import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
`;

export const Line = styled.div`
    display: flex;
    width: 100%;
`;

export const Section = styled.div`
    border-radius: 16px;
    background: #FFF;
    box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.16);
    width: 230px;
    height: 180px;
    padding: 20px;
    display: flex;
    align-items: center;
    color: #009c43;
    font-size: 1.3rem;
    flex-direction: column;
    justify-content: center;

    > div {
        height: 50%;
        width: 90%;
        overflow: hidden;         /* Oculta o conteúdo que transborda da div */
        text-overflow: ellipsis;  /* Exibe "..." quando o texto é cortado */
        white-space: nowrap; 

        > h3 {
            font-size: 2rem;
            overflow: hidden;         /* Oculta o conteúdo que transborda da div */
            text-overflow: ellipsis;  /* Exibe "..." quando o texto é cortado */
            white-space: nowrap; 
            color: #4D5D71;
            cursor: pointer;
    }
    }

   
`;