import axios from "axios";


export async function getOrders() {
    try {
        const { data: res } = await axios({ method: 'get', url: `${process.env.REACT_APP_LINK}/api/user/orders`, headers: { 'Authorization': `Bearer ${localStorage.getItem("clientToken")}` } })
        return res
    } catch (error) {
        return error.response.data
    }
}

export async function postBuyNow(store, product, quantity) {
    var data = {
        'quantity': quantity,
    }
    const { data: res } = await axios({ method: 'post', url: `${process.env.REACT_APP_LINK}/api/user/cart/buy-now/${store}/${product}`, headers: { 'Authorization': `Bearer ${localStorage.getItem('clientToken')}` }, data })
    return res
}