import React from 'react';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useInformation } from '../../../../Providers/informations';
import * as LogoutFunction from '../../../../Services/logout';
import logo from '../../../../Assets/Login/logo_white.png';
import MenuProfile from '../MenuProfile';
import MenuItemText from '../MenuItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import * as GetProducts from '../../../../Services/getProducts';
import * as GetProfile from '../../../../Services/user';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Badge from '@mui/material/Badge';


import {
    Container,
    Left,
    Middle,
    Right,
    Search,
    Div1,
    Div,
} from './styles';
import { LinkStyle } from '../MenuProfile/styles';
import { TemporaryDrawer } from './Asidemobile';

const MainHeader = () => {
    const { info, setInfo, setCategory, emailVerified, setEmailVeried, setStoreInfo, storeInfo, setCart, cart } = useInformation();
    const location = useLocation();
    const navigate = useNavigate();

    const [categoryList, setCategoryList] = React.useState([]);
    const [search, setSearch] = React.useState('');

    const getCategories = async () => {
        try {
            const categoryList = await GetProducts.getCategory();
            setCategoryList(categoryList.data.categories);
            setCategory(categoryList.data.categories);
        } catch (error) {

        }
    };

    const getProfileF = async () => {
        try {
            const profileInfo = await GetProfile.getProfile();
            if (profileInfo?.response?.data?.message === "Your email address is not verified.") {
                setEmailVeried(false);

                if (location.pathname.split("/")[1] === "confirmar") {
                    return
                } else {
                    navigate('/confirmar/email');
                }
            }
            if (profileInfo?.response?.data?.message === "Unauthenticated.") {
                LogoutFunction.Chek();
            }
            else {
                setInfo({
                    ClientName: profileInfo.data.user.name,
                    CientType: profileInfo.data.user.type,
                    ClientUuid: profileInfo.data.user.uuid,
                    ClientEmail: profileInfo.data.user.email,
                    ClientStoreStatus: profileInfo.data.user.store?.status,
                    CreatedAt: profileInfo.data.user.created_at,
                    UpdatedAt: profileInfo.data.user.updated_at,
                    ClientPhone: profileInfo.data.user.phone,
                    ClientDocumentType: profileInfo.data.user.document_type,
                    ClientDocumentNumber: profileInfo.data.user.document,
                    ClientEmailVerified: profileInfo.data.user.email_verified,
                    ClientInscE: profileInfo?.data?.user?.inscricao_estadual,
                });

                profileInfo.data?.store?.id !== null && setStoreInfo({
                    StoreCity: profileInfo.data?.store.city,
                    StoreCnpj: profileInfo.data?.store.cnpj,
                    StoreComplement: profileInfo.data?.store.complement,
                    StoreCountry: profileInfo.data?.store.country,
                    StoreCreatedAt: profileInfo.data?.store.created_at,
                    StoreDistrict: profileInfo.data?.store.district,
                    StoreId: profileInfo.data?.store.id,
                    StoreLogo: profileInfo.data?.store.logo,
                    StoreBanner: profileInfo.data?.store.banner,
                    StoreName: profileInfo.data?.store.name,
                    StoreNumber: profileInfo.data?.store.number,
                    StorePagarme_account_info: profileInfo.data?.store.pagarme_account_info,
                    StorePagarme_id: profileInfo.data?.store.pagarme_id,
                    StorePhone: profileInfo.data?.store.phone,
                    StoreSlug: profileInfo.data?.store.slug,
                    StoreState: profileInfo.data?.store.state,
                    StoreStatus: profileInfo.data?.store.status,
                    StoreStreet: profileInfo.data?.store.street,
                    StoreUpdatedAt: profileInfo.data?.store.updated_at,
                    StoreUser_id: profileInfo.data?.store.user_id,
                    StoreZip_code: profileInfo.data?.store.zip_code,
                    StoreShipment: profileInfo.data.store.shipment_locales,
                    StoreOrderValue: profileInfo.data.store.minimum_order_value,
                    StoreBanck: profileInfo.data.store.pagarme_account_info,
                    
                });
                setEmailVeried(true);
            }

        } catch (error) {
            if (error?.response?.data?.message === "Your email address is not verified.") {
                setEmailVeried(false);
                if (location.pathname.split("/")[1] === "confirmar") {
                    return
                } else {
                    navigate('/confirmar/email');
                }
            }
            if (error?.response?.data?.message === "Unauthenticated.") {
                LogoutFunction.Logout();
            }
        }
    }

    const getListCarProd = async () => {
        try {
            const response = await GetProducts.getProductsCart();
            setCart(response.data.products)
        } catch (error) {

        }
    }

    React.useEffect(() => {
        const shouldFetchProfile = localStorage.getItem("ClientAuth") === "true" &&
            !info.ClientName &&
            emailVerified !== false &&
            !storeInfo.StoreId;

        if (shouldFetchProfile) {
            getProfileF();
        }
    }, [location, info.ClientName, emailVerified, storeInfo.StoreId]);


    React.useEffect(() => {
        const shouldFetchProfile = localStorage.getItem("ClientAuth") === "true" &&
            emailVerified !== false &&
            !storeInfo.StoreId;

        if (shouldFetchProfile) {
            getProfileF();
        }
    }, [location, emailVerified, storeInfo.StoreId]);


    React.useEffect(() => {
        getCategories();
    }, []);

    React.useEffect(() => {
        if (localStorage.getItem("ClientAuth") === "true" && !storeInfo.StoreId && !info.ClientName && emailVerified !== false) {
            getProfileF();
            getListCarProd();
        }
    }, [location, info.ClientName])

    /*     React.useEffect(() => {
            if (localStorage.getItem("ClientAuth") === "true" && !info.ClientName && emailVerified !== false) {
                getProfileF();
                getListCarProd();
            }
        }, [location, info.ClientName])
    
        
    
        React.useEffect(() => {
            if (localStorage.getItem("ClientAuth") === "true" && !storeInfo.StoreId) {
                getProfileF();
            }
        }, [location, info.ClientName]) */

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const searchProducts = () => {
        if (!search || search.length < 3) {
            navigate('/');
        } else {
            navigate(`/produtos/buscar/${search}`);
        }
    }

    const searcheable = false;

    const [pageWidth, setPageWidth] = React.useState(window.innerWidth);

    function recarregarPagina() {
        setPageWidth(window.innerWidth)
    }
    var TimeOut;

    window.onresize = function () {
        clearTimeout(TimeOut);
        TimeOut = setTimeout(recarregarPagina, 10);
    };


    React.useEffect(() => {
        setPageWidth(window.innerWidth);
    }, [])

    return (
        <Container>
            <Left >
                {pageWidth <= 800 ? <TemporaryDrawer info={storeInfo.StoreId} status={localStorage.getItem("ClientAuth")} /> : <img src={logo} alt='logo agro' onClick={() => navigate('/')} />}
            </Left>
            <Middle>
                <Div1><MenuItemText string="Home" link="/" /></Div1>
                <Div1 id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}><h3>Categorias</h3></Div1>
                <Div1><MenuItemText string="Lojas" link="/lojas/lista" /></Div1>
                <Div1><MenuItemText string="Sobre Nós" link="/sobre-nos" /></Div1>
            </Middle>
            <Right>
                {pageWidth <= 800 ?
                    <Search >
                        <input placeholder='Buscar produtos...' id='search' value={search} onChange={(e) => setSearch(e.target.value)} onKeyUpCapture={(e) => e.key === "Enter" && searchProducts(e.target.value)} />
                        <div>
                            <SearchOutlinedIcon style={{ cursor: 'pointer' }} fontSize='large' onClick={() => searchProducts(search)} />
                        </div>
                    </Search> :
                    <Search >
                        <input placeholder='Buscar produtos...' id='search' value={search} onChange={(e) => setSearch(e.target.value)} onKeyUpCapture={(e) => e.key === "Enter" && searchProducts(e.target.value)} />
                        <div>
                            <SearchOutlinedIcon style={{ cursor: 'pointer' }} fontSize='large' onClick={() => searchProducts(search)} />
                        </div>
                    </Search>}
                {searcheable ? <></> :
                    <>
                        <div style={{ paddingLeft: '15px', transition: ' 2s ' }}>
                            <Link style={LinkStyle} to="/produtos/cart/lista">
                                <Badge badgeContent={cart?.length} color="success"><ShoppingCartIcon sx={{ cursor: 'pointer' }} /> </Badge>
                            </Link>
                        </div>
                        <Div>
                            <MenuProfile status={localStorage.getItem("ClientAuth")} type={storeInfo.StoreId} />
                        </Div>
                    </>
                }
            </Right>

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {categoryList?.map((cat) =>
                    <MenuItem key={cat?.id} onClick={handleClose}><Link to={`/categorias/${cat?.slug}/${cat?.title}`} style={{ textDecoration: 'none', color: 'black' }}>{cat?.title}</Link></MenuItem>
                )}
            </Menu>
        </Container>
    );
};

export default MainHeader;