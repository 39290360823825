import styled from "styled-components";

export const Container = styled.div`
     padding: 1% 5%;
`;

export const ContentCard = styled.div`
    width: 100%;
    height: 100vh;
    background-color: #FFFFFF ;
    box-shadow: 0px 0px 9px #26242424;
    border-radius: 6px;
`;

export const TopTitleDiv = styled.div`
    width: 100%;
    height: 110px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 2px #00000029;
    border-bottom: 1px solid #DDDDDD;
    opacity: 1;
    padding: 5px;
    border-radius: 6px 6px 0 0;
`;

export const ListContentOrder = styled.div`
    height: 80%;
    overflow: auto;
    padding: 15px;
`;