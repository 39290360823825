import axios from "axios"

export async function getProfile() {
  var { data: res } = await axios({ method: 'get', url: `${process.env.REACT_APP_LINK}/api/user/profile`, headers: { 'Authorization': `Bearer ${localStorage.getItem("clientToken")}` }, })
  return res
};

export async function updateUser(name, email, phone, docType, docNumber, uuid, inse) {
  var data = {
    "name": name,
    "email": email,
    "document_type": docType,
    "document": docNumber,
    "phone": phone,
    "inscricao_estadual": inse,
  }
  var { data: res } = await axios({ method: 'put', url: `${process.env.REACT_APP_LINK}/api/user/profile/${uuid}`, headers: { 'Authorization': `Bearer ${localStorage.getItem("clientToken")}` }, data })
  return res
};

export async function setAsStore(storeName, tradeName, cnpj, phone, address, num, complement, district, zip, city, state, country, referencePoint, income, partnerName, partnerEmail, prtDoc, prtNasc, prtJob, prtIncome, prtAddress, prtNum, prtComplement, prtDistrict, prtZip, prtCity, prtState, prtCountry, prtLegal, prtPhone, prtReferencePoint) {
  
  function removePhoneFormatting(phone) {
    return phone.replace(/[^\d]/g, '');
  }

  phone = removePhoneFormatting(phone);
  prtPhone = removePhoneFormatting(prtPhone);

  var data = {
    "company_name": storeName,
    "company_trading_name": tradeName,
    "company_document": cnpj,
    "company_annual_revenue": income,
    "company_address_street": address,
    "company_address_complementary": complement,
    "company_address_street_number": num,
    "company_address_neighborhood": district,
    "company_address_city": city,
    "company_address_state": state,
    "company_address_country": country,
    "company_address_zip_code": zip,
    "company_address_reference_point": referencePoint,
    "phone_store": phone,
    "partner_name": partnerName,
    "partner_email": partnerEmail,
    "partner_document": prtDoc,
    "partner_birthdate": prtNasc,
    "partner_monthly_income": prtIncome,
    "partner_professional_occupation": prtJob,
    "partner_self_declared_legal_representative": prtLegal,
    "partner_address_street": prtAddress,
    "partner_address_complementary": prtComplement,
    "partner_address_street_number": prtNum,
    "partner_address_neighborhood": prtDistrict,
    "partner_address_city": prtCity,
    "partner_address_state": prtState,
    "partner_address_zip_code": prtZip,
    "partner_address_reference_point": prtReferencePoint,
    "phone": prtPhone

  }
  var { data: res } = await axios({ method: 'post', url: `${process.env.REACT_APP_LINK}/api/user/store/request`, headers: { 'Authorization': `Bearer ${localStorage.getItem("clientToken")}` }, data })
  return res
}