import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Select from 'react-select';
import { changeOrderStatus } from '../../../../Services/store';
import { ButtonRedSmall, ButtonSilverSmall } from '../../../../Styles/stylesButtons';
import { Modal } from 'react-bootstrap';


export const SimpleAccordion = ({ successF, errorF, status, id, payment }) => {

    const [orderStatus, setOrderStatus] = React.useState();

    const [show, setShow] = React.useState(false);
    const handleCloseModal = () => setShow(false);

    const options = [
        { value: 'Em Processamento', label: 'Em Processamento' },
        { value: 'Enviado', label: 'Enviado' },
        { value: 'Concluído', label: 'Concluído' },
        { value: 'Cancelado', label: 'Cancelado' },
    ];

    const options2 = [
        { value: 'Cancelado', label: 'Cancelado' },
    ];
    const changeStatus = async (option) => {
        try {
            setOrderStatus(option);
            const response = await changeOrderStatus(option.value, id)
            if (response.status_code === 200) {
                successF()
            }
        } catch (error) {
            errorF()
        }
    }

    React.useEffect(() => {
        const findOrderStatus = () => {
            const foundOrderStatus = options.find(option => option.value === status);
            if (foundOrderStatus) {
                setOrderStatus(foundOrderStatus);
            }
        };
        findOrderStatus();
    }, [])

    return (
        <div>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>Atualizar Status</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {payment !== "Pagamento Pendente" && <label>Atualizar Status do Pedido</label>}
                    <Typography>
                        {payment === "Pagamento Pendente" ?
                            <Select
                                onChange={(opt) => changeStatus(opt)}
                                value={orderStatus}
                                name="Status"
                                options={options2}
                                className="basic-multi-select"
                                classNamePrefix="select"
                            />
                            :
                            <Select
                                onChange={(opt) => changeStatus(opt)}
                                value={orderStatus}
                                name="Status"
                                options={options}
                                className="basic-multi-select"
                                classNamePrefix="select"
                            />}

                    </Typography>
                </AccordionDetails>
            </Accordion>
            {/*   <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography>Cancelar Pedido</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        <ButtonRedSmall style={{ width: '100%', height: '30px' }} onClick={() => setShow(true)}>Cancelar Pedido</ButtonRedSmall>
                    </Typography>
                </AccordionDetails>
            </Accordion> */}

            <Modal show={show} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Cancelar Pedido</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Ao cancelar o pedido, o processo de reembolso para o cliente será iniciado.
                    Está certo de cancelar o pedido?
                </Modal.Body>
                <Modal.Footer>
                    <ButtonSilverSmall onClick={handleCloseModal}>Voltar</ButtonSilverSmall>
                    <ButtonRedSmall>Continuar</ButtonRedSmall>
                </Modal.Footer>
            </Modal>
        </div>
    );
}