import React from 'react';
import CarouselProduct from '../../Components/General/CaroselProduct';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import Grid from '@mui/material/Grid';
import { getStores } from '../../../Services/getProducts';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import {
    Container,
    Content,
} from './styles';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { getBannersList } from '../../../Services/publics';


const StoresList = () => {

    const [links, setLinks] = React.useState();
    const [totalPage, setTotalPage] = React.useState(0);
    const [page, setPage] = React.useState(1);
    const handleChange = (event, value) => {
        setPage(value);
        nextPage(value);
    };

    const [storeList, setStoreList] = React.useState([]);

    const getStoreList = async () => {
        try {
            const storeListResponse = await getStores();
            setStoreList(storeListResponse?.data)
            setLinks(storeListResponse?.meta?.links)
            setTotalPage(storeListResponse?.meta.last_page)
        } catch (error) {

        }
    }

    const nextPage = async (value) => {
        const { data: res } = await axios({ method: 'get', url: `${links?.[value].url}`, headers: { 'Authorization': `Bearer ${localStorage.getItem("clientToken")}` } });
        setStoreList(res?.data)
        /*  setStoreList(Object.values(res.data.orders.data)); */
    }

    const [bannersImg, setImages] = React.useState([])

    const getBanners = async () => {
        try {
            const response = await getBannersList("store");
            setImages(response.data.banners)
        } catch (error) {

        }
    }

    React.useEffect(() => {
        getStoreList();
        getBanners();
    }, [])

    return (
        <Container>
            <CarouselProduct images={bannersImg} />
            <Content>
                <Grid container spacing={3} sx={{ display: 'flex', justifyContent: 'center' }}>
                    {storeList?.map((list) => (
                        <Grid item key={list?.slug}>
                            <Link to={`/loja/${list?.name}/${list?.slug}/produtos`} style={{ textDecoration: 'none' }}>
                                <Card sx={{ width: 245, borderRadius: '16px' }}>
                                    <CardActionArea>
                                        <CardMedia
                                            component="img"
                                            height="200"
                                            image={list.logo}
                                            alt="logo da loja"
                                        />
                                        <CardContent>
                                            <Typography variant="body2" color="text.secondary">
                                                {list?.name}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                    {/* <CardActions>
                                        <Button size="small" color="primary">
                                            Explorar Loja
                                        </Button>
                                    </CardActions> */}
                                </Card>
                            </Link>
                        </Grid>
                    ))}
                </Grid>
                <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '15px' }}>
                    <Stack spacing={2}>
                        <Pagination count={totalPage} page={page} onChange={handleChange} />
                    </Stack>
                </div>
            </Content>
        </Container>
    )
}

export default StoresList;