import React from 'react';
import Button from '@mui/material/Button';
import { Modal, Form, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { ButtonGreen, ButtonGreenSmall } from '../../../../Styles/stylesButtons';
import AddIcon from '@mui/icons-material/Add';
import { deleteAddress, getAddressWithZipCode, postAddress, putAddress } from '../../../../Services/address';
import SnackBar from '../../../Components/General/SnackBar';
import HelpIcon from '@mui/icons-material/Help';
import DeleteIcon from '@mui/icons-material/Delete';
import PlaceIcon from '@mui/icons-material/Place';
import EditIcon from '@mui/icons-material/Edit';

import {
    Container,
} from './styles';
import { Avatar, ListItem, ListItemAvatar, ListItemText, Tooltip, Typography } from '@mui/material';
import isMobile from '../../../../Services/isMobile';

const NewAddress = ({ locantion, redirect, edit, addressInf }) => {
    const [show, setShow] = React.useState(false);
    const [showDelete, setShowDelete] = React.useState(false);
    const navigate = useNavigate();

    const handleClose = () => setShow(false);
    const handleCloseDelete = () => setShowDelete(false);
    const handleShow = () => setShow(true);
    const handleShowDelete = () => setShowDelete(true);

    const reset = () => {
        setShow(false);
        setTitle(null);
        setDescription(null);
        setAddress(null);
        setDistrict(null);
        setComplemnet(null);
        setNum(null);
        setZip(null);
        setCity(null);
        setState(null);
        setCountry(null);

    }

    const [title, setTitle] = React.useState();
    const [description, setDescription] = React.useState();
    const [address, setAddress] = React.useState();
    const [district, setDistrict] = React.useState();
    const [complement, setComplemnet] = React.useState();
    const [num, setNum] = React.useState();
    const [zip, setZip] = React.useState();
    const [city, setCity] = React.useState();
    const [state, setState] = React.useState();
    const [country, setCountry] = React.useState();
    const [ibgeCode, setIbgeCode] = React.useState();
    /* const [mainAddress, setMainAddress] = React.useState(); */

    const [success, setSuccess] = React.useState(false);
    const [error, setreturnError] = React.useState(false);

    const [cepError, setError] = React.useState(false);

    const getCep = async (zip) => {
        if (zip.length >= 8) {
            const addressResponse = await getAddressWithZipCode(zip)
            if (addressResponse?.erro === true) {
                setError(true);
                setTimeout(() => {
                    setError(false)
                }, 3000);
            } else {
                setZip(zip)
                setCity(addressResponse.localidade);
                setState(addressResponse.uf);
                setIbgeCode(addressResponse.ibge);
                setCountry("Brasil");
            }
        } else {
            setZip(zip)
            setCity("");
            setState("");
            setCountry("");
        }
    }

    const createAddress = async () => {
        if (!title || !address || !district || !num || !zip) {
            alert("campos em branco")
        }
        else {
            try {
                const response = await postAddress(title, address, num, complement, district, zip, city, state, country, description, ibgeCode,)
                if (response?.message === "success") {
                    setSuccess(true);
                    reset();
                    navigate(redirect);
                    setTimeout(() => {
                        setSuccess(false);
                    }, 3000);
                }
            } catch (error) {
                setreturnError(true);
                setTimeout(() => {
                    setreturnError(false);
                }, 3000);
            }
        }
    }

    const editAddress = async () => {
        if (!title || !address || !district || !num || !zip) {
            alert("campos em branco")
        }
        else {
            try {
                const response = await putAddress(title, address, num, complement, district, zip, city, state, country, description, ibgeCode, addressInf.id)
                if (response?.message === "success") {
                    setSuccess(true);
                    reset();
                    navigate(redirect);
                    setTimeout(() => {
                        setSuccess(false);
                    }, 3000);
                }
            } catch (error) {
                setreturnError(true);
                setTimeout(() => {
                    setreturnError(false);
                }, 3000);
            }
        }
    }

    const delteFunc = async () => {
        try {
            await deleteAddress(addressInf.id);
            setSuccess(true);
            reset();
            navigate(redirect);
            setTimeout(() => {
                setSuccess(false);
            }, 3000);
        } catch (error) {
            setreturnError(true);
            setTimeout(() => {
                setreturnError(false)
            }, 3000);
        }
    }

    React.useEffect(() => {
        if (edit === 'edit') {
            setIbgeCode(addressInf.ibge_code);
            setCountry(addressInf.country);
            setState(addressInf.state);
            setCity(addressInf.city);
            setZip(addressInf.zip_code);
            setNum(addressInf.number);
            setComplemnet(addressInf.complement);
            setDistrict(addressInf.district);
            setAddress(addressInf.street);
            setDescription(addressInf.description);
            setTitle(addressInf.title);
        }
    }, [edit])

    return (
        <Container>
            {edit === 'edit' && !locantion ?
                <ListItem alignItems="flex-start" sx={{ height: '100%', cursor: 'pointer' }} onClick={handleShow}>
                    <ListItemAvatar>
                        <PlaceIcon sx={{ color: '#009c43' }} />
                    </ListItemAvatar>
                    <ListItemText
                        primary={addressInf.title}
                        secondary={
                            <React.Fragment>
                                <Typography
                                    sx={{ display: 'inline' }}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                >
                                    {addressInf.street} - {addressInf.number}
                                </Typography>
                                {` — ${addressInf.district}, ${addressInf.city}/${addressInf.state} - ${addressInf.country}`}
                            </React.Fragment>
                        }
                    />
                    <EditIcon sx={{ color: '#797777' }} />
                </ListItem>
                : <></>}
            {locantion !== "outside" && edit !== 'edit' ? <ButtonGreenSmall onClick={handleShow}> <AddIcon />  &nbsp; Novo</ButtonGreenSmall> : <></>}
            {locantion === 'outside' && edit !== 'edit' ? <AddCircleIcon onClick={handleShow} style={{ cursor: 'pointer', color: 'green' }} /> : <></>}



            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton >
                    <Modal.Title>{edit === "edit" ? "Editar Endereço" : "Novo Endereço"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row className="mb-4">
                            <Col xs={12}>
                                <Form.Group controlId="formGridTitle">
                                    <Form.Label>Título <small style={{color: 'red'}}>*</small></Form.Label>
                                    <Form.Control value={title} onChange={e => setTitle(e.target.value)} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col xs={12}>
                                <Form.Group controlId="formGridAddress">
                                    <Form.Label>Logradouro <small style={{color: 'red'}}>*</small></Form.Label>
                                    <Form.Control value={address} onChange={e => setAddress(e.target.value)} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col xs={isMobile() ? 5 : 6}>
                                <Form.Group controlId="formGridDistrict">
                                    <Form.Label>Bairro <small style={{color: 'red'}}>*</small></Form.Label>
                                    <Form.Control value={district} onChange={e => setDistrict(e.target.value)} />
                                </Form.Group>
                            </Col>
                            <Col xs={isMobile() ? 4 : 4}>
                                <Form.Group controlId="formGridComplement">
                                    <Form.Label>Complemento</Form.Label>
                                    <Form.Control value={complement} onChange={e => setComplemnet(e.target.value)} />
                                </Form.Group>
                            </Col>
                            <Col xs={isMobile() ? 3 : 2}>
                                <Form.Group controlId="formGridZip">
                                    <Form.Label>Número <small style={{color: 'red'}}>*</small></Form.Label>
                                    <Form.Control value={num} onChange={e => setNum(e.target.value)} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-3">

                            <Col xs={isMobile() ? 6 : 3}>
                                <Form.Group as={Col} controlId="formGridZip">
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ marginTop: '-3px', paddingRight: '5px', cursor: 'pointer' }}>
                                            <Tooltip title="Digite seu CEP sem o ( - ) exemplo: 99500000">
                                                <HelpIcon />
                                            </Tooltip>
                                        </div>
                                        <Form.Label> CEP <small style={{color: 'red'}}>*</small></Form.Label>
                                    </div>
                                    <Form.Control onChange={(e) => getCep(e.target.value)}  value={zip} />
                                </Form.Group>
                            </Col>

                            <Col xs={isMobile() ? 6 : 3}>
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label>Cidade</Form.Label>
                                    <Form.Control readOnly value={city} />
                                </Form.Group>
                            </Col>

                            <Col xs={isMobile() ? 6 : 3}>
                                <Form.Group as={Col} controlId="formGridState">
                                    <Form.Label>Estado</Form.Label>
                                    <Form.Control readOnly value={state} />
                                </Form.Group>
                            </Col>

                            <Col xs={isMobile() ? 6 : 3}>
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label>País</Form.Label>
                                    <Form.Control readOnly value={country} />
                                </Form.Group>
                            </Col>
                        </Row>

                        {/*  <Form.Group className="mb-3" id="formGridCheckbox">
                            <Form.Check type="checkbox" label="Endereço padrão" />
                        </Form.Group> */}
                    </Form>
                </Modal.Body>
                <Modal.Footer >
                    {edit === 'edit' ?
                        <Button variant="secondary" onClick={handleShowDelete} style={{ borderRadius: '20px', color: 'red' }}>
                            <DeleteIcon /> Excluir
                        </Button> :
                        <Button variant="secondary" onClick={handleClose} style={{ borderRadius: '20px' }}>Cacelar</Button>}

                    <ButtonGreen style={{ borderRadius: '20px' }} variant="primary" onClick={edit === "edit" ? editAddress : createAddress}>
                        Salvar
                    </ButtonGreen>
                </Modal.Footer>
                <div id="sanckbar">
                    {cepError === true && <SnackBar type="warning" time={3000} message="Verifique seu CEP" states={true} />}
                    {success && <SnackBar type="success" time={3000} message="Registrado com sucesso" states={true} />}
                    {error && <SnackBar type="error" time={3000} message="Não foi possível completar o cadastro" states={true} />}
                </div>
            </Modal>



            <Modal show={showDelete} onHide={handleCloseDelete} size="md">
                <Modal.Header closeButton >
                    <Modal.Title>Excluir Endereço</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Deseja excluir esse endereço ?
                </Modal.Body>
                <Modal.Footer >
                    <Button variant="secondary" onClick={handleCloseDelete} style={{ borderRadius: '20px', color: 'red' }}>
                        Cancelar
                    </Button>
                    <ButtonGreen style={{ borderRadius: '20px' }} variant="primary" onClick={delteFunc}>
                        Excluir
                    </ButtonGreen>
                </Modal.Footer>
            </Modal>
        </Container>
    )
}

export default NewAddress;