import React from 'react';
import logo from '../../../../Assets/Login/logo_white.png';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import Pagarme from '../../../../Assets/ImagensGerais/pgm.png'
import Paymente from '../../../../Assets/ImagensGerais/pay.png'
import Pix from '../../../../Assets/ImagensGerais/pix.png'
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import {
    Container,
    Copyright,
    MenuLine1,
    Social,
    Logo,
    Menu,
    Icon,
} from './styles';

const Footer = () => {
    const navigate = useNavigate();
    return (
        <Container>
            <Menu>
                <MenuLine1 style={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <Logo style={{ justifyContent: 'center', display: 'flex' }}>
                        <img src={logo} alt="Logo" style={{ width: '50%' }} />
                    </Logo>
                    <Social>
                        <Icon>
                            <FacebookIcon fontSize='large' style={{ cursor: 'pointer' }} />
                        </Icon>
                        <Icon>
                            <InstagramIcon fontSize='large' style={{ cursor: 'pointer' }} />
                        </Icon>
                        <Icon>
                            <YouTubeIcon fontSize='large' style={{ cursor: 'pointer' }} />
                        </Icon>
                    </Social>

                    <Copyright>
                        <p>Copyright © {moment().format('YYYY')} produtosagro.com.br</p>
                    </Copyright>
                </MenuLine1>
                <MenuLine1 style={{ flexDirection: 'column', alignItems: 'center', paddingTop: '30px' }}>
                    <h5 style={{ color: '#ffffff', fontWeight: '600', paddingBottom: '20px' }}>Links Úteis</h5>
                    <div style={{ display: 'flex', flexDirection: 'column', height: '100%', textAlign: 'center', }}>
                        <p style={{ color: '#ffffff', cursor: 'pointer' }} onClick={() => navigate('/lojas/lista')}>Lojas Parceiras</p>
                        <p style={{ color: '#ffffff', cursor: 'pointer' }} onClick={() => navigate('/ajuda')}>Termos de Uso</p>
                        <p style={{ color: '#ffffff', cursor: 'pointer' }} onClick={() => navigate("/sobre-nos")}>Sobre Nós</p>
                        {/* <p style={{ color: '#ffffff', cursor: 'pointer' }} onClick={() => navigate('/contato')}>Contato</p> */}
                    </div>
                </MenuLine1>
                <MenuLine1 style={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <Logo style={{ justifyContent: 'center', display: 'flex' }}>
                        <img src={Pagarme} alt='selo segurança' style={{ width: '40%' }} />
                    </Logo>
                    <Logo style={{ justifyContent: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                        <img src={Paymente} alt='selo segurança' style={{ width: '40%' }} />
                        <img src={Pix} alt='selo segurança' style={{ width: '15%' }} />
                    </Logo>
                </MenuLine1>
            </Menu>
        </Container >
    );
};

export default Footer;