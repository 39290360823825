import styled from "styled-components";

export const Container = styled.div`

`;

export const LinkStyle = {
    textDecoration: 'none', 
    color: '#009c43'
}

export const LinkStyleBlack = {
    textDecoration: 'none',
    color: 'black'
}