import axios from "axios";
import axios_base from "../axios_base"

export async function registerFunc(name, email, password, term) {
  try {
    var data = {
      "name": name,
      "email": email,
      "password": password,
      "allowed_term": 1
    }
    const { data: res } = await axios_base.post(`/api/auth/register`, data);
    return res
  } catch (error) {
    return error
  }
};

export async function updatePassword(old, newOne, confirmNew) {
  var data = {
    old_password: old,
    new_password: newOne,
    password_confirmation: confirmNew,
  };
  var { data: res } = await axios({ method: 'post', url: `${process.env.REACT_APP_LINK}/api/auth/password-update`, headers: { 'Authorization': `Bearer ${localStorage.getItem("clientToken")}` }, data })
  return res
}

export async function changePassword(token, email, password, confirmPassword) {
  var data = {
    token: token,
    email: email,
    password: password,
    password_confirmation: confirmPassword,
  };
  var { data: res } = await axios({ method: 'post', url: `${process.env.REACT_APP_LINK}/api/auth/reset-password`, data })
  return res
}

export async function resendEmail() {
  const { data: res } = await axios({ method: 'post', url: `${process.env.REACT_APP_LINK}/api/auth/email/verification-notification`, headers: { 'Authorization': `Bearer ${localStorage.getItem("clientToken")}` } })
  return res
}