import React from "react";
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import { Link } from "react-router-dom";
import Card from '@mui/material/Card';
import {
    Container
} from './style';

const SmallCardProduct = ({ title, img, price, description, slug, cat, store }) => {
    const options = { style: 'currency', currency: 'BRL' };


    return (
        <Container>
            <Card sx={{ width: 250, height: 300 }}>
                <Link to={`/produto/detalhes/${store}/${slug}`} style={{ textDecoration: "none", }}>
                    <div style={{ height: '200px', cursor: 'pointer' }}>
                        <CardMedia
                            component="img"
                            height="100%"
                            image={img}
                            alt={title}
                        />
                    </div>
                </Link>
                <CardHeader
                    sx={{ width: "90%", height: '60px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', margin: '0', color: 'hsl(0, 0%, 38%)', textAlign: 'left' }}
                    title={title}
                    subheader={price?.toLocaleString('pt-BR', options)}
                />
                <CardContent>
                    <Typography variant="body2" color="text.secondary" sx={{ width: "90%", height: '55px', overflow: 'hidden', textOverflow: 'ellipsis', margin: '0' }}>
                        {description}
                    </Typography>
                </CardContent>
                <CardActions disableSpacing>
                </CardActions>

            </Card>
            <div id="sanckbar">

            </div>
        </Container>
    );
};

export default SmallCardProduct;