import React from "react";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
/* import FavoriteIcon from '@mui/icons-material/Favorite'; */
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import { Link, useNavigate } from "react-router-dom";
import Card from '@mui/material/Card';
import * as AddProduct from '../../../../Services/addViewProduct';
import { useInformation } from "../../../../Providers/informations";
import SnackBar from "../SnackBar";
import GoToBuy from "../GoToBuy";
import {
    Container
} from './style';
import { getProductsCart } from "../../../../Services/getProducts";

const CardProduct = ({ title, img, price, description, slug, cat, store }) => {

    const navigate = useNavigate();
    const { setUpdateCart, setCart, cart, setModalGoToCart } = useInformation();

    const [isInTheCart, setIsInTheCart] = React.useState(false);

    const [cartSuccess, setCartSuccess] = React.useState(false);
    const [cartError, setCartError] = React.useState(false);

    const options = { style: 'currency', currency: 'BRL' };

    const addToCart = async (slug, storeSlug) => {
        if (localStorage.getItem("ClientAuth") === "true") {
            const add = await AddProduct.addToCart(slug, 1, storeSlug);

            if (add.status_code !== 200 || !add) {
                setCartError(true);

                setTimeout(() => {
                    setCartError(false);
                }, 1000);
            } else {
                if (cart?.length === 0) {
                    setModalGoToCart(true);
                }
                getListCarProd();
                setCartSuccess(true);
                setUpdateCart(true);
                setIsInTheCart(true);
                setTimeout(() => {
                    setCartSuccess(false);
                }, 4000);
            }
        } else {
            localStorage.setItem("product", slug);
            localStorage.setItem("gotToCart", "yes");
            navigate(`/login/param?slug=${slug}&storelug=${storeSlug}&addto=car`);
        }
    }

    const getListCarProd = async () => {
        try {
            const response = await getProductsCart();
            setCart(response.data.products)
        } catch (error) {

        }
    }

    return (
        <Container>
            <Card sx={{ width: window.innerWidth <= 800 ? '45vw' : 250, height: 400, borderRadius: '16px' }}>
                <Link to={`/produto/detalhes/${store}/${slug}`} style={{ textDecoration: "none", }}>
                    <div style={{ height: '200px', cursor: 'pointer' }}>
                        <CardMedia
                            component="img"
                            height="100%"
                            image={img}
                            alt={title}
                        />
                    </div>
                </Link>
                <CardHeader
                    sx={{ width: "90%", height: '60px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', margin: '0', color: 'hsl(0, 0%, 38%)', textAlign: 'left' }}
                    title={`${price?.toLocaleString('pt-BR', options)}`}
                    subheader={title}
                />
                <CardContent>
                    <Typography variant="body2" color="text.secondary" sx={{ width: "90%", height: '55px', overflow: 'hidden', textOverflow: 'ellipsis', margin: '0' }}>
                        {description}
                    </Typography>
                </CardContent>
                <CardActions disableSpacing>
                    {/* <IconButton aria-label="add to favorites">
                        <FavoriteIcon />
                    </IconButton> */}
                    <IconButton aria-label="add to favorites" onClick={() => addToCart(slug, store)}>
                        <ShoppingCartIcon style={{ color: isInTheCart === false ? '#A9ABAE' : '#198754' }} />
                    </IconButton>
                </CardActions>

            </Card>
            <GoToBuy />
            {cartSuccess && <SnackBar type="success" time={1000} message={"Adicionado ao carrinho com sucesso"} states={true} />}
            {cartError && <SnackBar type="error" time={1000} message={"Erro ao adicionar ao carrinho"} states={true} />}

        </Container>
    );
};

export default CardProduct;