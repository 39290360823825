import React from 'react';
import InfoIcon from '@mui/icons-material/Info';


const TablePrice = ({ price, price2 }) => {
    const options = { style: 'currency', currency: 'BRL' };

    return (
        <div style={{ width: '80%', padding: '15px 0' }}>
            <table className="table">
                <thead>
                    <tr>
                        <th style={{ textAlign: 'center' }} scope="col">Valor Anunciado</th>
                        <th style={{ textAlign: 'center' }} scope="col">Cartão de Crédito</th>
                        <th style={{ textAlign: 'center' }} scope="col">Boleto</th>
                        <th style={{ textAlign: 'center' }} scope="col">Pix</th>
                        <th style={{ textAlign: 'center' }} scope="col">Você Receberá</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th style={{ textAlign: 'center' }} >{(price?.sales_price / 100)?.toLocaleString('pt-BR', options)}</th>
                        <td style={{ textAlign: 'center' }} >{(price?.credit_card / 100)?.toLocaleString('pt-BR', options)}</td>
                        <td style={{ textAlign: 'center' }} >{(price?.boleto / 100)?.toLocaleString('pt-BR', options)}</td>
                        <td style={{ textAlign: 'center' }} >{(price?.pix / 100)?.toLocaleString('pt-BR', options)}</td>
                        <td style={{ textAlign: 'center' }} >{(price2 / 100)?.toLocaleString('pt-BR', options)}</td>
                    </tr>
                </tbody>
            </table>
            <small>
                Cada método de pagamento (cartão de crédito, boleto bancário, pix) tem suas próprias taxas associadas.
                Portanto, é importante entender que as diferenças nos valores refletem as taxas cobradas pelos diferentes métodos de pagamento para garantir uma transação segura e eficiente, bem como para fornecer recursos extras aos clientes.
            </small>
            <div style={{ paddingTop: '15px', fontWeight: '600' }}>
                <small>
                    <InfoIcon style={{ color: '#e71b29' }} /> Devido à política de frete grátis, os custos de envio devem ser incluídos no preço do produto!
                </small>
            </div>
        </div>
    )

}

export default TablePrice;