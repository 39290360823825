import React from "react";
import { ButtonBlueSmall, ButtonGreen, ButtonSilver2 } from "../../../../Styles/stylesButtons";
import { Modal } from "react-bootstrap";
import Grid from '@mui/material/Grid';
import moment from "moment";
import Box from '@mui/material/Box';
import { rateProduct } from "../../../../Services/addViewProduct";
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import Rating from '@mui/material/Rating';
import SnackBar from "../SnackBar";
import { NfDownload } from "../../../Pages/MyOrders/nfDownload";
import StatusLine from "./status";
import { useNavigate } from "react-router-dom";
import {
    Container,
    Span,
    TextContent,
    Label,
    Line,
    Lines,
    TextStore,
    TextProduct,
    ProductViewCard
} from './styles';
import { TitleH3 } from "../../../../Styles/style";
import QRCode from "react-qr-code";

const OrdersProducts = ({ list, order, price }) => {

    const navigate = useNavigate();
    const [modalState, setModalState] = React.useState(false);
    const [modalRateState, setModalRateState] = React.useState(false);

    const [rating, setRating] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [error, setError] = React.useState(false);

    const [payment, setPayment] = React.useState();
    const [paymentInfo, setPaymentInfo] = React.useState();
    const [modalPayment, setModalPayment] = React.useState();
    const [pixQrCode, setPixQr] = React.useState();
    const [dateExpire, setDateExpire] = React.useState();

    const [productName, setProductName] = React.useState();
    const [store, setStore] = React.useState();
    const [slug, setSlug] = React.useState();

    const [descript, setDescript] = React.useState();

    const [value, setValue] = React.useState(0);

    const reating = (nameProduct, storeSlug, productSlug, order) => {
        setProductName(nameProduct);
        setStore(storeSlug);
        setSlug(productSlug);
        setModalState(!modalState)
        setModalRateState(!modalRateState);
    }

    const saveRate = async () => {
        setRating(true);
        try {
            const ratingResponse = await rateProduct(store, slug, value, descript, list?.order)
            if (ratingResponse.message === "success" && ratingResponse.status_code === 201) {
                setRating(false);
                setSuccess(true);
                setStore();
                setValue(0);
                setDescript();
                setProductName();
                navigate('/usuario/meus_pedidos');
                setTimeout(() => {
                    setSuccess(false)
                }, 3000);
                setModalRateState(!modalRateState);
                setModalState(!modalState)
            } else {
                setRating(false);
                setError(true);
                setTimeout(() => {
                    setError(false)
                }, 3000);
                setModalRateState(!modalRateState);
                setModalState(!modalState)
            }
        } catch (error) {
            setRating(false);
            setError(true);
            setTimeout(() => {
                setError(false)
            }, 3000);

        }
    }

    const copyQrCode = (qrCode) => {
        const input = document.createElement('input');
        input.value = qrCode;
        document.body.appendChild(input);
        input.select();
        document.execCommand('copy');
        document.body.removeChild(input);
        alert("Código copiado");
    }

    const completedNavigate = () => {
        setModalPayment(false);
        setModalState(false);
        navigate('/usuario/meus_pedidos');
    };

    React.useState(() => {
        const object = JSON.parse(list?.payment);
        setPayment(object?.method);
        if (object?.method === 'pix') {
            setPixQr(object?.qr_code);
            setDateExpire(object?.expires_at);
        } else {
            setPaymentInfo(object?.pdf);
        }
    }, [])
    const options = { style: 'currency', currency: 'BRL' };
    return (
        <Container>
            <ButtonSilver2 onClick={() => setModalState(!modalState)}>Ver Detalhes</ButtonSilver2>

            <Modal show={modalState} onHide={() => setModalState(!modalState)} size="lg">
                <Modal.Header style={{ borderBottom: '3px solid #cec1c1' }}>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Modal.Title><Span>{list?.order}</Span></Modal.Title>
                        {/*  <Small>Em Processamento</Small> */}
                    </div>
                </Modal.Header>
                <Modal.Body style={{ height: '80vh', overflow: 'auto' }}>
                    <Grid container spacing={2}>
                        <Line style={{ padding: '5px 0px 0px 15px' }}>
                            <TextContent>Informações do Pedido</TextContent>
                        </Line>
                        <Grid container>
                            <Lines style={{ borderBottom: '1px solid #434343' }}>
                                <div style={{ width: '60%' }}>

                                    <Line>
                                        <Label>Cliente</Label>
                                        <TextContent>{list?.customer}</TextContent>
                                    </Line>
                                    <Line>
                                        <Label>Data do pedido:</Label>
                                        <TextContent>{moment(list?.created_at).format('DD MMMM YYYY')}</TextContent>
                                    </Line>
                                    <Line>
                                        <Label>Endereço:</Label>
                                        <TextContent>{list.shipping?.street}, {list.shipping?.number} - {list.shipping?.district} - {list.shipping?.city}/{list.shipping?.state}</TextContent>
                                    </Line>
                                    <Line>
                                        <Label>CEP:</Label>
                                        <TextContent>{list?.shipping?.zip_code}</TextContent>
                                    </Line>

                                </div>
                                <div style={{ width: '40%' }}>
                                    <Line>
                                        <Label>Lojas:</Label>
                                        <TextContent>{list?.stores.length}</TextContent>
                                    </Line>
                                    <Line>
                                        <Label>Valor total</Label>
                                        <TextContent>{price}</TextContent>
                                    </Line>
                                    <Line>
                                        <Label>Forma de Pagamento </Label>
                                        <TextContent>
                                            {payment === "boleto" && <a href={paymentInfo} style={{ paddingTop: '5px', color: '#009c43' }} target="_blank" rel="noreferrer">Boleto</a>}
                                            {payment === "credit_card" && <p style={{ paddingTop: '5px' }} >Cartão de Crédito</p>}
                                            {payment === "pix" && <div style={{ paddingTop: '5px' }} >
                                                {list?.status === "Pendente" ? <p style={{ color: '#009c43', cursor: 'pointer' }} onClick={() => setModalPayment(true)}> Pix </p> : <p>Pix</p>}
                                            </div>}
                                        </TextContent>
                                    </Line>
                                </div>
                            </Lines>
                        </Grid>
                        {list?.stores?.map((store) => (
                            <div key={store?.nf} style={{ width: '100%' }}>
                                <Line style={{ padding: '5px 0px 0px 15px', width: '100%', justifyContent: 'space-between', display: 'flex', flexDirection: 'row', width: '100%' }}>
                                    <div style={{ width: '60%' }}>
                                        <TextContent style={{ fontWeight: '500' }}>{store?.name}</TextContent>
                                    </div>
                                    <div style={{ width: '40%', textAlign: 'left' }}>
                                        <TextContent>Status</TextContent>
                                    </div>
                                </Line>
                                <Grid container>
                                    <Lines style={{ borderBottom: '1px solid #C2BDBD' }}>
                                        <div style={{ width: '20%' }}>

                                            <Line>
                                                <Label>Valor parcial</Label>
                                                <TextContent>{store?.value?.toLocaleString('pt-BR', options)}</TextContent>
                                            </Line>
                                            <Line>
                                                {store?.nf && <NfDownload nf={store?.nf} />}
                                            </Line>
                                        </div>
                                        <div style={{ width: '80%', height: '100%' }}>
                                            <Line style={{ textAlign: 'center', height: '100%' }}>
                                                <Box sx={{ width: '100%' }}>
                                                    <StatusLine status={store?.status} />
                                                </Box>
                                            </Line>
                                        </div>
                                    </Lines>
                                </Grid>
                                <Grid container spacing={3} style={{ width: '100%', display: 'flex', borderBottom: '1px solid #434343' }}>
                                    {store?.order_products?.map((product) => (
                                        <>
                                            <Grid item xs="4" Key={product?.id} style={{}}>
                                                <ProductViewCard style={{ border: '1px solid #C9C5C5', background: '#F1F1F1F1', margin: '2px', borderRadius: '6px' }}>
                                                    <div style={{ padding: '5px 5px', width: '30%', justifyContent: 'center', }}>
                                                        <CardMedia
                                                            sx={{ borderRadius: '6px' }}
                                                            component="img"
                                                            height="50"
                                                            image={`${process.env.REACT_APP_LINK}/storage/stores/${product?.images[1]}`}
                                                            alt={product.title}
                                                        />
                                                    </div>
                                                    <div style={{ padding: '5px 5px', width: '70%' }}>

                                                        <TextProduct>{product.title}
                                                            <TextStore>{product?.price?.toLocaleString('pt-BR', options)} / {product?.quantity} </TextStore>
                                                            {
                                                                !product?.has_review &&
                                                                    store?.status !== "Cancelado" &&
                                                                    store?.status !== "Aguardando Pagamento" ? (
                                                                    <Button
                                                                        size="small"
                                                                        color="primary"
                                                                        onClick={() =>
                                                                            reating(
                                                                                product.title,
                                                                                product.store_slug,
                                                                                product.product_slug,
                                                                                list?.order
                                                                            )
                                                                        }
                                                                    >
                                                                        Avaliar
                                                                    </Button>
                                                                ) : (
                                                                    <></>
                                                                )
                                                            }

                                                        </TextProduct>

                                                    </div>
                                                </ProductViewCard>
                                            </Grid>
                                        </>
                                    ))}
                                </Grid>
                            </div>

                        ))}
                    </Grid>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setModalState(!modalState)}>
                        Fechar
                    </Button>
                </Modal.Footer>
                <div id="sanckbar">

                </div>
            </Modal>

            <Modal show={modalRateState} onHide={() => { setModalRateState(!modalRateState); setModalState(!modalState) }} size="sm">
                <Modal.Header closeButton>
                    <Modal.Title>Avaliar</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ height: '350px', overflow: 'auto' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography component="legend" sx={{ color: '#9e9e9e' }}>Produto: {productName}</Typography>

                        </Grid>
                        <Grid item xs={12}>
                            <Typography component="legend" sx={{ color: '#9e9e9e' }}>Qualidade</Typography>
                            <Rating
                                name="simple-controlled"
                                value={value}
                                onChange={(event, newValue) => {
                                    setValue(newValue);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <div className='row'>
                                <div className="col">
                                    <div className="mb-3">
                                        <label for="description" className="form-label" style={{ color: '#9e9e9e' }}><span style={{ color: 'red' }}>*</span>Descrição</label>
                                        <textarea className="form-control" id="descriptionArea" placeholder="Descrição do Produto" rows="6" value={descript} onChange={(e) => setDescript(e.target.value)}></textarea>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => saveRate()}>
                        Salvar
                    </Button>
                    <Button variant="secondary" onClick={() => { setModalRateState(!modalRateState); setModalState(!modalState) }}>
                        Cancelar
                    </Button>
                </Modal.Footer>
                <div id="sanckbar">
                    {error && <SnackBar type="error" time={3000} message="Não foi possível completar a ação, tente mais tarde" states={true} />}
                    {success && <SnackBar type="success" time={3000} message="Avaliação salva com sucesso" states={true} />}
                    {rating && <SnackBar type="info" time={3000} message="Salvando avaliação" states={true} />}
                </div>
            </Modal>

            <Modal show={modalPayment} onHide={() => setModalPayment(!modalPayment)} size="md">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <TitleH3>Pagamento com Pix</TitleH3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ height: '500px', overflow: 'auto' }}>
                    <div style={{ marginBottom: '10px' }}>
                        <p>Seu código qr code é valido até -  <small style={{ color: 'red' }} >{moment(dateExpire).format('DD/MM/YYYY: HH:mm')}</small></p>
                    </div>
                    <div style={{ height: "auto", margin: "0 auto", width: "35%", paddingBottom: '25px' }}>
                        <QRCode
                            size={256}
                            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                            value={pixQrCode}
                            viewBox={`0 0 256 256`}
                        />

                    </div>
                    <div style={{ width: '100%', justifyContent: 'center', display: 'flex' }}>
                        <ButtonBlueSmall onClick={() => copyQrCode(pixQrCode)} style={{ borderRadius: '20px', width: '250px', }}>
                            Copiar o código
                        </ButtonBlueSmall>
                    </div>
                    <div style={{ margin: '20px 10px', textAlign: 'center', }}>
                        <texto style={{ fontSize: '1.2rem', lineHeight: '1.5', color: '#555', fontWeight: '400' }}>
                            Use o seu aplicativo de banco ou carteira digital para escanear este QR Code e efetuar o pagamento de forma rápida e segura com o PIX!
                        </texto>

                    </div>
                    <div style={{ width: '100%', justifyContent: 'center', display: 'flex' }}>
                        <ButtonGreen onClick={() => completedNavigate()} style={{ borderRadius: '20px', width: '50%', }}>
                            Já fiz o pagamento
                        </ButtonGreen>
                    </div>
                </Modal.Body>
                <div id="sanckbar">
                    {error && <SnackBar type="error" time={3000} message="Não foi possível completar a ação, tente mais tarde" states={true} />}
                    {success && <SnackBar type="success" time={3000} message="Avaliação salva com sucesso" states={true} />}
                    {rating && <SnackBar type="info" time={3000} message="Salvando avaliação" states={true} />}
                </div>
            </Modal>

        </Container >
    )
}

export default OrdersProducts
