
export function Logout() {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("clientToken")}`);

    var urlencoded = new URLSearchParams();

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_LINK}/api/auth/logout`, requestOptions)
        .then(response => response.text())
        .then(result => {
            localStorage.clear();
            localStorage.clear();
            window.location.replace('/');
        })
        .catch(error => {
            console.log('error', error);
            localStorage.clear();
            localStorage.clear();
            window.location.replace('/');
        });
}


export function Chek() {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("clientToken")}`);

    var urlencoded = new URLSearchParams();

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_LINK}/api/auth/logout`, requestOptions)
        .then(response => response.text())
        .then(result => {
            localStorage.clear();
            localStorage.clear();
            window.location.replace('/login');
        })
        .catch(error => {
            console.log('error', error);
            localStorage.clear();
            localStorage.clear();
            window.location.replace('/login');
        });
}