import React from "react";
import banner01 from '../../../Assets/Área de Trabalho/banners1.png';
import { TitleH3, TitleH6 } from "../../../Styles/style";


import {
    Container,
    DivImage,
    SmallCard,
    BigCard,
    Bottom,
    Text,
    Top,
} from './styles';

const AboutUs = () => {

    const sendEmail = (email, subject, message) => {
        var emailAd = email;
        var assunto = subject;
        var mensagem = message;
        var link = "mailto:" + emailAd + "?subject=" + encodeURIComponent(assunto) + "&body=" + encodeURIComponent(mensagem);
        window.location.href = link;
    }

    return (
        <Container>
            <DivImage>
                <img src={banner01} alt="banner" />
            </DivImage>
            <Text>
                <div>
                    <Top>
                        <BigCard>
                            <TitleH3 style={{ fontSize: '2.5rem', padding: '3% 0' }}>
                                Sobre Nós
                            </TitleH3>
                            <p>Conheça e descubra o que é o Agro Produtos</p>
                            <TitleH6 style={{ padding: '2% 0 0 0' }}>
                                Quem Somos:
                            </TitleH6>
                            <div >
                                <p>
                                    Criamos oportunidades ao colocar o mundo em movimento.<br></br>
                                    Quando as pessoas tem mobilidade, tudo é possível. As oportunidades surgem, as portas se abrem e os sonhos se tornam realidade. Nossa tecnologia permite que você se conecte a milhares de
                                    fornecedores pelo Brasil, encontrando os melhores produtos sem precisar parar os serviços.
                                </p>
                            </div>
                        </BigCard>
                    </Top>

                    <Bottom>
                        <SmallCard>
                            <TitleH6 style={{ padding: '2% 0 0 0' }}>
                                Missão:
                            </TitleH6>
                            <p>
                                Ser um time obstinado em prever e atender as necessidades dos clientes e consumidores sempre agregando valores.
                            </p>
                        </SmallCard>

                        <SmallCard>
                            <TitleH6 style={{ padding: '2% 0 0 0' }}>
                                Valores:
                            </TitleH6>
                            <p>
                                Honestidade, respeito e dedicação.
                            </p>
                        </SmallCard>
                        <SmallCard>
                            <TitleH6 style={{ padding: '2% 0 0 0' }}>
                                Visão:
                            </TitleH6>
                            <p>
                                Tornar a compra de insumos agricolas acessível de qualquer lugar!
                            </p>
                        </SmallCard>
                    </Bottom>
                    <Top>
                        <BigCard>
                            <TitleH3 style={{ fontSize: '2.5rem', padding: '1% 0' }}>
                                Contato:
                            </TitleH3>
                            <p>Dúvidas e Sugestões entre em contato conosco:</p>
                            <TitleH6 style={{ padding: '1% 0 0 0' }}>
                                Email:
                            </TitleH6>
                            <div style={{ overflowWrap: 'break-word', wordWrap: 'break-word' }}>
                                <div style={{ padding: '5px 0', cursor: 'pointer' }} onClick={() => sendEmail('contato@produtosagro.com.br', 'Contato', 'Olá')}> contato@produtosagro.com.br</div>
                                <div style={{ padding: '5px 0', cursor: 'pointer' }} onClick={() => sendEmail('suporte@produtosagro.com.br', 'Contato', 'Olá')}> suporte@produtosagro.com.br</div>
                                <div style={{ padding: '5px 0', cursor: 'pointer' }} onClick={() => sendEmail('suporte.loja@produtosagro.com.br', 'Contato', 'Olá')}>  suporte.loja@produtosagro.com.br</div>

                            </div>
                            <TitleH6 style={{ padding: '1% 0 0 0' }}>
                                WhatsApp:
                            </TitleH6>
                            <div style={{ cursor: 'pointer' }}>
                                <p onClick={() => window.open('https://web.whatsapp.com/send?phone=5454991939646&text=olá')}>
                                    (54)99193-9646
                                </p>

                            </div>
                        </BigCard>
                    </Top>
                </div>
            </Text>
        </Container>
    )
}

export default AboutUs