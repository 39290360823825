import styled from "styled-components";

export const Container = styled.div`
    padding: 1% 5%;
`;
export const Line = styled.div`
    width: 100%;
    display: flex;
    padding: 0 15px;
    padding-bottom: 15px ;

    @media(max-width: 800px){
        padding: 0;
        flex-direction: column;
        }
`;
export const Small = styled.div`
    width: 40%;
    height: 240px;
    background-color: #fff ;
    box-shadow: 0px 0px 9px #26242424;
    border-radius: 6px;
    margin-left: 15px;
    overflow: auto ;
    @media(max-width: 800px){
        width: 100%;
        margin-left: 0;
        margin-top: 15px;
        margin-bottom: 15px;
        }

`;
export const Medium = styled.div`
    flex:1;
    height: 240px;
    background-color: #fff ;
    box-shadow: 0px 0px 9px #26242424;
    border-radius: 6px;
    overflow: auto;
    padding: 10px;
`;

export const Big = styled.div`
    flex:1;
    height: 580px;
    background-color: #fff ;
    box-shadow: 0px 0px 9px #26242424;
    border-radius: 6px;
    padding: 10px;
`;
export const DivList = styled.div`
    display: flex;
    padding-top: 15px;
    margin-bottom:5px ;
    border-radius: 6px;
`;
export const DivListProduct = styled.div`
    display: flex;
    padding-top: 15px;
    margin-bottom:5px ;
    border-radius: 6px;
    border: 1px solid #DDDDDD;
    height: 120px;

    @media(max-width: 800px){
        height: 100%;
    }
`;

export const H3Title = styled.h3`
    font: normal normal 700 0.9rem Mulish;
    letter-spacing: 0px;
    color: #434343;
    opacity: 1;
`;

export const SmallSubtitle = styled.small`
    font: normal normal 700 0.9rem Mulish;
    letter-spacing: 0px;
    color: #787878;
    opacity: 1;
    padding-right: 5px;
    padding-bottom: 5px;
`;
export const Input = {
    border: '1px solid #A9ABAE',
    background: '#F1F1F1',
    width: '60px',
    height: '35px',
    borderRadius: ' 5px',
    paddingLeft: '5px',
};
export const TotalPriceItem = styled.small`
    font: normal normal 700 1.1rem Mulish;
    letter-spacing: 0px;
    color: #434343;
    opacity: 1;
    padding-right: 5px;
    margin-bottom: 15px;
`;

export const LoadingDiv = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const LabelResume = styled.div`
    width: 50%;
    font-size: 1rem;
    color: #434343;
    font-weight: 600;
`;

export const ValueResume = styled.div`
    width: 50%;
    display: flex;
    justify-content: center;
    
`;
export const DivResume = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 15px;
`;

export const Web = styled.div`
    display: flex;
    @media(max-width: 800px){
        display: none;
    }
`;
export const Mobile = styled.div`
    display: none;
    @media(max-width: 800px){
        display: flex;
    }
`;