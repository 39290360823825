import axios from "axios";

export async function updateStoreProfile(store, storeName, cnpj, phone, address, img, imge2) {
    try {
        var data = {
            "name": storeName,
            "cnpj": cnpj,
            "phone": phone,
            "logo": img,
            "banner": imge2,
            "street": address.street,
            "number": address.num,
            "complement": address.complement,
            "district": address.district,
            "zip_code": address.zip,
            "city": address.city,
            "state": address.state,
            "country": address.country,
        }
        var { data: res } = await axios({ method: 'put', url: `${process.env.REACT_APP_LINK}/api/store/profile/${store}`, headers: { 'Authorization': `Bearer ${localStorage.getItem("clientToken")}` }, data })
        return res
    } catch (error) {
        return error
    }
}


export async function getTax() {
    try {
        var { data: res } = await axios({ method: 'get', url: `${process.env.REACT_APP_LINK}/api/store/payment-fees`, headers: { 'Authorization': `Bearer ${localStorage.getItem("clientToken")}` } })
        return res
    } catch (error) {
        return error
    }
}

export async function getStoreProfile() {
    try {
        var { data: res } = await axios({ method: 'get', url: `${process.env.REACT_APP_LINK}/api/store/profile`, headers: { 'Authorization': `Bearer ${localStorage.getItem("clientToken")}` } })
        return res
    } catch (error) {
        return error
    }
}

export async function getMySales() {
    try {
        const { data: res } = await axios({ method: 'get', url: `${process.env.REACT_APP_LINK}/api/store/orders`, headers: { 'Authorization': `Bearer ${localStorage.getItem("clientToken")}` } })
        return res
    } catch (error) {
        return error
    }
}

export async function postNF(order, file) {
    const url = `${process.env.REACT_APP_LINK}/api/store/orders/add-nf/${order}`;
    const token = localStorage.getItem('clientToken');

    const reader = new FileReader();
    reader.onload = async () => {
        const base64Data = reader.result.split(',')[1]; // Extrair o conteúdo em base64
        const pdfDataUrl = `data:application/pdf;base64,${base64Data}`;

        const options = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify({ nf: pdfDataUrl }) // Enviar o conteúdo em base64 no corpo da requisição
        };

        try {
            const response = await fetch(url, options);
            const data = await response.json();
            return data
        } catch (error) {
            return error
        }
    };
    reader.readAsDataURL(file);
    return reader
}

export async function changeOrderStatus(status, id) {
    var data = {
        "status": status,
    };
    const { data: res } = await axios({ method: 'put', url: `${process.env.REACT_APP_LINK}/api/store/orders/update-status/${id}`, headers: { 'Authorization': `Bearer ${localStorage.getItem('clientToken')}` }, data })
    return res
}

export async function getReportsMonth(url) {
    const { data: res } = await axios({ method: 'get', url: `${process.env.REACT_APP_LINK}/api/store/reports/${url}`, headers: { 'Authorization': `Bearer ${localStorage.getItem('clientToken')}` } })
    return res
}