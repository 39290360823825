import React from 'react'
import { ButtonGreenSmall, ButtonSilverSmall } from '../../../../Styles/stylesButtons';
import { Modal } from '@mantine/core';
import { Checkbox } from '@mui/material';
import { TitleH3, TitleH6 } from '../../../../Styles/style';
import InputGroup from '../../General/InputGroup/Group';
import SnackBar from '../../General/SnackBar';
import { getAddressWithZipCode } from '../../../../Services/address';
import { setAsStore } from '../../../../Services/user';
import { useNavigate } from 'react-router-dom';
import { useInformation } from '../../../../Providers/informations';


import {
    Container,
    InternContent,
    DivRow,
} from './styles';
import { formatarMoeda } from '../../../../Services/payment';
import ReactInputMask from 'react-input-mask';


const SetasStore = () => {

    const navigate = useNavigate();
    const { setStoreInfo } = useInformation();

    const [opened, setOpened] = React.useState(false);

    /* Store */
    const [storeName, setStoreName] = React.useState();
    const [tradeName, setTradeName] = React.useState();
    const [cnpj, setCnpj] = React.useState();
    const [phone, setPhone] = React.useState();
    const [address, setAddress] = React.useState();
    const [num, setNum] = React.useState();
    const [complement, setComplement] = React.useState();
    const [district, setDistrict] = React.useState();
    const [zip, setZip] = React.useState();
    const [city, setCity] = React.useState();
    const [state, setState] = React.useState();
    const [country, setCountry] = React.useState();
    const [referencePoint, setReferencePoint] = React.useState();
    const [income, setIncome] = React.useState();
    const [incomeS, setIncomeS] = React.useState();


    const [cepError, setCepError] = React.useState(false);
    const [createError, setCreateError] = React.useState(false);
    const [createSuccess, setCreateSuccess] = React.useState(false);
    const [creating, setCreating] = React.useState(false);


    /*  Partner */
    const [partnerName, setPartnerName] = React.useState("");
    const [partnerEmail, setPrtEmail] = React.useState("");
    const [prtDoc, setPrtDoc] = React.useState("");
    const [prtNasc, setPrtNasc] = React.useState();
    const [prtJob, setPrtJob] = React.useState("");
    const [prtIncome, setPrtIncome] = React.useState();
    const [prtIncomeS, setPrtIncomeS] = React.useState();
    const [prtAddress, setPrtAddress] = React.useState("");
    const [prtNum, setPrtNum] = React.useState();
    const [prtComplement, setPrtComplement] = React.useState("");
    const [prtDistrict, setPrtDistrict] = React.useState("");
    const [prtZip, setPrtZip] = React.useState("");
    const [prtCity, setPrtCity] = React.useState("");
    const [prtState, setPrtState] = React.useState("");
    const [prtCountry, setPrtCountry] = React.useState("");
    const [prtLegal, setPrtLegal] = React.useState(false);
    const [prtPhone, setPrtPhone] = React.useState("");
    const [prtReferencePoint, setPrtReferencePoint] = React.useState("");



    const [tab, setTab] = React.useState('loja');

    const handleChange = (event) => {
        setPrtLegal(event.target.checked);
    };

    const getCep = async (zip) => {
        if (zip.length >= 8) {
            const addressResponse = await getAddressWithZipCode(zip)
            if (addressResponse?.erro === true) {
                setCepError(true);
                setTimeout(() => {
                    setCepError(false)
                }, 3000);
            } else {
                if (tab === "loja") {
                    setZip(zip)
                    setCity(addressResponse.localidade);
                    setState(addressResponse.uf);
                    setCountry("Brasil");
                }
                if (tab === "resp") {
                    setPrtZip(zip)
                    setPrtCity(addressResponse.localidade);
                    setPrtState(addressResponse.uf);
                    setPrtCountry("Brasil");
                }
            }
        } else {
            if (tab === "loja") {
                setZip(zip)
                setCity("");
                setState("");
                setCountry("");
            } if (tab === "resp") {
                setPrtZip(zip)
                setPrtCity("");
                setPrtState("");
                setPrtCountry("");
            }
        }
    }

    const setAsStoref = async (storeName, tradeName, cnpj, phone, address, num, complement, district, zip, city, state, country, referencePoint, incomeS, partnerName, partnerEmail, prtDoc, prtNasc, prtJob, prtIncomeS, prtAddress, prtNum, prtComplement, prtDistrict, prtZip, prtCity, prtState, prtCountry, prtLegal, prtPhone, prtReferencePoint) => {
        try {
            const setAsStoreResponse = await setAsStore(storeName, tradeName, cnpj, phone, address, num, complement, district, zip, city, state, country, referencePoint, incomeS, partnerName, partnerEmail, prtDoc, prtNasc, prtJob, prtIncomeS, prtAddress, prtNum, prtComplement, prtDistrict, prtZip, prtCity, prtState, prtCountry, prtLegal, prtPhone, prtReferencePoint)
            console.log(setAsStoreResponse)
            if (setAsStoreResponse.status_code === 201) {
                setCreateSuccess(true);
                setTimeout(() => {
                    setStoreInfo({ StoreStatus: "pending" });
                    setOpened(false);
                    navigate("/usuario/perfil")
                }, 5000);
            } else {
                setCreating(false);
                setCreateError(true);
                setTimeout(() => {
                    setCreateError(false);
                }, 3000);
            }
        } catch (error) {
            setCreating(false);
            setCreateError(true);
            setTimeout(() => {
                setCreateError(false);
            }, 3000);
        }
    }

    const setPriceToFormat2 = async (e) => {
        var nr;
        const priceReturn = await formatarMoeda(e);
        setIncome(priceReturn);
        if (typeof e !== 'number') {
            var virgulaSeparaDecimais = priceReturn.match(/,\d{2}$/);
            if (virgulaSeparaDecimais) {
                nr = priceReturn.replace(/\./g, '').replace(',', '');
            }
            setIncomeS(nr);
        } else {
            setIncomeS(e);

        }
    }

    const setPriceToFormat = async (e) => {
        var nr;
        const priceReturn = await formatarMoeda(e);
        setPrtIncome(priceReturn);
        if (typeof e !== 'number') {
            var virgulaSeparaDecimais = priceReturn.match(/,\d{2}$/);
            if (virgulaSeparaDecimais) {
                nr = priceReturn.replace(/\./g, '').replace(',', '');
            }
            setPrtIncomeS(nr);
        } else {
            setPrtIncomeS(e);

        }
    }

    return (
        <Container>
            <ButtonGreenSmall onClick={() => setOpened(true)} style={{ width: '170px', height: '30px' }}>Cadastrar como loja</ButtonGreenSmall>
            <Modal
                overflow="inside"
                opened={opened}
                onClose={() => setOpened(false)}
                title={<TitleH3>Cadastrar Loja</TitleH3>}
                size="xl"
                styles={() => ({ modal: { borderRadius: 10 }, header: { borderBottom: 'solid 2px #c6c6c6' } })}
            >
                <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '2rem' }}>
                    <div style={{ borderBottom: tab === "loja" && '1px solid #009c43', width: '200px', display: 'flex', justifyContent: 'center' }}>
                        <TitleH6 style={{ color: tab === "loja" && '#009c43', cursor: 'pointer' }} onClick={() => setTab('loja')}>Loja</TitleH6>
                    </div>
                    <div style={{ borderBottom: tab === "resp" && '1px solid #009c43', width: '200px', display: 'flex', justifyContent: 'center' }}>
                        <TitleH6 style={{ color: tab === "resp" && '#009c43', cursor: 'pointer' }} onClick={() => setTab('resp')}>Responsavel</TitleH6>
                    </div>
                </div>
                {tab === "loja" ? <InternContent>
                    <DivRow>
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <label htmlFor="storeName" className="form-label">Razão Social</label>
                                    <input type="text" className="form-control" id="storeName" aria-describedby="Razão Social" value={storeName} onChange={(e) => setStoreName(e.target.value)} />
                                </div>
                            </div>
                        </div>
                    </DivRow>
                    <DivRow>
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <label htmlFor="cnpj" className="form-label">CNPJ</label>
                                    <ReactInputMask type="text" mask="99.999.999/9999-99" className="form-control" id="cnpj" aria-describedby="CNPJ" value={cnpj} onChange={(e) => setCnpj(e.target.value)} />
                                </div>
                                <div className="col">
                                    <label htmlFor="tradeName" className="form-label">Nome Fantasia</label>
                                    <input type="text" className="form-control" id="tradeName" aria-describedby="Nome Fantasia" value={tradeName} onChange={(e) => setTradeName(e.target.value)} />
                                </div>
                            </div>
                        </div>
                    </DivRow>

                    <DivRow>
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <label htmlFor="income" className="form-label">Rendimento Anual</label>
                                    <input type="text" className="form-control" id="income" aria-describedby="Rendimento Anual" value={income} onChange={(e) => setPriceToFormat2(e.target.value)} />
                                </div>
                                <div className="col">
                                    <label htmlFor="tradeName" className="form-label">Telefone</label>
                                    <ReactInputMask type="text" mask="(99) 99999-9999" className="form-control" id="phoneSto" aria-describedby="Telefone" value={phone} onChange={(e) => setPhone(e.target.value)} />
                                </div>
                            </div>
                        </div>
                    </DivRow>

                    <DivRow>
                        <div className="container">
                            <div className="row">
                                <div className="col-10">
                                    <label htmlFor="address" className="form-label">Endereço</label>
                                    <input type="text" className="form-control" id="address" aria-describedby="Endereço" value={address} onChange={(e) => setAddress(e.target.value)} />
                                </div>
                                <div className="col-2">
                                    <label htmlFor="num" className="form-label">Numero</label>
                                    <input type="number" className="form-control" id="num" aria-describedby="Numero" value={num} onChange={(e) => setNum(e.target.value)} />
                                </div>
                            </div>
                        </div>
                    </DivRow>

                    <DivRow>
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <label htmlFor="cmple" className="form-label">Complemento</label>
                                    <input type="text" className="form-control" id="cmple" aria-describedby="Complemento" value={complement} onChange={(e) => setComplement(e.target.value)} />
                                </div>
                                <div className="col">
                                    <label htmlFor="district" className="form-label">Bairro</label>
                                    <input type="text" className="form-control" id="district" aria-describedby="Bairro" value={district} onChange={(e) => setDistrict(e.target.value)} />
                                </div>
                            </div>
                        </div>
                    </DivRow>

                    <DivRow>
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <label htmlFor="cep" className="form-label">CEP</label>
                                    <input type="text" className="form-control" id="cep" aria-describedby="CEP" value={zip} onChange={(e) => getCep(e.target.value)} />
                                </div>
                                <div className="col">
                                    <label htmlFor="city" className="form-label">Cidade</label>
                                    <input type="text" className="form-control" id="city" aria-describedby="Cidade" value={city} />
                                </div>
                            </div>
                        </div>
                    </DivRow>

                    <DivRow>
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <label htmlFor="state" className="form-label">Estado</label>
                                    <input type="text" className="form-control" id="state" aria-describedby="Estado" value={state} />
                                </div>
                                <div className="col">
                                    <label htmlFor="country" className="form-label">País</label>
                                    <input type="text" className="form-control" id="country" aria-describedby="País" value={country} />
                                </div>
                            </div>
                        </div>
                    </DivRow>

                    <DivRow>
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <label htmlFor="reference" className="form-label">Ponto de Refência</label>
                                    <input type="text" className="form-control" id="reference" aria-describedby="Ponto de Refência" value={referencePoint} onChange={(e) => setReferencePoint(e.target.value)} />
                                </div>
                            </div>
                        </div>
                    </DivRow>

                    <div className="container" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        {creating &&
                            <div style={{ padding: '0px 10px' }}>
                                <ButtonSilverSmall onClick={() => alert(storeName)}> Cancelar </ButtonSilverSmall>
                            </div>
                        }
                        {creating ? <div style={{ padding: '0px 10px' }}>
                            <ButtonSilverSmall >Salvar</ButtonSilverSmall>
                        </div> :
                            <div style={{ padding: '0px 10px' }}>
                                <ButtonGreenSmall onClick={() => setTab("resp")}>Avançar</ButtonGreenSmall>
                            </div>}
                    </div>
                </InternContent>
                    :
                    <InternContent>
                        {/* Responsavel */}

                        <DivRow>
                            <div className="container">
                                <div className="row">
                                    <div className="col">
                                        <label htmlFor="partnerName" className="form-label">Nome Responsável</label>
                                        <input type="text" className="form-control" id="partnerName" aria-describedby="Nome Responsável" value={partnerName} onChange={(e) => setPartnerName(e.target.value)} />
                                    </div>
                                    <div className="col">
                                        <label htmlFor="partnerEmail" className="form-label">Email</label>
                                        <input type="text" className="form-control" id="partnerEmail" aria-describedby="Email" value={partnerEmail} onChange={(e) => setPrtEmail(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                        </DivRow>

                        <DivRow>
                            <div className="container">
                                <div className="row">
                                    <div className="col">
                                        <label htmlFor="cpf" className="form-label">CPF</label>
                                        <ReactInputMask type="text" mask="999.999.999-99" className="form-control" id="cpf" aria-describedby="CPF" value={prtDoc} onChange={(e) => setPrtDoc(e.target.value)} />
                                    </div>
                                    <div className="col">
                                        <label htmlFor="prtPhone" className="form-label">Telefone</label>
                                        <ReactInputMask type="text" mask="(99) 99999-9999" className="form-control" id="prtPhone" aria-describedby="Telefone" value={prtPhone} onChange={(e) => setPrtPhone(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                        </DivRow>

                        <DivRow>
                            <div className="container">
                                <div className="row">
                                    <div className="col">
                                        <label htmlFor="nasc" className="form-label">Data de Nascimento</label>
                                        <input type="date" className="form-control" id="nasc" aria-describedby="Data de Nascimento" value={prtNasc} onChange={(e) => setPrtNasc(e.target.value)} />
                                    </div>
                                    <div className="col">
                                        <label htmlFor="prtIncome" className="form-label">Renda Mensal</label>
                                        <input type="text" className="form-control" id="prtIncome" aria-describedby="Renda Mensal" value={prtIncome} onChange={(e) => setPriceToFormat(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                        </DivRow>

                        <DivRow>
                            <div className="container">
                                <div className="row">
                                    <div className="col">
                                        <label htmlFor="prof" className="form-label">Ocupação Profissional</label>
                                        <input type="text" className="form-control" id="prof" aria-describedby="Ocupação Profissional" value={prtJob} onChange={(e) => setPrtJob(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                        </DivRow>

                        <DivRow>
                            <div className="container">
                                <div className="row">
                                    <div className="col-10">
                                        <label htmlFor="prtAddress" className="form-label">Endereço</label>
                                        <input type="text" className="form-control" id="prtAddress" aria-describedby="Endereço" value={prtAddress} onChange={(e) => setPrtAddress(e.target.value)} />
                                    </div>
                                    <div className="col-2">
                                        <label htmlFor="prtNum" className="form-label">Numero</label>
                                        <input type="number" className="form-control" id="prtNum" aria-describedby="Numero" value={prtNum} onChange={(e) => setPrtNum(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                        </DivRow>


                        <DivRow>
                            <div className="container">
                                <div className="row">
                                    <div className="col">
                                        <label htmlFor="prtComplement" className="form-label">Complemento</label>
                                        <input type="text" className="form-control" id="prtComplement" aria-describedby="Complemento" value={prtComplement} onChange={(e) => setPrtComplement(e.target.value)} />
                                    </div>
                                    <div className="col">
                                        <label htmlFor="prtDistrict" className="form-label">Bairro</label>
                                        <input type="text" className="form-control" id="prtDistrict" aria-describedby="Bairro" value={prtDistrict} onChange={(e) => setPrtDistrict(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                        </DivRow>

                        <DivRow>
                            <div className="container">
                                <div className="row">
                                    <div className="col">
                                        <label htmlFor="cepPrt" className="form-label">CEP</label>
                                        <input type="text" className="form-control" id="cepPrt" aria-describedby="CEP" value={prtZip} onChange={(e) => getCep(e.target.value)} />
                                    </div>
                                    <div className="col">
                                        <label htmlFor="cityPrt" className="form-label">Cidade</label>
                                        <input type="text" className="form-control" id="cityPrt" aria-describedby="Cidade" value={prtCity} />
                                    </div>
                                </div>
                            </div>
                        </DivRow>

                        <DivRow>
                            <div className="container">
                                <div className="row">
                                    <div className="col">
                                        <label htmlFor="prtState" className="form-label">Estado</label>
                                        <input type="text" className="form-control" id="prtState" aria-describedby="Estado" value={prtState} />
                                    </div>
                                    <div className="col">
                                        <label htmlFor="prtCountry" className="form-label">País</label>
                                        <input type="text" className="form-control" id="prtCountry" aria-describedby="País" value={prtCountry} />
                                    </div>
                                </div>
                            </div>
                        </DivRow>

                        <DivRow>
                            <div className="container">
                                <div className="row">
                                    <div className="col">
                                        <label htmlFor="referencePrt" className="form-label">Ponto de Refência</label>
                                        <input type="text" className="form-control" id="referencePrt" aria-describedby="Ponto de Refência" value={prtReferencePoint} onChange={(e) => setPrtReferencePoint(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                        </DivRow>

                        <div className="mb-3">
                            <Checkbox
                                checked={prtLegal}
                                onChange={handleChange}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                            <label className="form-label" style={{ color: '#717171' }}>Declaro ser representante da empresa </label>
                        </div>

                        <div className="container" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            {creating &&
                                <div style={{ padding: '0px 10px' }}>
                                    {/* <ButtonSilverSmall onClick={() => alert(storeName)}> Cancelar </ButtonSilverSmall> */}
                                </div>
                            }
                            {creating ? <div style={{ padding: '0px 10px' }}>
                                <ButtonSilverSmall >Salvar</ButtonSilverSmall>
                            </div> :
                                <div style={{ padding: '0px 10px' }}>
                                    {prtLegal ?
                                        <ButtonGreenSmall onClick={() => setAsStoref(storeName, tradeName, cnpj, phone, address, num, complement, district, zip, city, state, country, referencePoint, incomeS, partnerName, partnerEmail, prtDoc, prtNasc, prtJob, prtIncomeS, prtAddress, prtNum, prtComplement, prtDistrict, prtZip, prtCity, prtState, prtCountry, prtLegal, prtPhone, prtReferencePoint)}>Salvar</ButtonGreenSmall> : <ButtonSilverSmall >Salvar</ButtonSilverSmall>}
                                </div>}
                        </div>
                    </InternContent>
                }
                {cepError === true && <SnackBar type="warning" time={3000} message="Verifique seu Cep" states={true} />}
                {createError === true && <SnackBar type="error" time={3000} message="Ouve um erro ao cadastrar loja" states={true} />}
                {createSuccess === true && <SnackBar type="success" time={3000} message="Loja criada com sucesso" states={true} />}
            </Modal>
        </Container>

    );
}

export default SetasStore;