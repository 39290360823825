import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import {
    Container,
} from './styles';


const CarouselProduct = ({ images }) => {

    return (
        <Container>
            <Carousel autoPlay={true}
                showThumbs={false}
                interval={6000}
                infiniteLoop={true}
                showStatus={false}>

                {images?.map((list) => (
                    <div key={list.title}>
                        <img src={list.image} alt={list.title}/>
                        {/*  <p className="legend">Legend 1</p> */}
                    </div>
                ))}
            </Carousel>
        </Container>
    );
};

export default CarouselProduct;