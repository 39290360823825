import React from "react";
import { ButtonBlueSmall, ButtonGreenSmall } from "../../../../../Styles/stylesButtons";
import * as ShippingFunct from '../../../../../Services/shippiment';
import InputGroup from "../../../General/InputGroup/Group";
import BasicPopover from "../../../General/Popper/indes";
import { TitleH6 } from "../../../../../Styles/style";
import Grid from '@mui/material/Grid';
import Select from "react-select";
import SnackBar from "../../../General/SnackBar";

import {
    Container,
} from './styles';
import { formatarMoeda } from "../../../../../Services/payment";
import { setMinimunValue } from "../../../../../Services/StoreCheckout";


const StoreConfig = ({ storeInf, infoS, funcGetP }) => {

    const options = [
        { value: 'Brasil', label: 'Todo o País' },
        { value: 'estado', label: 'Todo(s) o(s) Estado(s)' },
        { value: 'mar', label: 'Macro Região' },
        { value: 'mir', label: 'Micro Região' },
        { value: 'city', label: 'Cidade(s)' }
    ]

    const [success, setSuccess] = React.useState(false);
    const [error, setError] = React.useState(false);

    const [selectedStates, setSelectedStates] = React.useState([]);
    const [stateList, setStateList] = React.useState([])

    const [selectedMacro, setSelectedMacro] = React.useState([]);
    const [listMacro, setListMacro] = React.useState([]);

    const [selectedMicro, setSelectedMicro] = React.useState([]);
    const [listMicro, setListMicro] = React.useState([]);

    const [selectedCities, setSelectedCities] = React.useState([]);
    const [cityList, setCityList] = React.useState([]);

    const [deliveryType, setDeliveryType] = React.useState();
    const [deliveryTypeDisp, setDeliveryTypeDisp] = React.useState();
    const [order, setOrder] = React.useState();
    const [minimalValue, setMinimalVal] = React.useState();

    const [loadAgain, setloadAgain] = React.useState(false);



    const getStates = async () => {
        try {
            const stateResponse = await ShippingFunct.getState()
            if (stateResponse?.status_code === 200) {
                setStateList(stateResponse?.data);
            }
        } catch (error) {

        }
    }
    const getMacro = async (state) => {
        try {
            const shipmentUrl = `api/store/shipment/mesorregioes/${state.map(item => item.value).join('|')}`;
            const response = await ShippingFunct.getMacroRegion(shipmentUrl)
            setListMacro(response?.data);
            setloadAgain(false);
        } catch (error) {

        }
    }
    const getMicro = async (state) => {
        try {
            const shipmentUrl = `api/store/shipment/microrregioes/${state.map(item => item.value).join('|')}`;
            const response = await ShippingFunct.getMacroRegion(shipmentUrl)
            setListMicro(response?.data);
            setloadAgain(false);
        } catch (error) {

        }
    }
    const getCity = async (state) => {
        try {
            const shipmentUrl = `api/store/shipment/states/${state?.map(item => item.value).join('|')}/cities`;
            const response = await ShippingFunct.getMacroRegion(shipmentUrl)
            setCityList(response?.data);
            setloadAgain(false);
        } catch (error) {

        }
    }


    const postCountry = async () => {
        try {
            await ShippingFunct.postCountryFunc();
            finishOperation();
        } catch (error) {
            errorOperation()
        }
    }

    const postStates = async () => {
        try {
            await ShippingFunct.postStatesFunc(selectedStates);
            finishOperation();
        } catch (error) {
            errorOperation()
        }
    }

    const postMacro = async () => {
        try {
            await ShippingFunct.postMacroRegion(selectedMacro);
            finishOperation();
        } catch (error) {
            console.log(error)
            errorOperation()
        }
    }

    const postMicro = async () => {
        try {
            await ShippingFunct.postMicroRegion(selectedMicro)
            finishOperation();
        } catch (error) {
            errorOperation()
        }
    }

    const postCities = async () => {
        try {
            await ShippingFunct.postCitiesFunc(selectedCities)
            finishOperation();
        } catch (error) {
            errorOperation()
        }
    }

    const setMinimalValue = async (e) => {
        if (typeof e == 'number') return e;
        var nr;
        var virgulaSeparaDecimais = e.match(/(,)\d{2}$/);
        if (virgulaSeparaDecimais) nr = e.replace(/\./g, '').replace(',', '.')
        else nr = e.replace(',', '').replace('.', '');
        const priceReturn = await formatarMoeda(e)
        setOrder(priceReturn);
        setMinimalVal(parseFloat(nr))
    }

    const saveMinalValue = async () => {
        try {
            await setMinimunValue(minimalValue, storeInf?.StoreSlug);
            finishOperation();
        } catch (error) {
            errorOperation();
        }
    }

    const finishOperation = () => {
        setSuccess(true);
        funcGetP();
        setTimeout(() => {
            setSuccess(false);
        }, 3000);
    }

    const errorOperation = () => {
        setError(true);
        setTimeout(() => {
            setError(false)
        }, 3000);
    }

    const macroOptions = listMacro?.map(sta => ({ label: sta?.nome, value: sta?.id }));
    const cityOptions = cityList?.map(sta => ({ label: sta?.nome, value: sta?.id }));
    const micrOptions = listMicro?.map(sta => ({ label: sta?.nome, value: sta?.id }));
    const statesOptions = stateList?.map(sta => ({ label: sta?.nome, value: sta?.id }));

    /*  React.useEffect(() => {
         cityOptions = cityList?.map(sta => ({ label: sta?.nome, value: sta?.id }));
     }, [cityList]) */

    const cleanOpt = () => {
        setloadAgain(true)
        /*  setSelectedCities([]);
         setSelectedMicro([]);
         setSelectedMacro([]);
         setListMacro([]);
         setListMicro([]);
         setCityList([]); */
    }

    const getValuesMinimun = async () => {
        const priceReturn = await formatarMoeda(infoS?.store?.minimum_order_value);
        setOrder(priceReturn)
    }

    React.useEffect(() => {
        getValuesMinimun();
        getStates();
    }, []);


    React.useEffect(() => {
        if (infoS?.store?.shipment_locales?.mesorregioes) {
            setDeliveryType("mar");
            setDeliveryTypeDisp(options?.filter(opt => opt.value === "mar")[0]);
            setSelectedMacro(infoS?.store?.shipment_locales?.mesorregioes?.map(sta => ({ label: sta?.nome, value: sta?.id })));
            setListMacro(infoS?.store?.shipment_locales?.mesorregioes);
            const filteredStates = stateList.filter(state => infoS?.store?.shipment_locales?.mesorregioes?.some(m => m.state_id === state.id));
            setSelectedStates(filteredStates.map(state => ({ label: state.nome, value: state.id })));
            const st = filteredStates.map(state => ({ label: state.nome, value: state.id }));
            getMacro(st);
        } else if (infoS?.store?.shipment_locales?.country) {
            setDeliveryType("Brasil");
            setDeliveryTypeDisp(options?.filter(opt => opt.value === "Brasil")[0]);
        } else if (infoS?.store?.shipment_locales?.states) {
            setDeliveryType("estado");
            setDeliveryTypeDisp(options?.filter(opt => opt.value === "estado")[0]);
            setSelectedStates(infoS?.store?.shipment_locales?.states?.map(state => ({ label: state.nome, value: state.id })));
        } else if (infoS?.store?.shipment_locales?.microrregioes) {
            setDeliveryType("mir");
            setDeliveryTypeDisp(options?.filter(opt => opt.value === "mir")[0]);
            setSelectedMicro(infoS?.store?.shipment_locales?.microrregioes?.map(sta => ({ label: sta?.nome, value: sta?.id })));
            setListMicro(infoS?.store?.shipment_locales?.microrregioes);
            const filteredStates = stateList.filter(state => infoS?.store?.shipment_locales?.microrregioes?.some(m => m.state_id === state.id));
            setSelectedStates(filteredStates.map(state => ({ label: state.nome, value: state.id })));
            const st = filteredStates.map(state => ({ label: state.nome, value: state.id }));
            getMicro(st);
        } else if (infoS?.store?.shipment_locales?.cities) {
            setDeliveryType("city");
            setDeliveryTypeDisp(options?.filter(opt => opt.value === "city")[0]);
            setSelectedCities(infoS?.store?.shipment_locales?.cities?.map(sta => ({ label: sta?.nome, value: sta?.id })));
            setCityList(infoS?.store?.shipment_locales?.cities);
            const filteredStates = stateList.filter(state => infoS?.store?.shipment_locales?.cities?.some(m => m.state_id === state.id));
            setSelectedStates(filteredStates.map(state => ({ label: state.nome, value: state.id })));
            const st = filteredStates.map(state => ({ label: state.nome, value: state.id }));
            getCity(st);
        }
    }, [stateList])

    return (
        <Container>
            <div style={{ padding: '2%' }}>
                <TitleH6> Configurar Locais de Entrega</TitleH6>
            </div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <label>Locais de entrega</label>
                    <Select options={options} onChange={(opt) => { setDeliveryType(opt.value); setDeliveryTypeDisp(opt) }} value={deliveryTypeDisp} />
                </Grid>
                {deliveryType !== "Brasil" && <Grid item xs={12}>
                    <label>Estado(s)</label>
                    <Select options={statesOptions} isMulti onChange={(opt) => { setSelectedStates(opt); cleanOpt() }} value={selectedStates} />
                </Grid>}
                {deliveryType === "mar" &&
                    <Grid item xs={12}>
                        <label>Macro Região</label>
                        {loadAgain || listMacro?.length === 0 ? <div style={{ padding: '10px 0px' }}><ButtonBlueSmall onClick={() => getMacro(selectedStates)}>Carregar</ButtonBlueSmall></div> :
                            <Select options={macroOptions} onChange={(opt) => setSelectedMacro(opt)} isMulti value={selectedMacro} />}
                    </Grid>}
                {deliveryType === "mir" && <Grid item xs={12}>
                    <label>Micro Região</label>
                    {loadAgain || listMicro?.length === 0 ? <div style={{ padding: '10px 0px' }}><ButtonBlueSmall onClick={() => getMicro(selectedStates)}>Carregar</ButtonBlueSmall></div> :
                        <Select options={micrOptions} onChange={(opt) => setSelectedMicro(opt)} isMulti value={selectedMicro} />}
                </Grid>}
                {deliveryType === "city" && <Grid item xs={12}>
                    <label>Cidade(s)</label>
                    {loadAgain || cityList?.length === 0 ? <div style={{ padding: '10px 0px' }}><ButtonBlueSmall onClick={() => getCity(selectedStates)}>Carregar</ButtonBlueSmall></div> :
                        <Select options={cityOptions} onChange={(opt) => setSelectedCities(opt)} isMulti value={selectedCities} />}
                </Grid>}

            </Grid>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', paddingTop: '25px' }}>
                <div style={{ cursor: 'pointer' }}><BasicPopover title="Como cadastrar os locais de entrega"
                    text={<p><small>Primeiro selecione a região que atenderá </small><br />
                        <small>Você pode selecionar cidades especificas por estados, <br />regiões por estados, todo o estado ou todo o País</small><br /><br />
                        <small>Caso selecione cidades especificas ou regiões específicas por estado, <br /> primeiramente selecione os estados desejados<br />
                            depois clique em carregar para buscar as opções de regiões ou cidades de cada estado <br /> ao finalizar basta salvar</small></p>} />
                </div>
                {deliveryType === "Brasil" && <ButtonGreenSmall style={{ width: '10rem' }} onClick={() => postCountry()}>Salvar País</ButtonGreenSmall>}
                {deliveryType === "estado" && <ButtonGreenSmall style={{ width: '10rem' }} onClick={() => postStates()}>Salvar Estados</ButtonGreenSmall>}
                {deliveryType === "mar" && <ButtonGreenSmall style={{ width: '15rem' }} onClick={() => postMacro()}>Salvar Macro Regiões</ButtonGreenSmall>}
                {deliveryType === "mir" && <ButtonGreenSmall style={{ width: '15rem' }} onClick={() => postMicro()}>Salvar Micro Regiões</ButtonGreenSmall>}
                {deliveryType === "city" && <ButtonGreenSmall style={{ width: '10rem' }} onClick={() => postCities()}>Salvar Cidades</ButtonGreenSmall>}

            </div>
            {error && <SnackBar type="error" message="Houve um erro ao salvar" time={3000} states={true} />}
            {success && <SnackBar type="success" message="Salvo com sucesso" time={3000} states={true} />}
            <hr></hr>
            <div style={{ padding: '2%' }}>
                <TitleH6> Configurar Pedido Mínimo</TitleH6>
            </div>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <InputGroup label={["Pedido minimo"]} type={["text"]} id={["orde"]} value={[order]} customClass={["col"]} setState={setMinimalValue} group={false} />
                </Grid>
            </Grid>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', paddingTop: '25px' }}>
                <div style={{ cursor: 'pointer' }}><BasicPopover title="O que é ?" text={<p><small>Valor mínimo de pedido aceito</small></p>} /></div>
                <ButtonGreenSmall onClick={() => saveMinalValue()}>Salvar</ButtonGreenSmall>
            </div>
            <hr></hr>
        </Container>
    )
}

export default StoreConfig