import React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';
import * as LogoutFunction from '../../../../Services/logout';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { useInformation } from '../../../../Providers/informations';

import {
    Container,
    LinkStyle,
    LinkStyleBlack
} from './styles';


const MenuProfile = ({ status, type, name }) => {

    const logout = () => {
        LogoutFunction.Logout();
    }

    const { info } = useInformation();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Container>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <Tooltip title="Minha Conta">
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <Avatar sx={{ width: 42, height: 42, backgroundColor: '#009c43' }}></Avatar>
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >


                {status === "true" &&
                    <div key={"menu"}>
                        <MenuItem sx={{ display: 'flex', justifyContent: 'space-between', cursor: 'default', color: '#009c43', }}>
                            <Link style={LinkStyle} to="/produtos/cart/lista"> <ShoppingCartIcon sx={{ cursor: 'pointer' }} /> </Link> <Link to="/produtos/favoritos/lista" style={LinkStyle}><FavoriteIcon sx={{ cursor: 'pointer' }} /></Link> <NotificationsActiveIcon sx={{ cursor: 'pointer' }} />
                        </MenuItem>
                        <Divider />
                        <Link to="/usuario/perfil" style={LinkStyleBlack}>
                            <MenuItem>

                                Meu Perfil
                            </MenuItem>
                        </Link>

                        <Link to="/usuario/meus_pedidos" style={LinkStyleBlack}>
                            <MenuItem>

                                Minhas Compras
                            </MenuItem>
                        </Link>
                        <Divider />
                        {info.CientType !== "customer" && info.CientType !== null ?
                            <Link to="/loja/perfil" style={LinkStyleBlack}>
                                <MenuItem>
                                    Minha Loja
                                </MenuItem>
                            </Link>
                            :
                            <></>
                        }
                        {info.CientType !== "customer" && info.CientType !== null ?
                            <Link to="/loja/vendas" style={LinkStyleBlack}>
                                <MenuItem>
                                    Minhas Vendas
                                </MenuItem>
                            </Link>
                            :
                            <></>
                        }
                        <Divider />
                    </div>
                }

                {status === "true" ?
                    <MenuItem onClick={logout}>
                        Logout
                    </MenuItem> :
                    <Link to="login/param?redirect=home" style={LinkStyleBlack}>
                        <MenuItem>
                            Login
                        </MenuItem>
                    </Link>}
            </Menu>
        </Container>
    )
}

export default MenuProfile;