import React from 'react';
import moment from 'moment';
import {
    Container,
    Copyright,
    MenuLine1,
    Menu,
} from './styles';

const FooterApp = () => {
    return (
        <Container>
            <Menu>
                <MenuLine1>
                    <Copyright>
                        <p>Copyright © {moment().format('YYYY')} produtosagro.com.br</p>
                    </Copyright>
                </MenuLine1>

            </Menu>
        </Container>
    );
};

export default FooterApp;