import React from 'react';
import { useInformation } from '../../../../Providers/informations';
import { Modal } from 'react-bootstrap';

import {
    Container,
} from './styles';
import { ButtonGreenSmall } from '../../../../Styles/stylesButtons';
import SmallCardProduct from '../SmallCardProduct';
import { useNavigate } from 'react-router';


const GoToBuy = () => {

    const navigate = useNavigate()
    const { modalGoToCart, setModalGoToCart, cart } = useInformation();

    const goToCart = () => {
        setModalGoToCart(false);
        navigate('/produtos/cart/lista')
    }

    const handleCloseModal = () => {
        setModalGoToCart(false)
    }

    return (
        <Container>
            <></>
            <Modal show={modalGoToCart} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Adicionado ao Carrinho</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <SmallCardProduct title={cart[0]?.title} img={`${process.env.REACT_APP_LINK}/${cart[0]?.images[0]}`} price={cart[0]?.total_price} slug={cart[0]?.product_slug} cat={cart[0]?.category} store={cart[0]?.store_slug} />
                    <div style={{ cursor: 'pointer', color: '#009c43', }}><p onClick={() => handleCloseModal()} >Continar Comprando</p></div>
                </Modal.Body>
                <Modal.Footer style={{ display: 'flex', flexDirection: 'row' }}>
                    <div><ButtonGreenSmall onClick={() => goToCart()} style={{ width: '15rem', height: '35px' }}>Ir para o carrinho</ButtonGreenSmall></div>

                </Modal.Footer>
            </Modal>
        </Container>
    )
}

export default GoToBuy;