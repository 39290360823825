import React from 'react';
import List from '@mui/material/List';
import * as GetProducts from '../../../Services/getProducts';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import { ButtonGreen } from '../../../Styles/stylesButtons';
import { TitleH3 } from '../../../Styles/style';
import * as ViewedPrduct from '../../../Services/addViewProduct';
import { useNavigate, Link } from 'react-router-dom';
import { useInformation } from '../../../Providers/informations';
import SnackBar from '../../Components/General/SnackBar';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import Rating from '@mui/material/Rating';
import DeleteIcon from '@mui/icons-material/Delete';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ShareIcon from '@mui/icons-material/Share';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

import {
    Container,
    Content,
    FavoriteArea,
    Line,
    EmptyDiv,
    Image,
    Mobile,
    Web,
    MiniCard,
    TopLine,
    DivSearch,
} from './styles';
import { Search } from '../Store/Products/ProductsList/styles';
import { postBuyNow } from '../../../Services/orders';

const Favorite = () => {

    const [favoritesProducts, setFavoriteproducts] = React.useState([]);

    const navigate = useNavigate();
    const { setUpdateCart } = useInformation();

    const [carSuccess, setCarSuccess] = React.useState(false)
    const [carError, setCarError] = React.useState(false);
    const [exportSc, setExportSc] = React.useState(false);
    const [searchFav, setSearch] = React.useState('');

    const [deletedSuccess, setDeletedSuccess] = React.useState(false);
    const [deletedError, setDeletedError] = React.useState(false);

    const [listSize, setListSize] = React.useState();

    const getFavorites = async () => {
        const products = await GetProducts.getFavorites();
        setFavoriteproducts(products.data.products);
        setListSize(products?.data?.products?.length)
    }

    const addToCart = async (productSlug, storeSlug) => {
        if (localStorage.getItem("ClientAuth") === "true") {
            const add = await ViewedPrduct.addToCart(productSlug, 1, storeSlug);
            if (add.status_code !== 200 || !add) {
                setCarError(true);
                setTimeout(() => {
                    setCarError(false);
                }, 4000);
            } else {
                setCarSuccess(true);
                setUpdateCart(true);
                setTimeout(() => {
                    setCarSuccess(false);
                }, 4000);
            }
        } else {
            navigate(`/login/ebe67af1-1bde`);
        }
    }


    const copyLink = (productSlug, storeSlug) => {
        const currentURL = window.location.href;
        const urlObject = new URL(currentURL);
        const domain = urlObject.origin;

        const textToCopy = `${domain}/produto/detalhes/${storeSlug}/${productSlug}`;
        if (navigator.clipboard) {
            navigator.clipboard.writeText(textToCopy)
                .then(() => {
                    setExportSc(true);
                    setTimeout(() => {
                        setExportSc(false)
                    }, 6000);
                })
                .catch((err) => {
                    console.error('Erro ao copiar texto:', err);
                    // Lidar com erros de cópia, se necessário.
                });
        } else {
            const textArea = document.createElement('textarea');
            textArea.value = textToCopy;
            document.body.appendChild(textArea);
            textArea.select();
            document.execCommand('copy');
            document.body.removeChild(textArea);
        }
    }

    const removeFavo = async (productSlug, storeSlug) => {
        try {
            const removed = await ViewedPrduct.removeFavorite(productSlug, storeSlug);
            if (removed.message === "success") {
                setDeletedSuccess(true);
                getFavorites();
                setTimeout(() => {
                    setDeletedSuccess(false);
                }, 4000);
            }
            else {
                setDeletedError(true);
                setTimeout(() => {
                    setDeletedError(false);
                }, 4000);
            }
        } catch (error) {
            setDeletedError(true);
            setTimeout(() => {
                setDeletedError(false);
            }, 4000);
        }
    }

    React.useEffect(() => {
        getFavorites();
    }, []);

    const buyNow = async (slug, store) => {
        if (localStorage.getItem("ClientAuth") === "true") {
            try {
                const response = await postBuyNow(store, slug, 1)
                if (response.message === "success") {
                    navigate('/produtos/compra/finalizar/verify')
                }
            } catch (error) {
                navigate('/produtos/compra/finalizar/verify')
            }
        } else {
            navigate(`/login/param?slug=${slug}&storelug=${store}&addto=buyNow`);
        }

    }

    const options = { style: 'currency', currency: 'BRL' };

    return (
        <Container>
            <Content>
                <FavoriteArea>
                    <Line style={{ justifyContent: 'space-between', display: "flex", paddingTop: '15px', }}>
                        <TopLine>
                            <div style={{ width: '70%' }}>
                                <TitleH3>Minha Lista de Favoritos</TitleH3>
                            </div>
                            <DivSearch >
                                <Search style={{ height: '100%' }}>
                                    <input placeholder='Buscar produtos...' id='searchProduct' value={searchFav} onChange={(e) => setSearch(e.target.value)} />
                                    <div>
                                        <SearchOutlinedIcon style={{ cursor: 'pointer' }} fontSize='large' />
                                    </div>
                                </Search>
                            </DivSearch>
                        </TopLine>
                    </Line>
                    <hr></hr>
                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                        {listSize >= 1 ? favoritesProducts?.filter((products) => {
                            if (searchFav === "") {
                                return products
                            } else if (products.title.toLowerCase().includes(searchFav.toLocaleLowerCase())) {
                                return products
                            } else {
                                return null
                            }
                        })?.map((products) =>
                            <div key={products.id}>
                                <div style={{ padding: '15px' }}>
                                    <ListItem alignItems="flex-start" >
                                        <Box
                                            sx={{
                                                cursor: 'pointer',
                                                display: 'flex',
                                                flexWrap: 'wrap',
                                                '& > :not(style)': {
                                                    m: 1,
                                                    width: 100,
                                                    height: 100,
                                                },
                                            }}
                                            onClick={() => navigate(`/produto/detalhes/${products.store_slug}/${products.product_slug}`)}
                                        >

                                            <Image style={{ backgroundImage: `url(${process.env.REACT_APP_LINK}/${products?.images?.[0]})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center' }} />

                                        </Box>
                                        <ListItemText
                                            primary={<div style={{ paddingLeft: '25px' }}>
                                                <div style={{ color: '#434343' }}>{products.title}</div>
                                                <div>  <Rating name="read-only" value={products?.review_stars} readOnly sx={{ color: '#009C43' }} /></div>
                                                <div style={{ color: '#000', fontWeight: '500', fontSize: '1rem', fontStyle: 'normal' }}>{products.price?.toLocaleString('pt-BR', options)}</div>
                                                <div style={{ color: '#000', fontWeight: '500', fontSize: '1rem' }}>{products?.store_name}</div>
                                            </div>}

                                        />
                                        <Web>
                                            {products?.stock <= 0 ? <ButtonGreen style={{ color: '#707070', backgroundColor: '#DDDDDD', borderRadius: '16px', height: '100%' }} disabled>
                                                Comprar Agora
                                            </ButtonGreen> : <ButtonGreen style={{ borderRadius: '16px', height: '100%' }} onClick={() => addToCart(products.product_slug, products.store_slug)}>
                                                Comprar Agora
                                            </ButtonGreen>}
                                            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
                                                <MiniCard>
                                                    <ShoppingCartIcon onClick={() => addToCart(products.product_slug, products.store_slug)} />
                                                </MiniCard>
                                                <MiniCard>
                                                    <ShareIcon onClick={() => copyLink(products.product_slug, products.store_slug)} />
                                                </MiniCard>
                                                <MiniCard onClick={() => removeFavo(products.product_slug, products.store_slug)}>
                                                    <DeleteIcon />
                                                </MiniCard>
                                            </div>
                                        </Web>
                                    </ListItem>
                                    <Mobile >
                                        {products?.stock <= 0 ? <ButtonGreen style={{ color: '#707070', backgroundColor: '#DDDDDD', borderRadius: '16px', height: '100%' }} disabled>
                                            Comprar Agora
                                        </ButtonGreen> : <ButtonGreen style={{ borderRadius: '16px', height: '100%' }} onClick={() => addToCart(products.product_slug, products.store_slug)}>
                                            Comprar Agora
                                        </ButtonGreen>}
                                        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
                                            <MiniCard>
                                                <ShoppingCartIcon onClick={() => addToCart(products.product_slug, products.store_slug)} />
                                            </MiniCard>
                                            <MiniCard>
                                                <ShareIcon onClick={() => copyLink(products.product_slug, products.store_slug)} />
                                            </MiniCard>
                                            <MiniCard onClick={() => removeFavo(products.product_slug, products.store_slug)}>
                                                <DeleteIcon />
                                            </MiniCard>
                                        </div>
                                    </Mobile>
                                </div>
                                <Divider />
                            </div>
                        ) :
                            <EmptyDiv>
                                <FavoriteBorderIcon fontSize='large' color='success' sx={{ width: '100px', height: '100px' }} />
                                <p>Seu lista está vazia!</p>
                                <Link to="/">Explore produtos e adicione a sua lista</Link>
                            </EmptyDiv>
                        }

                    </List>
                </FavoriteArea>
            </Content>

            {carSuccess && <SnackBar type="success" time={4000} message={"Adicionado ao carrinho com sucesso"} states={true} />}
            {carError && <SnackBar type="error" time={4000} message={"Erro ao adicionar ao carrinho"} states={true} />}

            {deletedSuccess && <SnackBar type="success" time={4000} message={"Produto removido da lista"} states={true} />}
            {exportSc && <SnackBar type="success" time={4000} message={"Link copiado com sucesso!"} states={true} />}
            {deletedError && <SnackBar type="error" time={4000} message={"Erro ao remover produto da lista"} states={true} />}
        </Container>
    )
}

export default Favorite;