import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    justify-content: center;
`;
export const DivB = styled.div`
    width: 40%;

    @media(max-width: 800px){
        width: 90%;
    }
`;