import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/system';

function handleClick(event) {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
}

const IconBreadcrumbs = ({ list }) => {
    const navigate = useNavigate();
    return (
        <div style={{ width: '100%', background: '#FFFFFF', padding: '5px', margin: '5px 0', borderRadius: '6px', boxShadow: '0px 1px 2px #00000029' }}>
            <div role="presentation" onClick={handleClick}>
                <Breadcrumbs aria-label="breadcrumb">
                    {list?.map((list) => (
                        <Box key={list?.where}
                            onClick={() => navigate(list?.link)}
                            underline="hover"
                            sx={{ display: 'flex', alignItems: 'center', color: `${list?.color}`, cursor: 'pointer' }}
                            color="inherit"
                            href={``}
                        >

                            {list?.icon}

                            {list?.where}
                        </Box>
                    ))}

                    {/*    <Typography
                        sx={{ display: 'flex', alignItems: 'center' }}
                        color="text.primary"
                    >
                        <GrainIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                        Breadcrumb
                    </Typography> */}
                </Breadcrumbs>
            </div>
        </div>
    );
}

export default IconBreadcrumbs