import styled from "styled-components";
import { createTheme } from '@mui/material/styles';

export const Container = styled.div`
    padding: 2%;
`;

export const Line = styled.div`
    display: flex;

`;
export const Column = styled.div`
    padding: 10px;

    >h3 {
        color: #707070;
        font-size: 2.5rem;
        font-weight: 600;
        font-family: Mulish;
    }

    >small {
        color: #707070;
        font-size: 1rem;
        font-weight: 600;
        font-family: Mulish;   
    }
  
`;
export const Div = styled.div`
    background: #ffffff;
    border-radius: 6px;
`;
export const ImageColection = styled.div`
    width: 15%;
    height: 100%;
    align-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-top: 15px;
    padding-bottom: 20px;
    overflow: auto;
    >div{
        margin-top: 10px;
        width: 60px;
        height: 60px;
        border: 0.5px solid #3333 ;
        border-radius: 6px;
        align-content: center;
        align-items: center;
        justify-content: center;
        display: flex;
        cursor: pointer;

        &:hover{
         opacity: 0.8;
         transform: translateY(-5px);
         border: 2px solid #009c43;
     }

        >img{
            width: 90%;
            height: 90%;
            position: cover;
        }
    }
`;
export const Line2 = styled.div`
    width: 100% ;
    padding: 15px;
    align-items: center;
    justify-content: center;
    color:  #707070;
    flex-direction: column ;
    >input{
        width: 25%;
        height: 35px ;
        border-radius: 6px;
        border: 1px solid #3333 ;
        font-size: 0.9rem;
        padding-left: 10px;

    }
`;
export const ContentCard = styled.div`
    width: 100%;
    height: 100vh;
    background-color: #FFFFFF ;
    box-shadow: 0px 0px 9px #26242424;
    border-radius: 6px;
`;

export const TopTitleDiv = styled.div`
    width: 100%;
    height: 1005;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 2px #00000029;
    border-bottom: 1px solid #DDDDDD;
    opacity: 1;
    padding: 5px;
`;

export const ListContentOrder = styled.div`
    overflow: auto;
`;

export const theme2 = createTheme({
    palette: {
        primary: {
            // Purple and green play nicely together.
            main: '#009c43',
        },
        secondary: {
            // This is green.A700 as hex.
            main: '#009c43',
        },
    },
});

export const LoadingDiv = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;