import React from "react";
import Avatar from '@mui/material/Avatar';
import { useNavigate } from "react-router-dom";
import {
    Container,
} from './styles';


const CircIconShow = ({ image, title, slug }) => {

    const navigate = useNavigate();

    return (
        <Container>
            <div className="Image" onClick={() => navigate(`categorias/${slug}/${title}`)}>
                <Avatar
                    alt={title}
                    src={image}
                    sx={{ width: 100, height: 100 }}
                />
            </div>
            <div className="Title">
                <h3 style={{ fontSize: '1rem' }}>{title}</h3>
            </div>
        </Container>
    )
}

export default CircIconShow;