import styled from "styled-components";

export const Container = styled.div`
    grid-area: MH;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    background-color: #fff ;
    box-shadow: 0px 5px 9px #26242424;
    height: 90px;
    width: 100vw;
    margin-bottom: 15px;
    @media(max-width: 900px){
        height: 70px;
    }
    @media(min-width: 1921px){
        
    }
  
`;
export const Search = styled.div`
        width: 80%;
        height: 70%;
        border: 1px solid #A9ABAE;
        border-radius: 50px;
        background-color: #ffffff ;
        display: flex;
        align-items: center;
        padding-left: 8px;
        justify-content: space-between;
        @media(max-width: 800px){
           flex: 1;
           height: 90%;
    }
    >input {
        width: 80%;
        height: 65%;
        font-size: 1rem;
        padding-left: 20px;
        background-color: #fff ;
        color: #A9ABAE;
       
    }
    > div {
        background-color: #009c43;
        color: #fff;
        width: 40px;
        height: 99%;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        border-radius: 100px;
    }
    >input::placeholder {
            color: #A9ABAE;
        }
        >select{
            background-color: #F1F1F1 ;
        }
`;
export const Left = styled.div`
    width: 180px;
    height: 100%;
    background-color: #009c43;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    border-radius: 0px 6px 6px 0px;
    transition: width 0.8s;
    > img {
        width: 90px;
        cursor: pointer;
        @media(max-width: 800px){
            width: 60px;
    }
    }
    @media(max-width: 900px){
            width: 75px;
    }
`;
export const Middle = styled.div`
    background-color: #ffffff ;
    width: 60%;
    height: 100%;
    display: flex;
    align-items: center;
    transition: width 2s;

    > div {
        padding: 15px;
        >h3 {
            font-size: 0.9rem;
            color: #A9ABAE;
            cursor: pointer;
            text-transform: uppercase;
        }
    }

    @media(max-width: 800px){
        transition: 1s;
        display: none;
    }
`;
export const Div1 = styled.div`
    @media(max-width: 800px){
        display: none;
        transition: 1s;
    }
`;
export const Right = styled.div`
    background: #ffffff;
    width: 30%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 15px;
    color: #009c43;
    transition: width 2s;
    @media(max-width: 800px){
        flex: 1;
    }
`;

export const Div = styled.div`
    @media(max-width: 800px){
        display: none;
    }
`;