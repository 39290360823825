import React from 'react';
import { useLocation, useParams, useNavigate, Link } from 'react-router-dom';
import * as GetProducts from '../../../../../Services/getProducts';
import { ButtonGreen } from '../../../../../Styles/stylesButtons';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Rating from '@mui/material/Rating';
import * as ViewedPrduct from '../../../../../Services/addViewProduct';
import { useInformation } from '../../../../../Providers/informations';
import SnackBar from '../../SnackBar';
import { ThemeProvider } from '@mui/material/styles';
import { getAddressWithZipCode, shippingAvailable } from '../../../../../Services/address';
import { Modal } from 'react-bootstrap';
import Spinner from '../../SmallComponents/spinner';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import DOMPurify from 'dompurify';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import InfoIcon from '@mui/icons-material/Info';

import {
    Container,
    Column,
    Line,
    Div,
    Line2,
    ImageColection,
    ContentCard,
    ListContentOrder,
    TopTitleDiv,
    theme2,
    LoadingDiv,
} from './styles';
import RatingCard from '../../Rating';
import GoToBuy from '../../GoToBuy';
import { postBuyNow } from '../../../../../Services/orders';
import Ask from '../../Ask';
import { Tooltip } from '@mui/material';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}



const ProductDetails = () => {

    const location = useLocation();
    const navigate = useNavigate();
    let { store, slug } = useParams();
    const theme = useTheme();
    const { setUpdateCart, setModalGoToCart, cart, setCart } = useInformation();


    const [value, setValue] = React.useState(0);
    const [quant, setQuant] = React.useState(1);


    const [cartSuccess, setCartSuccess] = React.useState(false);
    const [cartError, setCartError] = React.useState(false);

    const [favoriteSuccess, setFavoriteSuccess] = React.useState(false);
    const [favoriteError, setFavoriteError] = React.useState(false);

    const [loading, setLoading] = React.useState(false);

    const [show, setShow] = React.useState(false);

    const [shipping, setShipping] = React.useState();
    const [shippingStatus, setShippingStatus] = React.useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    const [imageSelect, setImageSelected] = React.useState(0);

    const [description, setDescription] = React.useState([]);
    const [specification, setSpecification] = React.useState();
    const [discaunt, setDiscount] = React.useState();
    const [minimalValue, setMinimalValue] = React.useState();

    const [askList, setAskList] = React.useState([]);

    const [stars, setStars] = React.useState(0);
    const getDetail = async () => {
        let detail = await GetProducts.getdetailProduct(slug, store);
        setDescription(detail?.data?.product);
        setMinimalValue(detail?.data?.product?.store_minimum_order_value)
        setSpecification(DOMPurify.sanitize(detail?.data?.product?.specifications))
        setStars(parseFloat(detail?.data?.product?.stars))
        const dif = detail.data.product.price - detail.data.product.other_prices.pix;
        const discount = (100 * dif) / detail.data.product.price;
        const discountWithOneDecimal = discount.toFixed(1);
        setDiscount(parseFloat(discountWithOneDecimal));
    }

    const addToCart = async (slug, storeSlug) => {
        if (localStorage.getItem("ClientAuth") === "true") {
            const add = await ViewedPrduct.addToCart(slug, quant, storeSlug);
            if (add.status_code !== 200 || !add) {
                setCartError(true);
                setTimeout(() => {
                    setCartError(false);
                }, 4000);
            } else {
                if (cart?.length === 0) {
                    setModalGoToCart(true);
                }
                getListCarProd();
                setCartSuccess(true);
                setUpdateCart(true);
                setTimeout(() => {
                    setCartSuccess(false);
                }, 4000);
            }
        } else {
            localStorage.setItem("product", slug);
            localStorage.setItem("gotToCart", "yes");
            navigate(`/login/param?slug=${slug}&storelug=${storeSlug}&addto=car`);
        }
    }

    const addToFavorite = async (slug, storeSlug) => {
        if (localStorage.getItem("ClientAuth") === "true") {
            try {
                const addFavorite = await ViewedPrduct.addToFavorite(slug, storeSlug);
                if (addFavorite.message === "success") {
                    setFavoriteSuccess(true);
                    setTimeout(() => {
                        setFavoriteSuccess(false)
                    }, 4000);
                }
                else {
                    setFavoriteError(true);
                    setTimeout(() => {
                        setFavoriteError(false)
                    }, 4000);
                }
            } catch (error) {
                setFavoriteError(true);
                setTimeout(() => {
                    setFavoriteError(false)
                }, 4000);
            }

        } else {
            navigate(`/login/param?slug=${slug}&storelug=${storeSlug}&addto=myList`);
        }
    }

    const getListCarProd = async () => {
        try {
            const response = await GetProducts.getProductsCart();
            setCart(response.data.products)
        } catch (error) {

        }
    }
    const getZipCode = async (zip) => {
        if (zip.length >= 8) {
            setLoading(true);
            handleShow();
            const addressResponse = await getAddressWithZipCode(zip);
            setShipping(addressResponse);
            if (addressResponse?.erro === true) {
                setLoading(false);
            } else {
                const verifyShipping = await shippingAvailable(store, addressResponse.ibge);
                if (verifyShipping?.data?.shipment_available === true) {
                    setShippingStatus(true);
                } else {
                    setShippingStatus(false);
                }
                setLoading(false);
            }

        }
    }

    const [reviewistm, setReviewList] = React.useState([]);

    const getReviews = async () => {
        try {
            const reviewResponse = await GetProducts.getReviews(store, slug)
            setReviewList(reviewResponse.data)
        } catch (error) {
            console.log("error", error.response)

        }
    }

    const buyNow = async () => {
        if (localStorage.getItem("ClientAuth") === "true") {
            try {
                const response = await postBuyNow(store, slug, quant)
                if (response.message === "success") {
                    navigate('/produtos/compra/finalizar/verify')
                }
            } catch (error) {
                navigate('/produtos/compra/finalizar/verify')
            }
        } else {
            navigate(`/login/param?slug=${slug}&storelug=${store}&addto=buyNow`);
        }

    }

    const getAsks = async () => {
        try {
            const response = await GetProducts.getQuestionsList(store, slug);
            setAskList(response);
        } catch (error) {

        }
    }

    React.useEffect(() => {
        let add = ViewedPrduct.addViewedProduct(slug, store);
        console.log(add)
        getDetail();
    }, [location])

    const options = { style: 'currency', currency: 'BRL' };

    return (
        <Container>
            <Line>
                <Column style={{ width: '50%', height: '30rem' }} >
                    <Div style={{ width: '100%', height: '100%', display: 'flex' }}>
                        <ImageColection>
                            <div style={{ border: imageSelect === 0 && '2px solid #009c43' }}>
                                <img src={`${process.env.REACT_APP_LINK}/${description?.images?.[0]}`} alt="produto" onClick={() => setImageSelected(0)} />
                            </div>
                            {description.images?.[1] && <div style={{ border: imageSelect === 1 && '2px solid #009c43' }}>
                                <img src={`${process.env.REACT_APP_LINK}/${description?.images?.[1]}`} alt="produto" onClick={() => setImageSelected(1)} />
                            </div>}
                            {description.images?.[2] && <div style={{ border: imageSelect === 2 && '2px solid #009c43' }}>
                                <img src={`${process.env.REACT_APP_LINK}/${description?.images?.[2]}`} alt="produto" onClick={() => setImageSelected(2)} />
                            </div>}
                            {description.images?.[3] && <div style={{ border: imageSelect === 3 && '2px solid #009c43' }}>
                                <img src={`${process.env.REACT_APP_LINK}/${description?.images?.[3]}`} alt="produto" onClick={() => setImageSelected(3)} />
                            </div>}
                            {description.images?.[4] && <div style={{ border: imageSelect === 4 && '2px solid #009c43' }}>
                                <img src={`${process.env.REACT_APP_LINK}/${description?.images?.[4]}`} alt="produto" onClick={() => setImageSelected(4)} />
                            </div>}
                            {description.images?.[5] && <div style={{ border: imageSelect === 5 && '2px solid #009c43' }}>
                                <img src={`${process.env.REACT_APP_LINK}/${description?.images?.[5]}`} alt="produto" onClick={() => setImageSelected(5)} />
                            </div>}
                        </ImageColection>
                        <div style={{ width: '80%', height: '70%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10%', borderRadius: '6px' }}>
                            <img src={`${process.env.REACT_APP_LINK}/${description?.images?.[imageSelect]}`} alt=" description" style={{ maxWidth: '70%', maxHeight: '25rem' }} />
                        </div>
                    </Div>
                </Column>

                <Column style={{ width: '50%', height: '30rem' }} >
                    <div style={{ width: '100%', height: '20%', paddingBottom: '5px' }}>
                        <Div style={{ width: '100%', height: '100%', display: 'flex' }}>
                            <Column style={{ width: '30%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Avatar
                                    alt="logo Loja"
                                    src={`${process.env.REACT_APP_LINK}/storage/stores/${description?.store_logo}`}
                                    sx={{ width: 56, height: 56 }}
                                />
                            </Column>
                            <Column style={{ flex: '1', display: 'flex', flexDirection: 'column' }}>
                                <small style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>Vendido por: &nbsp; {description.store_name}</small>
                                <Rating name="read-only" value={stars} readOnly sx={{ color: '#009c43' }} />
                                <small>{description?.total_reviews} Avaliações</small>
                            </Column>
                            <Column style={{ width: '5%', background: description?.stock >= 1 ? '#009c43' : '#c32329', borderRadius: '0px 6px 6px 0px', textAlign: 'center', }}>
                                <p style={{ transform: 'rotate(-90deg)', marginTop: '50px', fontSize: '0.8rem', color: '#fff' }}>{description?.stock >= 1 ? "Disponivel" : "Indisponivel"}</p>
                            </Column>
                        </Div>
                    </div>
                    <div style={{ width: '100%', height: '80%', paddingTop: '5px' }}>
                        <Div style={{ width: '100%', height: '100%', }}>
                            <Line style={{ width: '100%', height: '20%', padding: '0px 15px' }}>
                                <p style={{ fontSize: '1.3rem', color: '#707070', fontFamily: 'Mulish', fontWeight: '500' }}>{description.title}</p>
                            </Line>
                            <Line style={{ display: 'flex', width: '100%', height: '40%', paddingTop: '10px' }}>
                                <Column style={{ width: '60%' }}>
                                    <h3>{description.price?.toLocaleString('pt-BR', options)}</h3>
                                    <small>Desconto de </small><small style={{ color: '#009c43', fontWeight: 'bold' }}>{discaunt}% no pix</small>
                                    <br></br>
                                    <Tooltip title={`Válido para compras a partir de ${(minimalValue / 100)?.toLocaleString('pt-BR', options)} nessa loja`}>
                                        <small style={{ color: '#009c43', fontSize: '0.9rem', cursor: 'pointer' }}>Frete Grátis</small>
                                    </Tooltip>
                                    <small style={{ fontSize: '0.9rem' }}>, Confira Disponibilidade</small>
                                </Column>
                                <Column style={{ width: '40%' }}>
                                    <Line2>
                                        <label style={{ paddingRight: '15px' }}>Quantidade</label>
                                        <input type="number" style={{ width: '80px' }} onChange={(e) => setQuant(Math.min(e.target.value, description?.stock))} value={quant} />
                                        <small style={{ color: description?.stock >= 1 ? '#009c43' : '#c32329' }}> {description?.stock >= 1 ? <div>Estoque disponível: <small style={{ color: '#000' }}>{description?.stock}</small></div> : "Estoque indisponível"} </small>
                                    </Line2>
                                </Column>
                            </Line>
                            <Line style={{ width: '100%', height: '40%', display: 'flex', flexDirection: 'column' }}>
                                <Line2>
                                    <label style={{ paddingRight: '15px' }}>Verificar Disponibilidade de entrega</label>
                                    <input type="text" placeholder="99500-000" onChange={(e) => getZipCode(e.target.value)} />
                                </Line2>

                                <Line style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                    {description?.stock >= 1 ? <div style={{ padding: '0px 15px', width: '15%' }}><div style={{ width: '50px', height: '50px', borderRadius: '100%', color: '#ffffff', background: '#009c43', display: 'flex', cursor: 'pointer', alignItems: 'center', justifyContent: 'center' }} onClick={() => addToCart(slug, store)}><ShoppingCartIcon /></div></div>
                                        :
                                        <div style={{ padding: '0px 15px', width: '15%' }}><div style={{ width: '50px', height: '50px', borderRadius: '100%', color: '#707070', background: '#DDDDDD', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><ShoppingCartIcon /></div></div>
                                    }

                                    <div style={{ padding: '0px 15px', width: '15%' }}><div style={{ width: '50px', height: '50px', borderRadius: '100%', color: '#ffffff', background: '#009c43', display: 'flex', cursor: 'pointer', alignItems: 'center', justifyContent: 'center' }} onClick={() => addToFavorite(slug, store)}><FavoriteIcon /></div></div>
                                    <div style={{ padding: '0px 15px', flex: '1' }}>
                                        {description?.stock >= 1 ? <ButtonGreen style={{ width: '100%' }} onClick={() => buyNow(slug, store)} >Comprar Agora</ButtonGreen>
                                            :
                                            <ButtonGreen style={{ width: '100%', color: '#707070', backgroundColor: '#DDDDDD' }} disabled >Comprar Agora</ButtonGreen>
                                        }

                                    </div>
                                </Line>
                            </Line>
                        </Div>
                    </div>
                </Column>
            </Line>
            <Line>
                <Column style={{ width: '100%', height: '800px', }}>
                    <Div style={{ background: 'red', height: '100%' }}>
                        <ContentCard style={{ height: '100%', }}>
                            <TopTitleDiv>
                                <Div style={{ width: '100%', height: '20%', paddingBottom: '15px' }}>
                                    <Column style={{ width: '100%' }}>
                                        <p style={{ color: '#707070', fontSize: '1.1rem', fontWeight: '600', fontFamily: 'Mulish' }}>{description?.title}</p>
                                        <small style={{ color: '#707070', fontSize: '0.9rem', fontFamily: 'Mulish' }}>{description?.description}</small>
                                    </Column>
                                </Div>
                                <Divider />
                                <AppBar position="static" sx={{ background: '#FFFFFF', height: '20%', color: 'black', border: 'none', boxShadow: '0' }}>
                                    <ThemeProvider theme={theme2}>
                                        <Tabs
                                            value={value}
                                            onChange={handleChange}
                                            indicatorColor="primary"
                                            textColor="inherit"
                                            variant="fullWidth"
                                            aria-label="detail"
                                        >
                                            <Tab label="Especificações" {...a11yProps(0)} style={{ color: value === 0 && '#009c43', fontSize: '0.9rem', fontWeight: '700', }} />
                                            <Tab label="Avaliações" {...a11yProps(1)} style={{ color: value === 1 && '#009c43', fontSize: '0.9rem', fontWeight: '700', }} onClick={() => getReviews()} />
                                            <Tab label="Perguntas" {...a11yProps(2)} style={{ color: value === 2 && '#009c43', fontSize: '0.9rem', fontWeight: '700', }} onClick={() => getAsks()} />
                                        </Tabs>
                                    </ThemeProvider>
                                </AppBar>
                            </TopTitleDiv>
                            <ListContentOrder>
                                <SwipeableViews
                                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                    index={value}
                                    onChangeIndex={handleChangeIndex}
                                >
                                    <TabPanel value={value} index={0} dir={theme.direction} style={{ height: '35rem' }} >
                                        <div style={{}}>
                                            <div dangerouslySetInnerHTML={{ __html: specification }}></div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel value={value} index={1} dir={theme.direction}>
                                        Avaliações
                                        <div style={{ height: 'auto', overflow: 'auto' }}>

                                            <Grid container spacing={2}>
                                                {reviewistm?.map((review) => (
                                                    <Grid item xs={4} Key={review?.id}>
                                                        <RatingCard stars={review?.stars} userName={review.user} date={review?.date} text={review.description} />
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </div>
                                    </TabPanel>
                                    <TabPanel value={value} index={2} dir={theme.direction}>
                                        Perguntas
                                        {localStorage.getItem("ClientAuth") === "true" ? <Ask store={store} product={slug} getQuestionsF={getAsks} dataList={askList} val={value} /> :
                                            <div style={{ textAlign: 'right' }}>
                                                <Link to="/login">Login para perguntar</Link>
                                            </div>
                                        }

                                    </TabPanel>
                                </SwipeableViews>
                            </ListContentOrder>
                        </ContentCard>
                    </Div>
                </Column>
            </Line>

            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Disponibilidade de entrega</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading ? <LoadingDiv>
                        <Spinner />

                    </LoadingDiv> : <LoadingDiv>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">CEP</th>
                                    <th scope="col">Cidade</th>
                                    <th scope="col">Estado</th>
                                    <th scope="col">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{shipping?.cep} </td>
                                    <td>{shipping?.localidade}</td>
                                    <td>{shipping?.uf}</td>
                                    <th scope="row">{shippingStatus ? `Frete Grátis` : "Ainda não chegamos na sua região"}</th>
                                </tr>
                            </tbody>
                        </table>
                        {shippingStatus ? <LocalShippingIcon fontSize='large' color="success" /> : <TravelExploreIcon fontSize='large' color="success" />}
                    </LoadingDiv>}
                    {shippingStatus && <div style={{ color: '#009c43' }}><InfoIcon /> <small> Válido para compras a partir de {(minimalValue / 100)?.toLocaleString('pt-BR', options)} nessa loja!</small></div>}
                </Modal.Body>
                <Modal.Footer>
                    {!loading && <ButtonGreen onClick={handleClose}>Continuar</ButtonGreen>}
                </Modal.Footer>
            </Modal>
            <GoToBuy />

            {cartSuccess && <SnackBar type="success" time={4000} message={"Adicionado ao carrinho com sucesso"} states={true} />}
            {cartError && <SnackBar type="error" time={4000} message={"Erro ao adicionar ao carrinho"} states={true} />}

            {favoriteSuccess && <SnackBar type="success" time={4000} message={"Adicionado a sua lista de favoritos"} states={true} />}
            {favoriteError && <SnackBar type="error" time={4000} message={"Erro ao adicionar a lista de favoritos"} states={true} />}
        </Container>
    )
}

export default ProductDetails;