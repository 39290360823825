import axios from "axios";

export async function payment() {
    try {

    } catch (error) {

    }
}

export async function finishOrder(products, address, payment_method) {
    var data = new FormData();
    products.map(product => (product)).forEach(product => data.append("shopping_cart[]", parseInt(product.id)));
    data.append("address_id", parseInt(address));
    data.append("payment_method", payment_method);
    const { data: res } = await axios({ method: 'post', url: `${process.env.REACT_APP_LINK}/api/user/orders`, headers: { 'Authorization': `Bearer ${localStorage.getItem("clientToken")}` }, data })
    return res
}

export async function finishOrderCreditCard(products, address, payment_method, installments, card_number, card_name, card_exp_month, card_exp_year, card_cvv) {
    var data = new FormData();
    products.map(product => (product)).forEach(product => data.append("shopping_cart[]", parseInt(product.id)));
    data.append("address_id", parseInt(address));
    data.append("payment_method", payment_method);
    data.append("installments", installments);
    data.append("card_number", card_number);
    data.append("card_name", card_name);
    data.append("card_exp_month", card_exp_month);
    data.append("card_exp_year", card_exp_year);
    data.append("card_cvv", card_cvv);
    const { data: res } = await axios({ method: 'post', url: `${process.env.REACT_APP_LINK}/api/user/orders`, headers: { 'Authorization': `Bearer ${localStorage.getItem("clientToken")}` }, data })
    return res
}


export async function formatarMoeda(value) {
    var valor = await value;

    valor = valor + '';
    valor = parseInt(valor.replace(/[\D]+/g, ''));
    valor = valor + '';
    valor = valor.replace(/([0-9]{2})$/g, ",$1");

    if (valor.length > 6 && valor.length <= 8) {
        valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
    }
    if (valor.length > 9 && valor.length <= 11) {
        valor = valor.replace(/([0-9]{6}),([0-9]{2}$)/g, ".$1,$2");
        valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
        /* valor = valor.replace('.', ''); */
    }
    if (valor.length > 13) {
        valor = valor.replace(/([0-9]{9}),([0-9]{2}$)/g, ".$1,$2");
        valor = valor.replace(/([0-9]{6}),([0-9]{2}$)/g, ".$1,$2");
        valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
        /* valor = valor.replace('.', ''); */
    }
    if (valor === 'NaN') {
        return ''
    } else {
        /* valor = valor.replace('.', ''); */
        return valor
    }

}