import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    justify-content: center;
    padding-top: 3%;
    padding-bottom: 4%;
`;

export const Header = styled.div`
    padding-bottom: 3%;
`;

export const Content = styled.div`
    width: 60%;
`;

export const Group = styled.div`
    padding-top: 2%;
    padding-bottom: 4%;
`;