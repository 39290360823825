import React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import Typography from '@mui/material/Typography';
import { Modal } from "react-bootstrap";


import {
    Container,
} from './styles';
import moment from 'moment';
import { Line } from '../../../Pages/Store/RequestWithdrawl/styles';


const StoreTimeLine = ({ value }) => {

    const options = { style: 'currency', currency: 'BRL' };
    const [show, setShow] = React.useState(false);
    const [detalS, setDetalS] = React.useState();

    const detail = (val) => {
        setShow(!show);
        setDetalS(val)
    }

    const handleClose = () => {
        setShow(!show)
    }


    return (
        <Container>
            <Timeline position="alternate">
                {value?.map((val) => (
                    <TimelineItem key={val?.id}>
                        <TimelineSeparator>
                            <TimelineConnector />
                            {val?.status === "failed" && <TimelineDot onClick={() => detail(val)} sx={{ cursor: 'pointer' }} color="error"><MonetizationOnIcon /></TimelineDot>}
                            {val?.status === "pending" && <TimelineDot onClick={() => detail(val)} sx={{ cursor: 'pointer' }} color="info"><MonetizationOnIcon /></TimelineDot>}
                            {val?.status === "processing" && <TimelineDot onClick={() => detail(val)} sx={{ cursor: 'pointer' }} color="info"><MonetizationOnIcon /></TimelineDot>}
                            {val?.status === "transferred" && <TimelineDot onClick={() => detail(val)} sx={{ cursor: 'pointer' }} color="success"><MonetizationOnIcon /> </TimelineDot>}
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                            <Typography>{(val?.amount / 100).toLocaleString('pt-BR', options)}</Typography>
                            <Typography>{moment(val?.updated_at).format('DD/MM/YYYY')} </Typography>
                        </TimelineContent>
                    </TimelineItem>
                ))}

                <TimelineItem>
                    <TimelineSeparator>
                        <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
                        <TimelineDot color="secondary">
                            <PlayCircleFilledIcon />
                        </TimelineDot>
                    </TimelineSeparator>
                    <TimelineContent sx={{ py: '12px', px: 2 }}>
                        <Typography variant="h6" component="span">
                            Loja Criada
                        </Typography>
                        {/* <Typography>12/04/2022</Typography> */}
                    </TimelineContent>
                </TimelineItem>

            </Timeline>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Recibo da Transferência</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Line style={{ width: '100%', justifyContent: 'space-between' }}>
                        <small>Id</small>
                        <small>{detalS?.id}</small>
                    </Line>
                    <Line style={{ width: '100%', justifyContent: 'space-between' }}>
                        <small>Valor:</small>
                        <small>{(detalS?.amount / 100)?.toLocaleString('pt-BR', options)}</small>
                    </Line>
                    <Line style={{ width: '100%', justifyContent: 'space-between' }}>
                        <small>Taxa de transferência:</small>
                        <small>{(detalS?.fee / 100)?.toLocaleString('pt-BR', options)}</small>
                    </Line>
                    <Line style={{ width: '100%', justifyContent: 'space-between' }}>
                        <small>Solicitado:</small>
                        <small>{moment(detalS?.created_at).format('DD/MM/YYYY')} </small>
                    </Line>
                    <Line style={{ width: '100%', justifyContent: 'space-between' }}>
                        <small>Data estimada de depósito:</small>
                        <small>{moment(detalS?.funding_estimated_date).format('DD/MM/YYYY')} </small>
                    </Line>
                    <Line style={{ width: '100%', justifyContent: 'space-between' }}>
                        <small>Status</small>
                        {detalS?.status === "transferred" && <small style={{ color: '#009c43' }}> Concluído</small>}
                        {detalS?.status === "processing" && <small style={{ color: '#0288D1' }}> Em Processamento</small>}
                        {detalS?.status === "pending" && <small style={{ color: '#0288D1' }}> Pendente</small>}
                        {detalS?.status === "failed" && <small style={{ color: '#FA4B57' }}> Não Concluído</small>}
                    </Line>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </Container>
    )
}

export default StoreTimeLine;