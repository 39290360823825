import React from 'react';
import { ButtonGreenSmall } from '../../../Styles/stylesButtons';
import { Link } from 'react-router-dom';

import {
    Container,
    Middle,
} from './styles';


const Error404 = () => {

    return (
        <Container>
            <Middle>
                <h2>404</h2>
                <h6>Essa págian não existe</h6>
                <div style={{ padding: '15px 25px' }}>
                    <p>
                        Verifique se o link está correto!
                    </p>
                </div>
                <Link to="/" style={{ textDecoration: 'none' }}>
                    <ButtonGreenSmall style={{ width: '16rem', height: '3rem', fontWeight: '600', fontSize: '0.9rem' }}>Voltar</ButtonGreenSmall>
                </Link>
            </Middle>
        </Container>
    )
}

export default Error404;