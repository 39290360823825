import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    width: 220px;
    height: 80vh;
    background-color: #fff ;
    box-shadow: 0px 0px 9px #26242424;
    border-radius: 6px;
    overflow-y: auto;
    padding-right: 5px;
    text-decoration: none;
    color: #A9ABAE;
    @media(min-width: 1920px){
        height: 85vh;
    }

`;