import React from "react";
import { ButtonGreenSmall, ButtonBlueSmall, ButtonRedSmall } from '../../../../../Styles/stylesButtons';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { useInformation } from "../../../../../Providers/informations";
import * as StoreCheckout from '../../../../../Services/StoreCheckout';
import { updateStoreProfile } from "../../../../../Services/store";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { TitleH3, TitleH6 } from '../../../../../Styles/style';
import { useNavigate } from "react-router-dom";
import VisibilityIcon from '@mui/icons-material/Visibility';
import InputGroup from "../../../General/InputGroup/Group";
import { ThemeProvider } from '@mui/material/styles';
import ModalComponent from '../../../General/Modal';
import Typography from '@mui/material/Typography';
import SnackBar from '../../../General/SnackBar';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import StoreConfig from "../StoresConfig";
import Tabs from '@mui/material/Tabs';
import { Modal } from '@mantine/core';
import StoreAddress from './address';
import Box from '@mui/material/Box';
import { Disabled } from './acount';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import moment from "moment/moment";
import BasicPopover from "../../../General/Popper/indes";



import {
    Container,
    ListContentOrder,
    DeleteSection,
    ContentCard,
    TopTitleDiv,
    theme2,
} from './styles';
import { SmallCard } from "../../StoreDashboard/styles";
import { Badge, Checkbox } from "@mui/material";
import { formatarMoeda } from "../../../../../Services/payment";
import MouseOverPopover from "./popover";
import { fontSize } from "@mui/system";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const ProfileStoreTabs = ({ balanceDisp, balanceIndsp, prof, getFunc }) => {

    const { info, storeInfo, open, setOpen, setInfo } = useInformation();
    const navigate = useNavigate();

    const [hasChangeBanck, setHasChangeBacnk] = React.useState(false);
    const [successUpdated, setSuccessUpdate] = React.useState(false);
    const [banckSuccess, setBanckSuccess] = React.useState(false);
    const [errorUpdate, setErrorUpdate] = React.useState(false);
    const [banckError, setBanckError] = React.useState(false);
    const [hasChange, setHasChange] = React.useState(false);
    const [modalIcon, setModalIcon] = React.useState(false);
    const [hidden, setHidden] = React.useState(true);
    const [confiStore, setConfstore] = React.useState();
    const [confSteps, setConfSteps] = React.useState({
        cad: false,
        aprov: false,
        bankInfo: false,
        shipLocal: false,
        minimunSales: false,

    })


    /* Store */
    const [phone, setPhone] = React.useState(storeInfo.StorePhone);
    const [name, setName] = React.useState(storeInfo.StoreName);
    const [tradeName, setTradeName] = React.useState(storeInfo.StoreName);
    const [cnpj, setCnpj] = React.useState(storeInfo.StoreCnpj);
    const [address, setAddress] = React.useState({
        street: storeInfo.StoreStreet,
        complement: storeInfo.StoreComplement,
        district: storeInfo.StoreDistrict,
        num: storeInfo.StoreNumber,
        city: storeInfo.StoreCity,
        state: storeInfo.StoreState,
        country: storeInfo.StoreCountry,
        zip: storeInfo.StoreZip_code,
    });
    const [banckInfotmation, setBanckInformation] = React.useState([]);
    const [banckDescriptio, setBanckDescriptio] = React.useState()
    const [banckAccount, setBanckAccount] = React.useState();
    const [banckAgency, setBanckAgency] = React.useState();
    const [banckCode, setBanckCode] = React.useState();
    const [banckDigi, setBanckDigi] = React.useState();


    /* Partner */
    const [prtPhone, setPrtPhone] = React.useState(storeInfo.StorePhone);
    const [prtName, setPrtName] = React.useState(storeInfo.StoreName);
    const [prtEmail, setPrtEmail] = React.useState(storeInfo.StoreName);
    const [prtDoc, setPrtDoc] = React.useState(storeInfo.StoreCnpj);
    const [prtNasc, setPrtNasc] = React.useState();
    const [prtInco, setPrtInco] = React.useState();
    const [prtInco2, setPrtInco2] = React.useState();
    const [prtJob, setPrtJob] = React.useState();
    const [prtLegal, setPrtLegal] = React.useState(true);
    const [prtAddress, setPrtAddress] = React.useState({
        street: storeInfo.StoreStreet,
        complement: storeInfo.StoreComplement,
        district: storeInfo.StoreDistrict,
        num: storeInfo.StoreNumber,
        city: storeInfo.StoreCity,
        state: storeInfo.StoreState,
        country: storeInfo.StoreCountry,
        zip: storeInfo.StoreZip_code,
    });



    const [imgBack, setImgBack] = React.useState();
    const [img, setImg] = React.useState();
    const [imgBackBanner, setImgBackBanner] = React.useState();
    const [imgBanner, setImgBanner] = React.useState();

    const [indexValue, setIndexValue] = React.useState(1);
    const [value, setValue] = React.useState(0);

    const [tab, setTab] = React.useState('store');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const afterSuccess = () => {
        setInfo({ ClientName: null });
        navigate('/loja/perfil');
    }

    const viewData = () => {
        setHidden(false);
        setHasChangeBacnk(false);
    }
    const hiddenData = () => {
        setHidden(true);
        setHasChangeBacnk(false);

    }

    const setPriceToFormat = async (e) => {
        var nr;
        const priceReturn = await formatarMoeda(e);
        setPrtInco(priceReturn);

        if (typeof e !== 'number') {
            var virgulaSeparaDecimais = priceReturn.match(/,\d{2}$/);
            if (virgulaSeparaDecimais) {
                nr = priceReturn.replace(/\./g, '').replace(',', '');
            }
            setPrtInco2(nr);
        } else {
            setPrtInco2(e);

        }

    }

    const UpdateStore = async (storeSlug, name, cnpj, phone, address, img, imge2) => {
        const updateUserResponse = await updateStoreProfile(storeSlug, name, cnpj, phone, address, img, imge2);
        if (updateUserResponse?.response?.data.message) {
            setErrorUpdate(true);
            setTimeout(() => {
                setErrorUpdate(false)
            }, 4000);
        }
        else {
            setHasChange(false);
            setSuccessUpdate(true);
            setTimeout(() => {
                setSuccessUpdate(false)
            }, 4000);
            afterSuccess();
        }
    }

    const saveData = async (banckDescriptio, banckCode, banckAgency, banckAccount, banckDigi) => {
        const backSavedAccount = await StoreCheckout.setAccountChek(banckDescriptio, banckCode, banckAgency, banckAccount, banckDigi);
        if (backSavedAccount?.response?.data.message) {
            setBanckError(true);
            setTimeout(() => {
                setBanckError(false);
            }, 4000);
        } else {
            setHasChangeBacnk(false)
            setBanckSuccess(true);
            setTimeout(() => {
                setBanckSuccess(false);
            }, 4000);
            afterSuccess();
        }

    }
    const edititData = async (banckDescriptio, banckCode, banckAgency, banckAccount, banckDigi) => {
        const backSavedAccount = await StoreCheckout.updateAccountChek(banckDescriptio, banckCode, banckAgency, banckAccount, banckDigi);
        if (backSavedAccount?.response?.data.message) {
            setBanckError(true);
            setTimeout(() => {
                setBanckError(false);
            }, 4000);
        } else {
            setHasChangeBacnk(false)
            setBanckSuccess(true);
            setTimeout(() => {
                setBanckSuccess(false);
            }, 4000);
            afterSuccess();
        }

    }

    const handleChangeImg = (event) => {
        var roundTo = null;
        var converted = event.target.files[0]?.size / (1024 * 1024);
        roundTo = converted.toFixed(roundTo);
        if (roundTo > 2) {
            event.target.value = null;
            /*  SetErrorSize(true); */
        } else {
            setImgBack(URL.createObjectURL(event?.target?.files[0]));
            let files = event.target.files;
            let filesReader = new FileReader();
            filesReader.readAsDataURL(files[0]);
            filesReader.onload = (event) => {
                setImg(event.target.result);
            }
        }
    }

    const handleChangeImgBanner = (event) => {
        var roundTo = null;
        var converted = event.target.files[0]?.size / (1024 * 1024);
        roundTo = converted.toFixed(roundTo);
        if (roundTo > 2) {
            event.target.value = null;
            /*  SetErrorSize(true); */
        } else {
            setImgBackBanner(URL.createObjectURL(event?.target?.files[0]));
            let files = event.target.files;
            let filesReader = new FileReader();
            filesReader.readAsDataURL(files[0]);
            filesReader.onload = (event) => {
                setImgBanner(event.target.result);
            }
        }
    }

    React.useEffect(() => {
        setBanckInformation(JSON.parse(storeInfo?.StorePagarme_account_info));
        setBanckDescriptio(banckInfotmation?.default_bank_account?.holder_name);
        setBanckCode(banckInfotmation?.default_bank_account?.bank);
        setBanckAgency(banckInfotmation?.default_bank_account?.branch_number);
        setBanckAccount(banckInfotmation?.default_bank_account?.account_number);
        setBanckDigi(banckInfotmation?.default_bank_account?.account_check_digit);
    }, [hidden])

    React.useEffect(() => {
        setImgBack(storeInfo?.StoreLogo);
        setImgBackBanner(storeInfo?.StoreBanner);
    }, []);

    return (
        <Container>
            <ContentCard>
                <TopTitleDiv>
                    <div style={{ height: '63%', display: 'flex', justifyContent: 'space-between', padding: '15px' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                            <TitleH3>Minha Loja </TitleH3>
                            {storeInfo.StoreStatus === "active" && (!storeInfo?.StoreShipment || !storeInfo?.StoreOrderValue || !storeInfo?.StoreBanck) ?
                                <MouseOverPopover children={<div />} func={setIndexValue}>
                                    <Badge badgeContent=" ! " color="error">
                                        <ButtonBlueSmall style={{ width: '10rem', background: 'orange' }}>Ativação pendente</ButtonBlueSmall>
                                    </Badge>
                                </MouseOverPopover> :
                                <ButtonGreenSmall style={{ width: '10rem' }}>Loja Ativa</ButtonGreenSmall>}
                            {storeInfo.StoreStatus === "pending" && <ButtonGreenSmall>Ativação pendente</ButtonGreenSmall>}
                            {storeInfo.StoreStatus === "blocked" && <ButtonRedSmall>Bloqueada</ButtonRedSmall>}
                            {/*  <ModalComponent buttonText={<SettingsIcon />} modalTitle="Configurações" param={1} widthS={800} comp={<StoreConfig infoS={prof} funcGetP={getFunc} storeInf={storeInfo} />} /> */}
                        </div>
                    </div>
                    <Divider />
                    <AppBar position="static" sx={{ background: '#FFFFFF', height: '20%', color: 'black', border: 'none', boxShadow: '0' }}>
                        <ThemeProvider theme={theme2}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                indicatorColor="primary"
                                textColor="inherit"
                                variant="fullWidth"
                                aria-label="full width tabs example"
                            >
                                <Tab label="Perfil da Loja" onClick={() => setIndexValue(1)} {...a11yProps(0)} style={{ color: value === 0 && '#009c43', fontSize: '0.9rem', fontWeight: '700' }} />
                                <Tab label="Checkout" onClick={() => setIndexValue(2)} {...a11yProps(1)} style={{ color: value === 1 && '#009c43', fontSize: '0.9rem', fontWeight: '700' }} />
                                <Tab label="Configurações" onClick={() => setIndexValue(3)} {...a11yProps(2)} style={{ color: value === 2 && '#009c43', fontSize: '0.9rem', fontWeight: '700' }} />
                            </Tabs>
                        </ThemeProvider>
                    </AppBar>
                </TopTitleDiv>
                <ListContentOrder>
                    {indexValue === 1 && <div>
                        <form onChange={() => setHasChange(true)}>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <div className='col' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '6px', width: '8rem', height: '8rem', paddingLeft: '25px', backgroundColor: '#dfdfdf', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundImage: `url(${imgBackBanner})`, }}>
                                    <label htmlFor="img6" style={{ width: '5rem', height: '5rem', cursor: 'pointer', backgroundColor: '#b8b8b8', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundImage: `url(${imgBack})`, textAlign: 'center', borderRadius: '100%', cursor: 'pointer' }}>
                                        <input onChange={handleChangeImg} accept="image/*" style={{ opacity: '0', width: '100%', height: '50%', }} id="img6" type="file" />
                                    </label>
                                    <div style={{ display: 'flex', alignItems: 'flex-end', height: '100%', padding: '5px' }}>
                                        <input onChange={handleChangeImgBanner} accept="image/*" style={{ opacity: '0', width: '100%', height: '50%', }} id="imgBanner" type="file" />
                                        <label htmlFor='imgBanner'>
                                            <PhotoCameraIcon style={{ cursor: 'pointer' }} />
                                        </label>
                                    </div>
                                </div>

                            </div>
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-around', padding: '1rem 0px' }}>
                                <TitleH6 style={{ width: '200px', borderBottom: tab === "store" && '1px solid #009c43', color: tab === "store" && '#009c43', cursor: 'pointer', textAlign: 'center' }} onClick={() => setTab("store")}>
                                    Loja
                                </TitleH6>
                               {/*  Descomentar depois que implementar a parte da edição da loja e responsavel */}
                               {/*  <TitleH6 style={{ width: '200px', borderBottom: tab === "resp" && '1px solid #009c43', color: tab === "resp" && '#009c43', cursor: 'pointer', textAlign: 'center' }} onClick={() => setTab("resp")}>
                                    Responsável
                                </TitleH6> */}
                            </div>

                            {/* Store */}
                            {tab === "store" && <>
                                <InputGroup label={["Razão Social", "Nome Fantasia"]} type={["text", "text"]} id={["name", "tradeName"]} value={[name, tradeName]} customClass={["col", "col"]} setState={setName} setState2={setTradeName} group={true} />
                                <InputGroup label={["CNPJ", "Telefone"]} type={["text", "text"]} id={["cnpj", "phone"]} value={[cnpj, phone]} customClass={["col", "col"]} setState={setCnpj} setState2={setPhone} group={true} />
                                <ModalComponent asChild={true} modalTitle="ALterar Endereço" comp={<StoreAddress value={address} fun={setAddress} closeModal={setOpen} />}>
                                    <InputGroup label={["Endereço"]} type={["text"]} id={["address"]} value={[`${address.street} nº ${address.num} - ${address.city} - ${address.state} - ${address.country} `]}
                                        customClass={["col"]} group={false} onClick={() => setOpen(!open)} />
                                </ModalComponent>
                                <div className="container" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    {/* <div style={{ padding: '0px 10px' }}>
                                <ButtonBlueSmall> Editar </ButtonBlueSmall>
                            </div> */}
                                        {/* Descomentar depois que implementar a parte da edição da loja e responsavel
                                    {hasChange &&
                                        <div style={{ padding: '0px 10px' }}>
                                            <ButtonGreenSmall onClick={() => UpdateStore(storeInfo.StoreSlug, name, cnpj, phone, address, img, imgBanner)}>Salvar</ButtonGreenSmall>
                                        </div>
                                    } */}
                                </div></>}

                            {/* Partner */}
                            {tab === "resp" && <>
                                <InputGroup label={["Nome Responsável", "Email"]} type={["text", "text"]} id={["prtName", "prtEmail"]} value={[prtName, prtEmail]} customClass={["col", "col"]} setState={setPrtName} setState2={setPrtEmail} group={true} />

                                <InputGroup label={["CPF", "Telefone"]} type={["text", "text"]} id={["cpf", "prtPhone"]}
                                    customClass={["col", "col"]} value={[prtDoc, prtPhone]} setState={setPrtDoc} setState2={setPrtPhone} group={true} />

                                <InputGroup label={["Data de Nascimento", "Renda Mensal"]} type={["date", "text"]} id={["nasc", "prtIncome"]}
                                    customClass={["col", "col"]} value={[prtNasc, prtInco]} setState={setPrtNasc} setState2={setPriceToFormat} group={true} />

                                <InputGroup label={["Ocupação Profissional"]} type={["text"]} id={["prof"]} customClass={["col"]} value={[prtJob]} setState={setPrtJob} group={false} />
                                <ModalComponent asChild={true} modalTitle="ALterar Endereço" comp={<StoreAddress value={prtAddress} fun={setPrtAddress} closeModal={setOpen} />}>
                                    <InputGroup label={["Endereço"]} type={["text"]} id={["address"]} value={[`${prtAddress.street} nº ${prtAddress.num} - ${prtAddress.city} - ${prtAddress.state} - ${prtAddress.country} `]}
                                        customClass={["col"]} group={false} onClick={() => setOpen(!open)} />
                                </ModalComponent>
                                <div className="mb-3">
                                    <Checkbox
                                        checked={prtLegal}
                                        onChange={handleChange}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                    <label className="form-label" style={{ color: '#717171' }}>Declaro ser representante da empresa </label>
                                </div>
                                <div className="container" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    {/* <div style={{ padding: '0px 10px' }}>
                                <ButtonBlueSmall> Editar </ButtonBlueSmall>
                            </div> */}
                                    {hasChange &&
                                        <div style={{ padding: '0px 10px' }}>
                                            <ButtonGreenSmall onClick={() => UpdateStore(storeInfo.StoreSlug, name, cnpj, phone, address, img, imgBanner)}>Salvar</ButtonGreenSmall>
                                        </div>
                                    }
                                </div>
                            </>}
                        </form>
                        <div className="container" style={{ display: 'flex', justifyContent: 'space-between', fontSize: '0.9rem', paddingTop: '5%' }}>
                            <div >
                                <small>Conta criada em: {moment(info.CreatedAt).format('DD/MM/YYYY')}</small>
                            </div>
                            <div>
                                <small>Ultima Edição: {moment(info.UpdatedAt).format('DD/MM/YYYY')}</small>
                            </div>
                        </div>
                        {errorUpdate && <SnackBar type="error" time={4000} message="Não foi possível completar a atualização" states={true} />}
                        {successUpdated && <SnackBar type="success" time={4000} message="Suas informações foram atualizadas" states={true} />}
                    </div>}
                    {indexValue === 2 &&
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <TitleH6 style={{ fontSize: '1.2rem', cursor: 'pointer' }}>
                                    <BasicPopover style={{ fontSize: '0.8rem' }} title="Conta Bancária vinculada ao cnpj da empresa"
                                        text={<p><small>Conta bancária vinculada ao cnpj cadastrado na plataforma</small><br />
                                            <small>Essa conta, será onde o valor das vendas sera depositado.</small><br /><br />
                                            </p>} />
                                </TitleH6>

                                {hidden === true ?
                                    <ButtonBlueSmall style={{ width: '4rem' }} onClick={() => viewData()}>&nbsp; <VisibilityIcon /></ButtonBlueSmall> :
                                    <ButtonBlueSmall style={{ width: '4rem' }} onClick={() => hiddenData()}>&nbsp; <VisibilityOffIcon /></ButtonBlueSmall>
                                }
                            </div>
                            <Divider />
                            {hidden ?
                                <Disabled /> :
                                <form onChange={() => setHasChangeBacnk(true)}>
                                    <InputGroup label={["Titular da Conta"]} type={["text"]} id={["name"]} value={[banckDescriptio]} customClass={["col"]} setState={setBanckDescriptio} group={false} />
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-3">
                                                <label htmlFor="BankCode" className="form-label" style={{ fontSize: '0.8rem' }}>Código do Banco</label>
                                                <input type="number" className="form-control" id="BankCode" value={banckCode} onChange={(e) => setBanckCode(e.target.value)} />
                                            </div>
                                            <div className="col-3">
                                                <label htmlFor="ag" className="form-label" style={{ fontSize: '0.8rem' }}>Agência</label>
                                                <input type="number" className="form-control" id="ag" aria-describedby="agencia" value={banckAgency} onChange={(e) => setBanckAgency(e.target.value)} />
                                            </div>
                                            <div className="col-4">
                                                <label htmlFor="ag" className="form-label" style={{ fontSize: '0.8rem' }}>Conta</label>
                                                <input type="number" className="form-control" id="ag" aria-describedby="agencia" value={banckAccount} onChange={(e) => setBanckAccount(e.target.value)} />
                                            </div>
                                            <div className="col-2">
                                                <label htmlFor="ag" className="form-label" style={{ fontSize: '0.8rem' }}>Dígito</label>
                                                <input type="number" className="form-control" id="ag" aria-describedby="agencia" value={banckDigi} onChange={(e) => setBanckDigi(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                </form>}
                            <div style={{ paddingTop: '5%', display: 'flex', justifyContent: 'flex-end' }}>
                                {hasChangeBanck ? !storeInfo.StorePagarme_id ?
                                    <ButtonGreenSmall style={{ width: '8rem', }} onClick={() => saveData(banckDescriptio, banckCode, banckAgency, banckAccount, banckDigi)}>Salvar dados</ButtonGreenSmall> :
                                    <ButtonGreenSmall style={{ width: '8rem', }} onClick={() => edititData(banckDescriptio, banckCode, banckAgency, banckAccount, banckDigi)}>Editar dados</ButtonGreenSmall> : <></>}

                            </div>
                            <div style={{}}>
                                <TitleH6 style={{ fontSize: '1.2rem' }}>
                                    Ganhos
                                </TitleH6>
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                    <div style={{ width: '80%', display: 'flex', justifyContent: 'center' }}>
                                        <SmallCard style={{ height: '10rem', cursor: 'pointer' }} onClick={() => navigate('/loja/ganhos')}>
                                            <TitleH6>Disponível para saque:</TitleH6>
                                            <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', height: '80%', alignItems: 'center' }}>
                                                <TitleH3> {balanceDisp}</TitleH3>
                                            </div>
                                        </SmallCard>
                                        <SmallCard style={{ height: '10rem', cursor: 'pointer' }} onClick={() => navigate('/loja/ganhos')}>
                                            <TitleH6>A Receber:</TitleH6>
                                            <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', height: '80%', alignItems: 'center' }}>
                                                <TitleH3>{balanceIndsp}</TitleH3>
                                            </div>
                                        </SmallCard>
                                    </div>
                                </div>
                            </div>
                            {banckError && <SnackBar type="error" time={4000} message="Não foi possível completar a ação" states={true} />}
                            {banckSuccess && <SnackBar type="success" time={4000} message="Suas informações bancárias foram salvas" states={true} />}

                        </div>}
                    {indexValue === 3 &&
                        <DeleteSection>
                            <StoreConfig infoS={prof} funcGetP={getFunc} storeInf={storeInfo} />
                        </DeleteSection>}
                </ListContentOrder>
            </ContentCard>
            <Modal
                overflow="inside"
                opened={modalIcon}
                onClose={() => setModalIcon(false)}
                title={"Trocar logo"}
                size="md"
                styles={() => ({ modal: { borderRadius: 10 }, header: { borderBottom: 'solid 2px #c6c6c6' } })}
            >

            </Modal>
        </Container>
    )
}

export default ProfileStoreTabs;