import axios from "axios";

export async function getLines() {
    const { data: res } = await axios({ method: 'get', url: `${process.env.REACT_APP_LINK}/api/admin/product-categories`, headers: { 'Authorization': `Bearer ${localStorage.getItem("Token")}` } })
    return res
}

export async function postLine(title, description, image, status, commission) {
    var data = {
        "title": title,
        "description": description,
        "image": image,
        "commission": commission
    }

    const { data: res } = await axios({ method: 'post', url: `${process.env.REACT_APP_LINK}/api/admin/product-categories`, headers: { 'Authorization': `Bearer ${localStorage.getItem("Token")}` }, data })
    return res
}

export async function putLine(title, description, image, status, slug, commission) {
    var data = {
        "title": title,
        "description": description,
        "image": image,
        "commission": commission
    }

    const { data: res } = await axios({ method: 'put', url: `${process.env.REACT_APP_LINK}/api/admin/product-categories/${slug}`, headers: { 'Authorization': `Bearer ${localStorage.getItem("Token")}` }, data })
    return res
}

export async function getLineSlug(slug) {
    const { data: res } = await axios({ method: 'get', url: `${process.env.REACT_APP_LINK}/api/admin/product-categories/${slug}`, headers: { 'Authorization': `Bearer ${localStorage.getItem("Token")}` } })
    return res
}

export async function deleteLineSl(slug) {
    const { data: res } = await axios({ method: 'delete', url: `${process.env.REACT_APP_LINK}/api/admin/product-categories/${slug}`, headers: { 'Authorization': `Bearer ${localStorage.getItem("Token")}` } })
    return res
}

export async function postBanner(title, status, page, position, banner, date, image, target, action) {
    var data = {
        "title": title,
        "status": status,
        "image": image,
        "page": page,
        "position": position.value,
        "is_banner": banner,
        "expiration_date": date,
        "action": action,
        "target": target
    }

    const { data: res } = await axios({ method: 'post', url: `${process.env.REACT_APP_LINK}/api/admin/banners`, headers: { 'Authorization': `Bearer ${localStorage.getItem("Token")}` }, data })
    return res
}

export async function putBanner(title, status, page, position, banner, date, image, slug, target, action) {
    var data = {
        "title": title,
        "status": status,
        "image": image,
        "page": page,
        "position": position.value,
        "is_banner": banner,
        "expiration_date": date,
        "action": action,
        "target": target
    }

    const { data: res } = await axios({ method: 'put', url: `${process.env.REACT_APP_LINK}/api/admin/banners/${slug}`, headers: { 'Authorization': `Bearer ${localStorage.getItem("Token")}` }, data })
    return res
}

export async function getBanners() {
    const { data: res } = await axios({ method: 'get', url: `${process.env.REACT_APP_LINK}/api/admin/banners`, headers: { 'Authorization': `Bearer ${localStorage.getItem("Token")}` } })
    return res

}
export async function getBannersId(slug) {
    const { data: res } = await axios({ method: 'get', url: `${process.env.REACT_APP_LINK}/api/admin/banners/${slug}`, headers: { 'Authorization': `Bearer ${localStorage.getItem("Token")}` } })
    return res

}

export async function blockStore(slug) {
    const { data: res } = await axios({ method: 'get', url: `${process.env.REACT_APP_LINK}/api/admin/stores/block/${slug}`, headers: { 'Authorization': `Bearer ${localStorage.getItem("Token")}` } })
    return res
}

export async function deleteStore(id) {
    const { data: res } = await axios({ method: 'get', url: `${process.env.REACT_APP_LINK}/api/admin/stores/${id}`, headers: { 'Authorization': `Bearer ${localStorage.getItem("Token")}` } })
    return res
}

export async function getStoresSales(initial, final) {
    const { data: res } = await axios({ method: 'get', url: `${process.env.REACT_APP_LINK}/api/admin/stores/charged-fees/${initial}/${final}`, headers: { 'Authorization': `Bearer ${localStorage.getItem("Token")}` } })
    return res
}