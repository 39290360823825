import React from 'react';

export const InformationsContext = React.createContext({});

export const InformationProvider = (props) => {

    const [info, setInfo] = React.useState({
        clientToken: null,
        ClientAuth: false,
        ClientName: null,
        ClientEmail: null,
        ClientUuid: null,
        CientType: null,
        ClientPhone: null,
        ClientDocumentType: null,
        ClientDocumentNumber: null,
        ClientStoreStatus: null,
        CreatesAt: null,
        UpdatedAt: null,
        ClientEmailVerified: null,
        ClientInscE: null,

    });

    const [storeInfo, setStoreInfo] = React.useState({
        StoreCity: null,
        StoreCnpj: null,
        StoreComplement: null,
        StoreCountry: null,
        StoreCreatedAt: null,
        StoreDistrict: null,
        StoreId: null,
        StoreLogo: null,
        StoreBanner: null,
        StoreName: null,
        StoreNumber: null,
        StorePagarme_account_info: null,
        StorePagarme_id: null,
        StorePhone: null,
        StoreSlug: null,
        StoreState: null,
        StoreStatus: null,
        StoreStreet: null,
        StoreUpdatedAt: null,
        StoreUser_id: null,
        StoreZip_code: null,
        StoreShipment: [],
        StoreBanck: null,
        StoreOrderValue: null,
        StoreIncome: null,
        StoreTradeName: null,
        StoreAdressReference: null

    });

    const [prtInfo, setPrtInfo] = React.useState({
        PrtCity: null,
        Prtdoc: null,
        PrtComplement: null,
        PrtCountry: null,
        PrtCreatedAt: null,
        PrtDistrict: null,
        PrtId: null,
        PrtName: null,
        PrtNumber: null,
        PrtPhone: null,
        PrtState: null,
        PrtStreet: null,
        PrtZip_code: null,
        PrtNasc: null,
        PrtIncome: null,
        PrtOcupation: null,
        PrtAddressReference: null,
        PrtRespo: null

    });


    const [listSearchedProducts, setListSearchedProducts] = React.useState([]);
    const [seledtedProducts, setSelectedProducts] = React.useState([]);
    const [viewdProducst, setViewdProducst] = React.useState([]);
    const [detailSales, setDetailSales] = React.useState([]);
    const [category, setCategory] = React.useState([]);
    const [line1, setLine1] = React.useState([]);
    const [line2, setLine2] = React.useState([]);
    const [line3, setLine3] = React.useState([]);
    const [line4, setLine4] = React.useState([]);
    const [line5, setLine5] = React.useState([]);
    const [cart, setCart] = React.useState([]);


    const [showButtonFinish, setShowButtonFinish] = React.useState(false);
    const [modalGoToCart, setModalGoToCart] = React.useState(false);
    const [emailVerified, setEmailVeried] = React.useState(true);
    const [updateCart, setUpdateCart] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [getStoreP, setGetStoreP] = React.useState(true);

    const [pageWidth, setPageWidth] = React.useState(window.innerWidth);
    const [indexParameter, setIndexParameter] = React.useState(0);
    const [ship, setShip] = React.useState();


    return (
        <InformationsContext.Provider value={{
            cart, setCart, pageWidth, setPageWidth, setStoreInfo, storeInfo, info, setInfo, line1, setLine1, line2, setLine2, line3, setLine3, line4, setLine4, line5, setLine5,
            category, setCategory, updateCart, setUpdateCart, listSearchedProducts, setListSearchedProducts, setViewdProducst, viewdProducst, emailVerified, setEmailVeried, open, setOpen, detailSales, setDetailSales,
            seledtedProducts, setSelectedProducts, indexParameter, setIndexParameter, ship, setShip, modalGoToCart, setModalGoToCart, showButtonFinish, setShowButtonFinish, getStoreP, setGetStoreP, prtInfo, setPrtInfo
        }}>
            {props.children}
        </InformationsContext.Provider>
    )
}

export const useInformation = () => React.useContext(InformationsContext);