import React from "react";
import JoditEditor from "jodit-react";
import './styles.css';

const Editor = ({ func, valueDef }) => {
    const editor = React.useRef(null);
    const [content, setContent] = React.useState(valueDef);

    /* const config = {
        height: 400,
        readonly: false,
        placeholder: 'Especificação',
        id: 'especification'
    } */
    const config = React.useRef(
        {
            readonly: false,
            placeholder: 'Start typings...',
            id: 'eps',
            name: 'esp'
        },

    );

    const handleUpdate = (event) => {
        const editorContent = event;
        setContent(editorContent);
        func(editorContent);
    }
    return (
        <div className="App">
            <JoditEditor
                id="espe"
                ref={editor}
                value={content}
                config={config}
                onBlur={e => handleUpdate(e)}
                onChange={(newContent) => { }}
            />
        </div>
    )
}

export default Editor