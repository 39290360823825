import React from 'react';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import LogoutIcon from '@mui/icons-material/Logout';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SettingsIcon from '@mui/icons-material/Settings';
import StoreIcon from '@mui/icons-material/Store';
import ArticleIcon from '@mui/icons-material/Article';
import CampaignIcon from '@mui/icons-material/Campaign';
import InventoryIcon from '@mui/icons-material/Inventory';
/* import { useNavigate } from 'react-router-dom'; */
import * as LogoutFunction from '../../../Services/logout';
import { useInformation } from '../../../Providers/informations';
import AssessmentIcon from '@mui/icons-material/Assessment';

import {
    Container,
} from './styles';
import { Link } from 'react-router-dom';


const AdmSideBarMenu = () => {

    /* const navigate = useNavigate() */
    const { info } = useInformation()
    const [openSecond, setOpenSecond] = React.useState(true);

    const handleClickSecond = () => {
        setOpenSecond(!openSecond);
    };
    const logout = () => {
        LogoutFunction.Logout();
    }

    return (
        <Container>
            <List
                sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', borderRadius: '6px' }}
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                    <ListSubheader component="div" id="nested-list-subheader" sx={{ borderRadius: '6px' }}>
                        Main Menu
                    </ListSubheader>
                }
            >
                {/* Cliente */}
                <ListItemButton onClick={handleClickSecond}>
                    <ListItemIcon>
                        <ManageAccountsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Adm" />
                    {openSecond ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>

                <Collapse in={openSecond} timeout="auto" unmountOnExit sx={{ color: "#A9ABAE" }}>

                    {info.CientType !== "customer" &&
                        <List component="div" disablePadding>
                            <Link to="/agroProdutos_management/supervisor/dashboard" style={{ textDecoration: 'none', color: "#A9ABAE" }}>
                                <ListItemButton sx={{ pl: 4 }}>
                                    <ListItemIcon>
                                        <SettingsIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Configurações da plataforma" />
                                </ListItemButton>
                            </Link>
                        </List>}

                    <List component="div" disablePadding>
                        <Link to="/agroProdutos_management/supervisor/store" style={{ textDecoration: 'none', color: "#A9ABAE" }}>
                            <ListItemButton sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <StoreIcon />
                                </ListItemIcon>
                                <ListItemText primary="Lojas" />
                            </ListItemButton>
                        </Link>
                    </List>

                    <List component="div" disablePadding>
                        <ListItemButton sx={{ pl: 4 }}>
                            <ListItemIcon>
                                <ArticleIcon />
                            </ListItemIcon>
                            <ListItemText primary="Termos" />
                        </ListItemButton>
                    </List>

                    <List component="div" disablePadding>
                        <Link to="/agroProdutos_management/supervisor/sales" style={{ textDecoration: 'none', color: "#A9ABAE" }}>
                            <ListItemButton sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <AssessmentIcon />
                                </ListItemIcon>
                                <ListItemText primary="Vendas" />
                            </ListItemButton>
                        </Link>
                    </List>

                    <List component="div" disablePadding>
                        <Link to="/agroProdutos_management/supervisor/banners" style={{ textDecoration: 'none', color: "#A9ABAE" }}>
                            <ListItemButton sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <CampaignIcon />
                                </ListItemIcon>
                                <ListItemText primary="Banners" />
                            </ListItemButton>
                        </Link>
                    </List>

                    <List component="div" disablePadding>
                        <Link to="/agroProdutos_management/supervisor/lines" style={{ textDecoration: 'none', color: "#A9ABAE" }}>
                            <ListItemButton sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <InventoryIcon />
                                </ListItemIcon>
                                <ListItemText primary="Categorias de produtos" />
                            </ListItemButton>
                        </Link>
                    </List>

                </Collapse>
                {/* Cliente */}

                <ListItemButton onClick={logout}>
                    <ListItemIcon>
                        <LogoutIcon />
                    </ListItemIcon>
                    <ListItemText primary="Logout" />
                </ListItemButton>
            </List>
        </Container>
    )
}

export default AdmSideBarMenu;