import styled from 'styled-components';

export const Container = styled.div`

`;
export const InternContent = styled.div`
    padding-bottom:1rem;
`;

export const DivRow = styled.div`
    padding: 10px 10px 10px 0px;
    color: #717171;
`;