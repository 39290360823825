import React from 'react';
import Grid from '@mui/material/Grid';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import PendingIcon from '@mui/icons-material/Pending';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Tooltip from '@mui/material/Tooltip';

import {
    Container,
    Section,
    Line,
} from './styles';
import { TitleH3, TitleH6 } from '../../../../Styles/style';

const Statistcs = ({ sales }) => {

    return (
        <Container>
            <Line style={{ paddingBottom: '15px', borderBottom: '2px solid #C4D1E0', marginBottom: '15px' }}>
                <TitleH6>
                    Vendas Totais
                </TitleH6>
            </Line>
            <Line style={{ display: 'flex', justifyContent: 'center' }}>
                <Grid container spacing={3} sx={{ justifyContent: 'center' }}>
                    <Grid item xs="auto">
                        <Section>
                            <div>
                                <CurrencyExchangeIcon />
                                &nbsp;
                                A Processar
                            </div>
                            <div>
                                <Tooltip title={sales?.data?.["A processar"]?.vendas}>
                                    <TitleH3>
                                        {sales?.data?.["A processar"]?.vendas}
                                    </TitleH3>
                                </Tooltip>
                            </div>
                        </Section>
                    </Grid>
                    <Grid item xs="auto">
                        <Section>
                            <div>
                                <PendingIcon />
                                &nbsp;
                                Aguardando Pagamento
                            </div>
                            <div>
                                <Tooltip title={sales?.data?.["Aguardando Pagamento"]?.vendas}>
                                    <TitleH3>
                                        {sales?.data?.["Aguardando Pagamento"]?.vendas}
                                    </TitleH3>
                                </Tooltip>
                            </div>
                        </Section>
                    </Grid>
                    <Grid item xs="auto">
                        <Section>
                            <div>
                                <LocalShippingIcon />
                                &nbsp;
                                Em Processamento
                            </div>
                            <div>
                                <Tooltip title={sales?.data?.["Em Processamento"]?.vendas}>

                                    <TitleH3>
                                        {sales?.data?.["Em Processamento"]?.vendas}
                                    </TitleH3>
                                </Tooltip>
                            </div>
                        </Section>
                    </Grid>
                    <Grid item xs="auto">
                        <Section>
                            <div>
                                <CheckCircleIcon />
                                &nbsp;
                                Enviadas
                            </div>
                            <div>
                                <Tooltip title={sales?.data?.["Enviado"]?.vendas}>

                                    <TitleH3>
                                        {sales?.data?.["Enviado"]?.vendas}
                                    </TitleH3>
                                </Tooltip>
                            </div>
                        </Section>
                    </Grid>
                    <Grid item xs="auto">
                        <Section>
                            <div>
                                <CheckCircleIcon />
                                &nbsp;
                                Finalizadas
                            </div>
                            <div>
                                <Tooltip title={sales?.data?.["Finalizado"]?.vendas}>

                                    <TitleH3>
                                        {sales?.data?.["Finalizado"]?.vendas}
                                    </TitleH3>
                                </Tooltip>
                            </div>
                        </Section>
                    </Grid>
                </Grid>
            </Line>
        </Container>
    )
}

export default Statistcs;