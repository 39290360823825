import React from 'react';
import { useLocation, useParams, useNavigate, Link } from 'react-router-dom';
import * as GetProducts from '../../../../../Services/getProducts';
import { ButtonGreenSmall } from '../../../../../Styles/stylesButtons';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Rating from '@mui/material/Rating';
import * as ViewedPrduct from '../../../../../Services/addViewProduct';
import { useInformation } from '../../../../../Providers/informations';
import SnackBar from '../../SnackBar';
import { ThemeProvider } from '@mui/material/styles';
import { getAddressWithZipCode, /* getDistance */ shippingAvailable } from '../../../../../Services/address';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { Modal } from 'react-bootstrap';
import Spinner from '../../SmallComponents/spinner';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import DOMPurify from 'dompurify';
import DescriptionIcon from '@mui/icons-material/Description';
import StarRateIcon from '@mui/icons-material/StarRate';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import Grid from '@mui/material/Grid';
import RatingCard from '../../Rating';
import Ask from '../../Ask';
import InfoIcon from '@mui/icons-material/Info';
import { Tooltip } from '@mui/material';


import {
    Container,
    Column,
    Line,
    Div,
    Line2,
    ImageColection,
    ContentCard,
    ListContentOrder,
    TopTitleDiv,
    theme2,
    LoadingDiv,
} from './styles';
import { Avatar } from '@mui/material';
import { postBuyNow } from '../../../../../Services/orders';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}



const ProductDetailsMobile = () => {

    const location = useLocation();
    const navigate = useNavigate();
    let { store, slug } = useParams();
    const theme = useTheme();
    const { setUpdateCart } = useInformation();


    const [value, setValue] = React.useState(0);

    const [cartSuccess, setCartSuccess] = React.useState(false);
    const [cartError, setCartError] = React.useState(false);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    const [imageSelect, setImageSelected] = React.useState(0);
    const [specification, setSpecification] = React.useState();


    const [description, setDescription] = React.useState([]);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [loading, setLoading] = React.useState(false);
    const [show, setShow] = React.useState(false);
    const [shipping, setShipping] = React.useState();
    const [shippingStatus, setShippingStatus] = React.useState(false);

    const [stars, setStars] = React.useState(0);
    const [minimalValue, setMinimalValue] = React.useState();


    const getDetail = async () => {
        let detail = await GetProducts.getdetailProduct(slug, store);
        setDescription(detail.data.product);
        setMinimalValue(detail?.data?.product?.store_minimum_order_value)

        /*  setQtd(detail.data.product.minimum_quantity); */
        setSpecification(DOMPurify.sanitize(detail?.data?.product?.specifications));
        setStars(parseFloat(detail?.data?.product?.stars));

    }

    const addToCart = async (slug, storeSlug) => {
        if (localStorage.getItem("ClientAuth") === "true") {
            const add = await ViewedPrduct.addToCart(slug, 1, storeSlug);
            if (add.status_code !== 200 || !add) {
                setCartError(true);
                setTimeout(() => {
                    setCartError(false);
                }, 4000);
            } else {
                setCartSuccess(true);
                setUpdateCart(true);
                setTimeout(() => {
                    setCartSuccess(false);
                }, 4000);
            }
        } else {
            localStorage.setItem("product", slug);
            localStorage.setItem("gotToCart", "yes");
            navigate(`/login/param?slug=${slug}&storelug=${storeSlug}&addto=car`);
        }
    }
    const [askList, setAskList] = React.useState([]);


    const getAsks = async () => {
        try {
            const response = await GetProducts.getQuestionsList(store, slug);
            setAskList(response);
        } catch (error) {

        }
    }
    const getZipCode = async (zip) => {
        if (zip.length >= 8) {
            setLoading(true);
            handleShow();
            const addressResponse = await getAddressWithZipCode(zip);
            setShipping(addressResponse);
            if (addressResponse?.erro === true) {
                setLoading(false);
            } else {
                const verifyShipping = await shippingAvailable(store, addressResponse.ibge);
                if (verifyShipping?.data?.shipment_available === true) {
                    setShippingStatus(true);
                } else {
                    setShippingStatus(false);
                }
                setLoading(false);
            }

        }
    }
    const [favoriteSuccess, setFavoriteSuccess] = React.useState(false);
    const [favoriteError, setFavoriteError] = React.useState(false);

    const addToFavorite = async (slug, storeSlug) => {
        if (localStorage.getItem("ClientAuth") === "true") {
            try {
                const addFavorite = await ViewedPrduct.addToFavorite(slug, storeSlug);
                if (addFavorite.message === "success") {
                    setFavoriteSuccess(true);
                    setTimeout(() => {
                        setFavoriteSuccess(false)
                    }, 4000);
                }
                else {
                    setFavoriteError(true);
                    setTimeout(() => {
                        setFavoriteError(false)
                    }, 4000);
                }
            } catch (error) {
                setFavoriteError(true);
                setTimeout(() => {
                    setFavoriteError(false)
                }, 4000);
            }

        } else {
            navigate(`/login/param?slug=${slug}&storelug=${storeSlug}&addto=myList`);
        }
    }

    const [reviewistm, setReviewList] = React.useState([]);
    const getReviews = async () => {
        try {
            const reviewResponse = await GetProducts.getReviews(store, slug)
            setReviewList(reviewResponse.data)

        } catch (error) {
            console.log("error", error.response)

        }
    };

    const buyNow = async () => {
        if (localStorage.getItem("ClientAuth") === "true") {
            try {
                const response = await postBuyNow(store, slug, 1)
                if (response.message === "success") {
                    navigate('/produtos/compra/finalizar/verify')
                }
            } catch (error) {
                navigate('/produtos/compra/finalizar/verify')
            }
        } else {
            navigate(`/login/param?slug=${slug}&storelug=${store}&addto=buyNow`);
        }

    }

    /*     React.useEffect(() => {
            const distance = getDistance(1, 2);
        }, []) */

    React.useEffect(() => {
        ViewedPrduct.addViewedProduct(slug, store);
        getDetail();
        /*   getLine1(categoria); */
    }, [location])

    const options = { style: 'currency', currency: 'BRL' };

    return (
        <Container>
            <Line>
                <div style={{ width: '100%', height: '20%', paddingBottom: '5px' }}>
                    <Div style={{ width: '100%', height: '100%', display: 'flex' }}>
                        <Column style={{ width: '30%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Avatar
                                alt="logo Loja"
                                src={`${process.env.REACT_APP_LINK}/storage/stores/${description?.store_logo}`}
                                sx={{ width: 56, height: 56 }}
                            />
                        </Column>
                        <Column style={{ flex: '1', display: 'flex', flexDirection: 'column' }}>
                            <small style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>Vendido por: &nbsp; {description.store_name}</small>
                            <Rating name="read-only" value={stars} readOnly sx={{ color: '#009c43' }} />
                            <small>{description?.total_reviews} Avaliações</small>
                        </Column>
                        <Column style={{ width: '5%', background: description?.stock >= 1 ? '#009c43' : '#c32329', borderRadius: '0px 6px 6px 0px', textAlign: 'center', }}>
                            <p style={{ transform: 'rotate(-90deg)', marginTop: '50px', fontSize: '0.8rem', color: '#fff' }}>{description?.stock >= 1 ? "Disponivel" : "Indisponivel"}</p>
                        </Column>
                    </Div>
                </div>
                <Column style={{ width: '100%', height: '25rem' }} >
                    <Div style={{ width: '100%', height: '100%', display: 'flex' }}>
                        <ImageColection>
                            <div style={{ border: imageSelect === 0 && '2px solid #009c43' }}>
                                <img src={`${process.env.REACT_APP_LINK}/${description?.images?.[0]}`} alt="produto" onClick={() => setImageSelected(0)} />
                            </div>
                            {description.images?.[1] && <div style={{ border: imageSelect === 1 && '2px solid #009c43' }}>
                                <img src={`${process.env.REACT_APP_LINK}/${description?.images?.[1]}`} alt="produto" onClick={() => setImageSelected(1)} />
                            </div>}
                            {description.images?.[2] && <div style={{ border: imageSelect === 2 && '2px solid #009c43' }}>
                                <img src={`${process.env.REACT_APP_LINK}/${description?.images?.[2]}`} alt="produto" onClick={() => setImageSelected(2)} />
                            </div>}
                            {description.images?.[3] && <div style={{ border: imageSelect === 3 && '2px solid #009c43' }}>
                                <img src={`${process.env.REACT_APP_LINK}/${description?.images?.[3]}`} alt="produto" onClick={() => setImageSelected(3)} />
                            </div>}
                            {description.images?.[4] && <div style={{ border: imageSelect === 4 && '2px solid #009c43' }}>
                                <img src={`${process.env.REACT_APP_LINK}/${description?.images?.[4]}`} alt="produto" onClick={() => setImageSelected(4)} />
                            </div>}
                            {description.images?.[5] && <div style={{ border: imageSelect === 5 && '2px solid #009c43' }}>
                                <img src={`${process.env.REACT_APP_LINK}/${description?.images?.[5]}`} alt="produto" onClick={() => setImageSelected(5)} />
                            </div>}
                        </ImageColection>
                        <div style={{ width: '80%', height: '70%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10%', borderRadius: '6px', overflow: 'hidden' }}>
                            <img src={`${process.env.REACT_APP_LINK}/${description?.images?.[imageSelect]}`} alt=" description" style={{ maxWidth: '70%', maxHeight: '20rem' }} />
                        </div>
                    </Div>
                </Column>
                <Column style={{ width: '100%', height: '100%' }} >
                    <div style={{ width: '100%', height: '80%', paddingTop: '5px' }}>
                        <Div style={{ width: '100%', height: '100%', }}>
                            <Line style={{ width: '100%', height: '20%', padding: '0px 15px' }}>
                                <p style={{ fontSize: '1.3rem', color: '#707070', fontFamily: 'Mulish', fontWeight: '500' }}>{description.title}</p>
                            </Line>
                            <Line style={{ display: 'flex', width: '100%', height: '40%', paddingTop: '10px' }}>
                                <Column style={{ width: '60%' }}>
                                    <h3>{description.price?.toLocaleString('pt-BR', options)}</h3>
                                    <small>Desconto de </small><small style={{ color: '#009c43', fontWeight: 'bold' }}>1.3% no pix</small>
                                    <br></br>
                                    <Tooltip title={`Válido para compras a partir de ${(minimalValue / 100)?.toLocaleString('pt-BR', options)} nessa loja`}>
                                        <small style={{ color: '#009c43', fontSize: '0.9rem', cursor: 'pointer' }}>Frete Grátis</small>
                                    </Tooltip> <small style={{ fontSize: '0.9rem' }}>, Confira Disponibilidade</small>
                                </Column>
                                {/* <Column style={{ width: '40%' }}>
                                    <Line2>
                                        <label style={{ paddingRight: '15px' }}>Qunatidade</label>
                                        <input type="number" style={{ width: '80px' }} onChange={(e) => setQuant(e.target.value)} value={quant} />
                                    </Line2>
                                </Column> */}
                            </Line>
                            <Line style={{ width: '100%', height: '40%', display: 'flex', flexDirection: 'column' }}>
                                <Line2>
                                    <label style={{ paddingRight: '15px' }}>Verificar Disponibilidade de entrega</label>
                                    <input type="text" placeholder="99500-000" onChange={(e) => getZipCode(e.target.value)} />
                                </Line2>

                                <Line style={{ display: 'flex', justifyContent: 'space-around' }}>
                                    <div style={{ padding: '5px 5px 15px 5px' }}>
                                        {description?.stock >= 1 ?
                                            <ButtonGreenSmall onClick={() => addToCart(slug, store)}><ShoppingCartIcon /></ButtonGreenSmall>
                                            :
                                            <ButtonGreenSmall disabled><ShoppingCartIcon /></ButtonGreenSmall>
                                        }

                                    </div>
                                    <div style={{ padding: '5px 5px 15px 5px' }}><ButtonGreenSmall onClick={() => addToFavorite(slug, store)}><FavoriteIcon /></ButtonGreenSmall></div>
                                    <div style={{ padding: '5px 5px 15px 5px' }}>
                                        {description?.stock >= 1 ?
                                            <ButtonGreenSmall style={{ width: '100%' }} onClick={() => buyNow(slug, store)}>Comprar</ButtonGreenSmall>
                                            :
                                            <ButtonGreenSmall style={{ width: '100%' }} disabled >Comprar</ButtonGreenSmall>
                                        }
                                    </div>
                                </Line>
                            </Line>
                        </Div>
                    </div>
                </Column>
            </Line>
            <Line>
                <Column style={{ width: '100%' }}>
                    <Div style={{ width: '100%', height: '100%' }}>
                        <ContentCard>
                            <TopTitleDiv >
                                <Div style={{ width: '100%', height: '20%', paddingBottom: '15px' }}>
                                    <Column style={{ width: '100%' }}>
                                        <p style={{ color: '#707070', fontSize: '1.1rem', fontWeight: '600', fontFamily: 'Mulish' }}>{description?.title}</p>
                                        <small style={{ color: '#707070', fontSize: '0.9rem', fontFamily: 'Mulish' }}>{description?.description}</small>
                                    </Column>
                                </Div>
                            </TopTitleDiv>
                        </ContentCard>
                    </Div>
                </Column>
                <Column style={{ width: '100%' }}>
                    <Div>
                        <AppBar position="static" sx={{ background: '#FFFFFF', height: '20%', color: 'black', border: 'none', boxShadow: '0', }}>
                            <ThemeProvider theme={theme2}>
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    indicatorColor="primary"
                                    textColor="inherit"
                                    variant="fullWidth"
                                    aria-label="detail"
                                >
                                    <Tab label={<DescriptionIcon />} {...a11yProps(0)} style={{ color: value === 0 && '#009c43', fontSize: '0.9rem', fontWeight: '700', }} />
                                    <Tab label={<StarRateIcon />} {...a11yProps(1)} style={{ color: value === 1 && '#009c43', fontSize: '0.9rem', fontWeight: '700', }} onClick={() => getReviews()} />
                                    <Tab label={<ContactSupportIcon />} {...a11yProps(2)} style={{ color: value === 2 && '#009c43', fontSize: '0.9rem', fontWeight: '700', }} onClick={() => getAsks()} />
                                </Tabs>
                            </ThemeProvider>
                        </AppBar>
                        <Divider />
                        <ListContentOrder style={{ marginTop: '15px' }}>
                            <SwipeableViews
                                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                index={value}
                                onChangeIndex={handleChangeIndex}
                            >
                                <TabPanel value={value} index={0} dir={theme.direction} >
                                    <div style={{ overflow: 'auto' }}>
                                        <div dangerouslySetInnerHTML={{ __html: specification }}></div>
                                    </div>
                                </TabPanel>
                                <TabPanel value={value} index={1} dir={theme.direction}>
                                    Avaliações
                                    <div style={{ height: 'auto', overflow: 'auto', }}>

                                        <Grid container spacing={2}>
                                            {reviewistm?.map((review) => (
                                                <Grid item xs={12} Key={review?.id}>
                                                    <RatingCard stars={review?.stars} userName={review.user} date={review?.date} text={review.description} />
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </div>
                                </TabPanel>
                                <TabPanel value={value} index={2} dir={theme.direction} >
                                    Perguntas
                                    {localStorage.getItem("ClientAuth") === "true" ? <Ask store={store} product={slug} getQuestionsF={getAsks} dataList={askList} val={value} /> :
                                        <div style={{ textAlign: 'right' }}>
                                            <Link to="/login">Login para perguntar</Link>
                                        </div>
                                    }
                                </TabPanel>
                            </SwipeableViews>
                        </ListContentOrder>
                    </Div>
                </Column>
            </Line>
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Disponibilidade de entrega</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading ? <LoadingDiv>
                        <Spinner />

                    </LoadingDiv> : <LoadingDiv>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">CEP</th>
                                    <th scope="col">Cidade</th>
                                    <th scope="col">Estado</th>
                                    <th scope="col">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{shipping?.cep} </td>
                                    <td>{shipping?.localidade}</td>
                                    <td>{shipping?.uf}</td>
                                    <th scope="row">{shippingStatus ? "Frete Grátis" : "Ainda não chegamos na sua região"}</th>
                                </tr>
                            </tbody>
                        </table>
                        {shippingStatus ? <LocalShippingIcon fontSize='large' color="success" /> : <TravelExploreIcon fontSize='large' color="success" />}
                    </LoadingDiv>}
                    {shippingStatus && <div style={{ color: '#009c43' }}><InfoIcon /> <small> Válido para compras a partir de {(minimalValue / 100)?.toLocaleString('pt-BR', options)} nessa loja!</small></div>}
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
            </Modal>
            {cartSuccess && <SnackBar type="success" time={4000} message={"Adicionado ao carrinho com sucesso"} states={true} />}
            {cartError && <SnackBar type="error" time={4000} message={"Erro ao adicionar ao carrinho"} states={true} />}
            {favoriteSuccess && <SnackBar type="success" time={4000} message={"Adicionado a sua lista de favoritos"} states={true} />}
            {favoriteError && <SnackBar type="error" time={4000} message={"Erro ao adicionar a lista de favoritos"} states={true} />}
        </Container >
    )
}

export default ProductDetailsMobile;