import axios from "axios";
import qs from 'qs';

export async function createNewProduct(titleN, descriptionN, slugN, specificationsN, categoryP, price, status, image1, image2, image3, image4, image5, image6, stock, sales_price) {
    var data = new FormData();
    data.append("title", titleN);
    data.append("description", descriptionN);
    data.append("slug", slugN);
    data.append("price", price);
    data.append("specifications", specificationsN);
    data.append("status", status.value);
    data.append("stock", parseInt(stock));
    data.append("sales_price", sales_price);
    data.append("category_id", categoryP);
    data.append("free_installments", 1);
    data.append("images1", image1);
    data.append("images2", image2);
    data.append("images3", image3);
    data.append("images4", image4);
    data.append("images5", image5);
    data.append("images6", image6);

    const options = {
        method: 'POST',
        url: `${process.env.REACT_APP_LINK}/api/store/products`,
        headers: {
            'Content-Type': 'multipart/form-data',
            'Accept': 'application/json',
            'type': "formData",
            'Enctype': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem('clientToken')}`
        },
        data: data
    };
    const { data: res } = await axios.request(options)
    return res
}

export async function editProduct(titleN, descriptionN, slugN, specificationsN, categoryP, price, status, image1, image2, image3, image4, image5, image6, productSlug, stock, sales_price) {

    const data = qs.stringify({
        title: titleN,
        description: descriptionN,
        price,
        specifications: specificationsN,
        status: status.value,
        stock: parseInt(stock),
        sales_price,
        category_id: categoryP,
        images1: image1,
        images2: image2,
        images3: image3,
        images4: image4,
        images5: image5,
        images6: image6,
    });

    const response = await axios.put(
        `${process.env.REACT_APP_LINK}/api/store/products/${productSlug}`,
        data, // Use os dados serializados
        {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                Authorization: `Bearer ${localStorage.getItem('clientToken')}`,
            },
        }
    );

    return response.data;
}

export async function showProduct(productsSlug) {
    try {
        const { data: res } = await axios({ method: 'get', url: `${process.env.REACT_APP_LINK}/api/store/products/${productsSlug}`, headers: { 'Authorization': `Bearer ${localStorage.getItem("clientToken")}` } })
        return res
    } catch (error) {
        return error
    }
}

export async function getCategoriesFunc() {
    try {
        const { data: res } = await axios({ method: 'get', url: `${process.env.REACT_APP_LINK}/api/public/product-categories` })
        return res
    } catch (error) {
        return error
    }
}

export async function getCategoriesStore() {
    const { data: res } = await axios({ method: 'get', url: `${process.env.REACT_APP_LINK}/api/store/product-categories`, headers: { 'Authorization': `Bearer ${localStorage.getItem("clientToken")}` } })
    return res
}

export async function deletePeoduct(slug) {
    const { data: res } = await axios({ method: 'delete', url: `${process.env.REACT_APP_LINK}/api/store/products/${slug}`, headers: { 'Authorization': `Bearer ${localStorage.getItem("clientToken")}` } })
    return res
}

export async function getListQuestionStore() {
    const { data: res } = await axios({ method: 'get', url: `${process.env.REACT_APP_LINK}/api/store/product-questions`, headers: { 'Authorization': `Bearer ${localStorage.getItem("clientToken")}` } })
    return res
}

export async function postAnswer(answer, id) {
    var data = {
        "answer": answer,
    }
    const { data: res } = await axios({ method: 'post', url: `${process.env.REACT_APP_LINK}/api/store/product-questions/answer/${id}`, headers: { 'Authorization': `Bearer ${localStorage.getItem("clientToken")}` }, data })
    return res
}

export async function getCalcValue(price, tex) {
    var data = {
        "price": price,
        "commission": tex
    }
    const { data: res } = await axios({ method: 'post', url: `${process.env.REACT_APP_LINK}/api/store/products/sales_prices`, headers: { 'Authorization': `Bearer ${localStorage.getItem('clientToken')}` }, data })
    return res
}