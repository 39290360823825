import React from 'react';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import SnackBar from '../../../Components/General/SnackBar';
import { useNavigate, useParams } from 'react-router-dom';
import loginImage from '../../../../Assets/Login/login_register.png';
import { CustomizeInput } from '../../../../Styles/style';
import { changePassword } from '../../../../Services/auth';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
    Container,
    Left,
    Right,
    Middle,
    useStyles,
    DivTitle,
    DivButton
} from './styles';


const ResetPassword = () => {

    const classes = useStyles()
    const navigate = useNavigate();
    let { token } = useParams();
    const [email, setEmail] = React.useState();
    const [password, setPassword] = React.useState();
    const [confirmPassword, setConfrimPassword] = React.useState();
    const [incorrectPassword, setIncorretPassword] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');

    const [seePassword, setSeePassword] = React.useState(false);
    const [passType, setPassType] = React.useState('password');

    const seePass = (see, type) => {
        setSeePassword(see)
        setPassType(type);
    }

    const checkPassword = (checkPassword) => {
        setConfrimPassword(checkPassword);
        if (password !== checkPassword) {
            setIncorretPassword(true)
        } else {
            setIncorretPassword(false);
        }
    }

    const setNewPassword = async (token, email, password, confirmPassword) => {
        if (confirmPassword === password) {
            try {
                const changePasswordResponse = await changePassword(token, email, password, confirmPassword);
                setSuccess(true);
                navigate("/login")
                setTimeout(() => {
                    setSuccess(false);
                }, 3000);
            } catch (error) {
                console.log(error.response.data.message)

                switch (error?.response?.data?.message) {
                    case "The email must be a valid email address.":
                        setErrorMessage("Verique se seu e-mail está correto");
                        setError(true)
                        setTimeout(() => {
                            setError(false)
                        }, 3000);
                        break;
                    case "We can't find a user with that email address.":
                        setErrorMessage("Verique se seu e-mail está correto");
                        setError(true)
                        setTimeout(() => {
                            setError(false)
                        }, 3000);
                        break;
                    case "This password reset token is invalid.":
                        setErrorMessage("esse link de redefinição expirou");
                        setError(true)
                        setTimeout(() => {
                            setError(false)
                        }, 3000);
                        break;
                    case "The password must be at least 8 characters.":
                        setErrorMessage("a senha deve conter ao menos 8 caracteres");
                        setError(true)
                        setTimeout(() => {
                            setError(false)
                        }, 3000);
                        break;

                    default:
                        setErrorMessage("Tente mais tarde");
                        setError(true)
                        setTimeout(() => {
                            setError(false)
                        }, 3000);
                        break;
                }
            }
        }
    }

    React.useEffect(() => {
        localStorage.setItem('resetPass', token);
    }, [])

    return (
        <Container>
            <Left style={{ backgroundImage: `url(${loginImage})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover' }}>

            </Left>
            <Right>
                <DivTitle onClick={() => navigate('/')}>
                    <h1>Agro Produtos</h1>
                </DivTitle>
                <Middle>
                    <div style={{ textAlign: 'Left' }}>
                        <h3 style={{ fontWeight: '500', color: '#717171' }}>Redefinir Senha</h3>
                    </div>
                    <CustomizeInput>
                        <label htmlFor="email">Email</label>
                        <input type="email" id="email" onChange={(e) => setEmail(e.target.value)} />
                    </CustomizeInput>
                    <CustomizeInput>
                        <label htmlFor="password">Senha</label>
                        <div style={{ display: 'flex', height: '100%' }}>
                            <input type={passType} id="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                            <div style={{ justifyContent: 'flex-end', display: 'flex', color: 'green', height: '15px' }}>
                                {seePassword ? <VisibilityOffIcon style={{ cursor: 'pointer' }} onClick={() => seePass(false, 'password')} /> : <VisibilityIcon style={{ cursor: 'pointer' }} onClick={() => seePass(true, 'text')} />}
                            </div>
                        </div>
                    </CustomizeInput>
                    <CustomizeInput>
                        <label htmlFor="password2">Confirmar Senha</label>
                        <input type={passType} id="password2" value={confirmPassword} onChange={(e) => checkPassword(e.target.value)} onClick={(e) => checkPassword(e.target.value)} />
                        {incorrectPassword !== false && <small className={classes.small}>As senhas não  coincidem</small>}
                    </CustomizeInput>
                    <DivButton >
                        <Button variant="contained" color="success" onClick={() => setNewPassword(token, email, password, confirmPassword)} style={{ width: '100%', height: '50px' }}>
                            Redefinir Senha
                        </Button>
                    </DivButton>

                    <p style={{ paddingLeft: '5px', color: '#717171' }} >Voltar para o <Link to="/login" style={{ textDecoration: 'none', color: '#009c43' }}>Login</Link></p>


                    <div id="sanckbar">
                        {success && <SnackBar type="success" time={3000} message="Senha autualizada" states={true} />}
                        {error && <SnackBar type="error" time={3000} message={`Não foi possível atualizar sua senha, ${errorMessage}`} states={true} />}
                    </div>
                </Middle>
            </Right>
        </Container>
    )
}

export default ResetPassword;