import React from 'react';

export const AdmInformationsContext = React.createContext({});

export const AdmInformationProvider = (props) => {

    const [info, setInfo] = React.useState({
        AdmToken: null,
        AdmAuth : false,
        AdmName: null,
        AdmEmail: null,
        AdmType: null,
        
    })

    return(
        <AdmInformationsContext.Provider value={{info, setInfo}}>
            {props.children}
        </AdmInformationsContext.Provider>
    )
}

export const useAdmInformation = () => React.useContext(AdmInformationsContext);