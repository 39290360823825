import styled from "styled-components";

export const Container = styled.div`
    padding: 2%;
  
`;
export const Line = styled.div`
    width: 100%;
    display: flex;
    padding: 0px 15px;
    padding-bottom: 15px ;
    @media(max-width:  800px){
        flex-direction: column;
    }
`;
export const LineSmall = styled.div`
    width: 100%;
    display: flex;
    padding-top: 15px;
    padding-left: 15px;
    width: 100%;
    height: 21vh;
    color: #009c43;

    @media(max-width: 800px){
        /* flex-direction: column; */
    }
`;

export const Salles = styled.div`
    height: calc(100vh - 21vh);
    background-color: #fff ;
    box-shadow: 0px 0px 9px #26242424;
    border-radius: 6px;
    margin-left: 15px;
    border: 1px solid #c6c6c6 ;
    overflow: auto ;
    @media(max-width: 800px){
        width: 100%;
        margin-left: 0;
    }
`;
export const InfoTable = styled.div`
    flex:1;
    height: 100vh;
    background-color: #fff ;
    box-shadow: 0px 0px 9px #26242424;
    border-radius: 6px;
    border: 1px solid #c6c6c6 ;
    overflow: auto;
    @media(max-height: 768px){
        height: 100vh;
    }
    @media(max-width: 800px){
        width: 100%;
    }
`;

export const SmallCard = styled.div`
    background-color: #fff ;
    box-shadow: 0px 0px 9px #26242424;
    border: 1px solid #c6c6c6 ;
    border-radius: 6px;
    height: 100%;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 15px 0px;
    cursor: pointer;
    @media(max-width: 800px){
        >p{
            font-size: 0.8rem;
        }
    }
`;

export const DivCenter = styled.div`
    width: 45%;
    height: 100vh;
    @media(max-width: 800px){
        width: 100%;
    }
`;