import React from 'react';
import { Container, ContentSection } from './styles';
import MainHeader from '../MainHeader';
import Footer from '../Footer';
import FooterApp from '../FooterApp';
import { useLocation } from 'react-router-dom';
import CarouselProduct from '../CaroselProduct';
import isMobile from '../../../../Services/isMobile';
import { getBannersList } from '../../../../Services/publics';
import localforage from 'localforage';
import * as GetProducts from '../../../../Services/getProducts'
import { Logout } from '../../../../Services/logout';

const Content = ({ children }) => {
    const location = useLocation();

    React.useEffect(() => {
        document.getElementById("ContentContainer").scrollTo(0, 0)
    }, [location]);

    const [bannersImg, setImages] = React.useState([])

    /* Pega os produtos da home e guarda em cache */
    /* Pega os produtos vizualizados e guarda em cache */
    const getViewdProducts = async () => {
        try {
            const list = await GetProducts.getViewedProducts();
            if (list?.response?.data.message === "Unauthenticated.") {
                Logout();
            } else {
                localforage.setItem('viewdProducts', list?.products);
            }
        } catch (error) {

        }
    }
    /* Pega os banners da home e guarda em cache */
    const getBanners = async () => {
        try {
            const response = await getBannersList("home");
            setImages(response.data.banners)
            localforage.setItem('homeBanners', response.data.banners);
        } catch (error) {

        }
    }

    /* Pega os produtos da home do cache para não ter que recarregar */
    const getImagesLocal = async () => {
        const banner = await localforage.getItem('homeBanners')
        setImages(banner);
    }

    /* Chama as funções para buscar os produtos e os banners da home no back */
    React.useEffect(() => {
        if (window.location.href.split('/')[3] !== " ") {
            getBanners();
        }
    }, [location]);

    /* Chama a função para buscar os produtos vizualizados no back */
    React.useEffect(() => {
        if (window.location.href.split('/')[3] !== " ") {
            getViewdProducts();
        }
    }, [localStorage.getItem('ClientAuth')]);

    /* Chama as funções para buscar banners em cache */
    React.useEffect(() => {
        if (window.location.href.split('/')[3] !== " ") {
            getImagesLocal();
        }
    }, [location]);

    return (
        <Container id='ContentContainer'>
            {window.location.href.split('/')[3] !== "login" && window.location.href.split('/')[3] !== "register" && window.location.href.split('/')[3] !== "reset-password" && window.location.href.split('/')[3] !== "esqueci_senha" ?
                <>
                    <MainHeader />
                    {window.location.href.split("/")[3] === "" && <CarouselProduct images={bannersImg} />}
                    <ContentSection style={{ paddingLeft: window.innerWidth >= '1920' && '10%', paddingRight: window.innerWidth >= '1920' && '10%' }}>
                        {children}
                    </ContentSection>
                    {!isMobile() ? !window.location.href.split("/")[3] || window.location.href.split("/")[3] === "lojas" || window.location.href.split("/")[3] === "sobre-nos" || window.location.href.split("/")[3] === "categorias" || window.location.href.split("/")[3] === "produto" ? <Footer /> : <FooterApp /> : <> </>}
                </>
                :
                <ContentSection>
                    {children}
                </ContentSection>}
        </Container>
    );
}

export default Content;