import styled from "styled-components";

export const Container = styled.div`

`;
export const Image = styled.div`
        width: 250px;
        height: 150px;
        
    >img {
        max-width: 80%;
        max-height: 100%;
        border-radius: 6px;
    };
    @media(max-width: 800px){
        width: 100px;
        height: 100px;
    }
`;
export const Image2 = styled.div`
        width: 100px;
        height: 100px;  
    >img {
        max-width: 80%;
        max-height: 100%;
        border-radius: 6px;
    }
`;
export const Description = styled.div`
    width: 60%;
    padding-left: 15px;
    >h6 {
        font: normal normal 700 1rem Mulish;
        letter-spacing: 0px;
        color: #434343;
        opacity: 1;
    }
    >small {
        font: normal normal 700 0.9rem Mulish;
        letter-spacing: 0px;
        color: #A9ABAE;
        opacity: 1;
        padding-right: 5px;
        padding-bottom: 5px;
    }
    >b{
        color: #787878;
        font: normal normal 700 0.9rem Mulish;
        letter-spacing: 0px;
        opacity: 1;
        padding-right: 5px;
        padding-bottom: 5px;
    };
    @media(max-width: 800px){
        display: flex;
        flex-direction: column;
    }
`;
export const Price = styled.div`
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    >small {
        color: #A9ABAE;
    }
    @media(max-width: 800px){
        width: 100%;
        flex-direction: column;
    }
`;
export const Input = {
    border: '1px solid #A9ABAE',
    background: '#F1F1F1',
    width: '60px',
    height: '35px',
    borderRadius: ' 5px',
    paddingLeft: '5px',
};

export const P = styled.p`
    padding-top: 5%;
    padding-left: 25px;
    font-size: 15px;
    color: #A9ABAE;
    cursor: pointer;
    &:hover{
        color: #007185;
        text-decoration: underline;
    }
`;
export const DivList = styled.div`
    display: flex;
    padding-top: 15px;
    margin-bottom:5px ;
    border-radius: 6px;
    justify-content: space-between;
    border: 1px solid #DDDDDD;
    @media(max-width: 800px){
        flex-direction: column;
    }
`;

export const Elips = {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
};
export const DivBottom = styled.div`
    justify-content: center;
    padding-bottom: 5px;
    >button {
        width: 100px;
        &:hover{
           
        }
    }
`;