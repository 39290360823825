import styled from "styled-components";
import { styled as style } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { purple } from '@mui/material/colors';


export const btn = styled.button`
    width: 213px;
    height: 42px;
    background: #009c43 0% 0% no-repeat padding-box;
    box-shadow: 0.62px 0.79px 2px #1E120D1A;
    border-radius: 5px;
    opacity: 1;
`;

export const ButtonGreen = style(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  backgroundColor: '#009c43',
  width: '213px',
  height: '42px',
  boxShadow: '0.62px 0.79px 2px #1E120D1A',
  borderRadius: '5px',
  opacity: '1',
  '&:hover': {
    backgroundColor: '#009c43',
    opacity: '0.9'
  },
}));

export const ButtonBlueSmall = style(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  backgroundColor: '#009ee0',
  width: '90px',
  fontSize: '0.8rem',
  height: '25px',
  boxShadow: '0.62px 0.79px 2px #1E120D1A',
  borderRadius: '15px',
  opacity: '1',
  '&:hover': {
    backgroundColor: '#009ee0',
  },
}));
export const ButtonRedSmall = style(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  backgroundColor: '#c32329',
  width: '90px',
  fontSize: '0.8rem',
  height: '25px',
  boxShadow: '0.62px 0.79px 2px #1E120D1A',
  borderRadius: '15px',
  opacity: '1',
  '&:hover': {
    backgroundColor: '#c32329',
  },
}));

export const ButtonGreenSmall = style(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  backgroundColor: '#009c43',
  width: '90px',
  fontSize: '0.8rem',
  height: '25px',
  boxShadow: '0.62px 0.79px 2px #1E120D1A',
  borderRadius: '15px',
  opacity: '1',
  '&:hover': {
    backgroundColor: '#009c43',
  },
}));

export const ButtonSilverSmall = style(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  backgroundColor: '#787878',
  width: '90px',
  fontSize: '0.8rem',
  height: '25px',
  boxShadow: '0.62px 0.79px 2px #1E120D1A',
  borderRadius: '15px',
  opacity: '1',
  '&:hover': {
    backgroundColor: '#787878',
  },
}));

export const ButtonGreen2 = style(Button)(({ theme }) => ({
  backgroundColor: '#c8dbbb',
  width: '213px',
  color: '#009c43',
  height: '42px',
  boxShadow: '0.62px 0.79px 2px #1E120D1A',
  borderRadius: '5px',
  opacity: '1',
  '&:hover': {
    backgroundColor: '#c8dbbb',
  },
}));

export const ButtonRed = style(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  backgroundColor: '#FA4B57',
  width: '213px',
  height: '42px',
  boxShadow: '0.62px 0.79px 2px #1E120D1A',
  borderRadius: '5px',
  opacity: '1',
  '&:hover': {
    backgroundColor: '#FA4B57',
  },
}));

export const ButtonSilver = style(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  backgroundColor: '#787878',
  width: '213px',
  height: '42px',
  boxShadow: '0.62px 0.79px 2px #1E120D1A',
  borderRadius: '5px',
  opacity: '1',
  '&:hover': {
    backgroundColor: '#787878',
  },
}));

export const ButtonSilver2 = style(Button)(({ theme }) => ({
  color: '#434343',
  backgroundColor: 'rgba(241, 241, 241, 0.95)',
  width: '213px',
  height: '42px',
  boxShadow: '0.62px 0.79px 2px #1E120D1A',
  borderRadius: '5px',
  border: '1px solid #D3CBCB',
  opacity: '1',
  margin: '5px',
  '&:hover': {
    backgroundColor: '#BED1EA',

  },
}));
