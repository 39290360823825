import styled from "styled-components";

export const Container = styled.div`
     display: flex;
    padding: 2%;
    padding-bottom: 30px ;
`;
export const Content = styled.div`
    flex: 1;
    overflow-y: auto;
    height: 90vh;
    padding-left: 15px;
`;
export const TopLine = styled.div`
justify-content: space-between;
 display: flex; 
padding-top: 15px;
width: 100%;
@media(max-width: 800px){
    flex-direction: column;
}
`;
export const DivSearch = styled.div`
    width: 30%;
    height: 100%;
    align-items: center;
    display: flex;
    @media(max-width: 800px){
        width: 100%;
    }
`;
export const Line = styled.div`
    width: 100%;
    display: flex;
    padding: 0 15px;
    padding-bottom: 15px ;
`;
export const FavoriteArea = styled.div`
    flex:1;
    height: 100%;
    background-color: #fff ;
    box-shadow: 0px 0px 9px #26242424;
    border-radius: 6px;
    overflow: auto;
`;

export const EmptyDiv = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    >img {
        padding-top: 10%;
        padding-bottom: 2%;
        width: 15%;
    }
    > p{
        font-size: 1.8rem;
        color: #434343;
        font-family: Mulish;
    }
`;

export const Image = styled.div`
    width: 170px;
    height: 147px;
    border-radius: 16px;
    border: 1px solid #C9C5C5;
    

    @media(max-width: 800px){
        width: 80px;
        height: 80px;
    }
`;

export const Mobile = styled.div`
    display: none;
    align-items: center;
    @media(max-width: 900px) {
        display: flex;
    }
`;
export const Web = styled.div`
    height: 100%;

    @media(max-width: 900px) {
        display: none;
    }
`;

export const MiniCard = styled.div`
    width: 45px;
    cursor: pointer;
    height: 45px;
    flex-shrink: 0;
    border-radius: 16px;
    background: #F4EDED;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #009c43;
    transition: transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    :hover{
        transform: translateY(-5px);
    }
`;