import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
    *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;

        @media(min-width: 1366px){
          
            /* width */
        ::-webkit-scrollbar {
        width: 10px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
        background: #f1f1f1; 
        border-radius:5px;
        }
        
        /* Handle */
        ::-webkit-scrollbar-thumb {
        background: #009c43; 
        border-radius:5px;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
        background: #008e3c; 
        }
        }
    }

    html, body, #root {
        height: 100% 100vh;
        width: 100% 100vw;
        display: flex;
        justify-content: center;
        position: static;
    }

    *, button, input{
        border: 0;
        outline: 0;
        font-family: 'Roboto', sans-serif;
    }

    button{
        cursor: pointer;
    }
`;