import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CloseIcon from '@mui/icons-material/Close';
import { TitleH6 } from '../../../../../Styles/style';
import { useInformation } from '../../../../../Providers/informations';
import {
    ListDiv
} from './styles';


const MouseOverPopover = ({ children, func }) => {

    const { storeInfo } = useInformation();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    console.log(storeInfo);

    return (
        <div>
            <Typography
                aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}>
                <div>
                    {children}
                </div>
            </Typography>

            <Popover
                id="mouse-over-popover"
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >

                <Typography sx={{ p: 1 }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', cursor: 'pointer', width: '100%', borderBottom: '1px solid #DDDDDD' }} onClick={handlePopoverClose}>
                        <TitleH6>
                            Ativação da loja
                        </TitleH6>
                        <CloseIcon />
                    </div>
                    <small style={{ color: '#7a7474' }}>
                        Para ativar a loja, siga os passos abaixo:
                    </small>
                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                        <ListItem >
                            <CheckBoxIcon sx={{ color: '#009c43' }} />
                            <ListDiv>
                                <spam> - Cadastro</spam>
                            </ListDiv>
                        </ListItem>
                        <ListItem >
                            <CheckBoxIcon sx={{ color: '#009c43' }} />
                            <ListDiv>
                                <spam> - Aprovação</spam>
                            </ListDiv>
                        </ListItem>
                        <ListItem >
                            {storeInfo?.StoreShipment ?
                                <CheckBoxIcon sx={{ color: '#009c43' }} /> :
                                <CheckBoxOutlineBlankIcon sx={{ color: '#009c43' }} />}
                            <ListDiv>
                                <spam onClick={() => func(3)}> - Locais de Entrega</spam>
                            </ListDiv>
                        </ListItem>
                        <ListItem >
                            {storeInfo?.StoreOrderValue ?
                                <CheckBoxIcon sx={{ color: '#009c43' }} /> :
                                <CheckBoxOutlineBlankIcon sx={{ color: '#009c43' }} />}
                            <ListDiv>
                                <spam onClick={() => func(3)}> - Pedido Minimo</spam>
                            </ListDiv>
                        </ListItem>
                        <ListItem >
                            {storeInfo?.StoreBanck ?
                                <CheckBoxIcon sx={{ color: '#009c43' }} /> :
                                <CheckBoxOutlineBlankIcon sx={{ color: '#009c43' }} />}
                            <ListDiv>
                                <spam onClick={() => func(2)}> - Informações Bancarias</spam>
                            </ListDiv>
                        </ListItem>
                    </List>
                </Typography>
            </Popover>
        </div>
    );
}

export default MouseOverPopover;