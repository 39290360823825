import React from 'react';
import AdmSideBarMenu from '../../Components/SideBarMenu';
import Divider from '@mui/material/Divider';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import { Modal } from 'react-bootstrap';

import {
    Container,
    Content,
    InfoTable,
    Line,
    Search,
    ListS,
} from './styles';
import { getStoresSales } from '../../../Services/adm';


const SalesList = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const [show, setShow] = React.useState(false);
    const handleClose = () => setShow(false);

    const [sales, setSales] = React.useState([]);

    const getSales = async () => {
        const response = await getStoresSales('2023-10-01', '2023-10-31');
        console.log(response)
    }



    React.useEffect(() => {
        getSales();
    }, [location])

    return (
        <Container>
            <div style={{ padding: '10px 5px' }}>
                <AdmSideBarMenu />
            </div>
            <Content>
                <InfoTable>
                    <Line style={{ justifyContent: 'space-between', display: "flex", paddingTop: '15px', }}>
                        <div style={{ paddingLeft: '25px' }}>
                            Sales
                        </div>
                        <Search>
                            <input type="text" placeholder='Procurar' style={{ color: '#A9ABAE' }} />
                        </Search>
                        <div>
                            <MoreVertIcon style={{ cursor: "pointer" }} />
                        </div>
                    </Line>
                    <Divider variant="inset" />

                </InfoTable>
            </Content>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Modal heading</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Deseja confirmar a requisição da loja:?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" >
                        Save
                    </Button>
                </Modal.Footer>
                <div id="sanckbar">

                </div>
            </Modal>

        </Container>
    )
}

export default SalesList;

