import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
`;

export const Span = styled.span`
    color: #717171;
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
`;
export const TextContent = styled.span`
    color: #211E1E;
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
`;
export const Line = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 5px 0px;
`;
export const Lines = styled.div`
    width: 100%;
    padding: 15px 0px 0px 15px;
    display: flex;
    justify-content: space-between;

    @media(max-width: 600px){
        flex-direction: column;
    }
   
`;
export const Label = styled.small`
    color: #8F8888;
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

export const TextProduct = styled.p`
    color: #434343;
    font-size: 0.8rem;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    @media(max-width: 600px){
        width: 80%;
    }
    @media(max-width: 1366px){
        width: 50%;
    }
    
`;
export const TextStore = styled.p`
    color: #211E1E;
    font-size: 0.9rem;
    font-weight: 600;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;
export const ProductViewCard = styled.div`
    display: flex;
    width: 100%;
`;