import React from "react";
import { useInformation } from "../../../../Providers/informations";
import { useLocation, useNavigate } from "react-router-dom";
import { getMySales } from "../../../../Services/store";
import { TitleH3 } from '../../../../Styles/style';
import SwipeableViews from 'react-swipeable-views';
import Typography from '@mui/material/Typography';
/* import Pagination from '@mui/material/Pagination'; */
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import StoreIcon from '@mui/icons-material/Store';
import ReceiptIcon from '@mui/icons-material/Receipt';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import moment from "moment";
import Badge from '@mui/material/Badge';

import {
    ListContentOrder,
    TopTitleDiv,
    ContentCard,
    Container,
} from './styles';
import { Table } from "react-bootstrap";
import IconBreadcrumbs from "../../../Components/General/Breadcrumbs";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 2 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}



const MySales = () => {

    const { setDetailSales } = useInformation();
    const location = useLocation();
    const navigate = useNavigate();
    const theme = useTheme();

    const [orderList, setOrderList] = React.useState([]);

    const [totalOfPages, setTotalOfPages] = React.useState();
    const [nexProducts, setNextProducts] = React.useState();

    const [value, setValue] = React.useState(0);
    const [page, setPage] = React.useState(1);

    const options = { style: 'currency', currency: 'BRL' };

    const getSales = async () => {
        const listOrders = await getMySales();
        if (listOrders?.response?.data.message) {
            console.log("error")
        } else {
            setOrderList(listOrders.data.orders);
            setNextProducts(listOrders.data.orders.links);
            setTotalOfPages(listOrders.data.orders.last_page);
        }
    }

    /* const nextPage = async (value1) => {
        const { data: res } = await axios({ method: 'get', url: `${process.env.REACT_APP_LINK}/api/user/orders${nexProducts?.[value1]?.url.split("/")[1]}`, headers: { 'Authorization': `Bearer ${localStorage.getItem("clientToken")}` } });
        if (res?.data?.orders?.data) {
            setOrderList([res?.data?.orders?.data]);
        }
    } */
    const seeDetail = (list) => {
        setDetailSales(list);
        navigate(`/loja/vendas/detalhe`)
    }

    /*  const handleChangePage = (event, value1) => {
         setPage(value1);
         nextPage(value1);
     }; */

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    React.useEffect(() => {
        getSales();
        setPage(1);
        console.log(page, nexProducts, totalOfPages)
    }, [location])

    const where = [
        { where: 'Minha Loja', link: '/loja/perfil', icon: <StoreIcon sx={{ mr: 0.5 }} fontSize="inherit" /> },
        { where: 'Vendas', link: '/loja/vendas', color: '#009C43', icon: <ReceiptIcon sx={{ mr: 0.5 }} fontSize="inherit" /> },
    ]

    return (
        <Container>
            <IconBreadcrumbs list={where} />
            <ContentCard>
                <TopTitleDiv>
                    <div style={{ height: '50%', display: 'flex', justifyContent: 'space-between', padding: '15px', }}>
                        <TitleH3> Minhas Vendas </TitleH3>
                        {/*  <DivFilter style={{ display: 'flex' }}>
                            <p style={{ display: 'flex', alignItems: 'center', padding: '0 10px', height: '100%' }}>Pedidos feitos em</p>  <Select options={options} />
                        </DivFilter> */}
                    </div>
                    <AppBar position="static" sx={{ background: '#ffffff', color: 'black', border: 'none', boxShadow: '0', flex: '1' }}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="success"
                            textColor="inherit"
                            variant="fullWidth"
                            aria-label="full width tabs example"
                        >
                            <Tab label="Todas Vendas" {...a11yProps(0)} />
                            <Tab label="Novas Vendas" {...a11yProps(1)} />
                            <Tab label="A Processar" {...a11yProps(2)} />
                            <Tab label="Em Processamento" {...a11yProps(3)} />
                            <Tab label="Enviadas" {...a11yProps(4)} />
                            <Tab label="Finalizadas" {...a11yProps(5)} />
                            <Tab label="Canceladas" {...a11yProps(6)} />
                        </Tabs>
                    </AppBar>
                </TopTitleDiv>
                <ListContentOrder>
                    <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={value}
                        onChangeIndex={handleChangeIndex}
                    >
                        <TabPanel value={value} index={0} dir={theme.direction}>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>
                                            Pedido
                                        </th>
                                        <th>
                                            Data
                                        </th>
                                        <th>
                                            Cliente
                                        </th>
                                        <th style={{ textAlign: 'center' }}>
                                            Total
                                        </th>
                                        <th style={{ textAlign: 'center' }}>
                                            Status do Pagamento
                                        </th>
                                        <th style={{ textAlign: 'center' }}>
                                            Status do Processamento
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {orderList
                                        .map((list) => (
                                            <tr key={list.id} style={{ height: '50px', cursor: 'pointer', transition: 'background-color 0.3s ease-out' }} onClick={() => seeDetail(list)}>
                                                <th scope="row">
                                                    {list?.id}
                                                </th>
                                                <td>
                                                    {moment(list?.created_at).format('DD/MM/YYYY')}
                                                </td>
                                                <td>
                                                    {list?.customer_name}
                                                </td>
                                                <td style={{ textAlign: 'center' }}>
                                                    {(list?.total_value / 100).toLocaleString('pt-BR', options)}
                                                </td>
                                                {list?.payment_status === 'Pagamento Pendente' &&
                                                    <td >
                                                        {list?.payment_status}
                                                        {/*  <div style={{ textAlign: 'center', background: '#DDDD', borderRadius: '50px', height: '10px', height: '10px' }}>
                                                        </div> */}
                                                    </td>}
                                                {list?.payment_status === 'Pagamento Concluído' &&
                                                    <td style={{ color: '#009C43' }}>
                                                        {list?.payment_status}
                                                        {/* <div style={{ textAlign: 'center', background: '#009C43', borderRadius: '50px', height: '10px', width: '10px' }}>
                                                        </div> */}
                                                    </td>}
                                                {list?.payment_status === 'Pagamento Vencido' &&
                                                    <td style={{ color: '#F54B5E' }}>
                                                        {list?.payment_status}
                                                        {/* <div style={{ textAlign: 'center', background: '#F54B5E', borderRadius: '50px', height: '10px', width: '10px' }}>
                                                        </div> */}
                                                    </td>}
                                                <td style={{ textAlign: 'center' }}>
                                                    {list?.processing_status}
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>

                            </Table>
                        </TabPanel>
                        <TabPanel value={value} index={1} dir={theme.direction}>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>
                                            Pedido
                                        </th>
                                        <th>
                                            Data
                                        </th>
                                        <th>
                                            Cliente
                                        </th>
                                        <th style={{ textAlign: 'center' }}>
                                            Total
                                        </th>
                                        <th style={{ textAlign: 'center' }}>
                                            Status do Pagamento
                                        </th>
                                        <th style={{ textAlign: 'center' }}>
                                            Status do Processamento
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {orderList
                                        .filter((list) => list.processing_status !== 'Cancelado' && list.payment_status === "Pagamento Pendente")
                                        .map((list) => (
                                            <tr key={list?.id} style={{ height: '50px', cursor: 'pointer', transition: 'background-color 0.3s ease-out' }} onClick={() => seeDetail(list)}>
                                                <th scope="row" >
                                                    {list?.id}
                                                </th>
                                                <td>
                                                    {moment(list?.created_at).format('DD/MM/YYYY')}
                                                </td>
                                                <td>
                                                    {list?.customer_name}
                                                </td>
                                                <td style={{ textAlign: 'center' }}>
                                                    {(list?.total_value / 100).toLocaleString('pt-BR', options)}
                                                </td>
                                                <td style={{ textAlign: 'center' }}>
                                                    {list?.payment_status}
                                                </td>
                                                <td style={{ textAlign: 'center' }}>
                                                    {list?.processing_status}

                                                </td>
                                            </tr>
                                        ))}
                                </tbody>

                            </Table>
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: '25px' }}>
                                {/* <Stack spacing={2}>
                                    <Pagination count={totalOfPages} page={page} onChange={handleChangePage} />
                                </Stack> */}
                            </div>
                        </TabPanel>
                        <TabPanel value={value} index={2} dir={theme.direction}>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>
                                            Pedido
                                        </th>
                                        <th>
                                            Data
                                        </th>
                                        <th>
                                            Cliente
                                        </th>
                                        <th style={{ textAlign: 'center' }}>
                                            Total
                                        </th>
                                        <th style={{ textAlign: 'center' }}>
                                            Status do Pagamento
                                        </th>
                                        <th style={{ textAlign: 'center' }}>
                                            Status do Processamento
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {orderList
                                        .filter((list) => list.processing_status === 'Pagamento Concluído' && list.payment_status === 'Pagamento Concluído')
                                        .map((list) => (
                                            <tr key={list.id} style={{ height: '50px', cursor: 'pointer', transition: 'background-color 0.3s ease-out' }} onClick={() => seeDetail(list)}>
                                                <th scope="row">
                                                    {list?.id}
                                                </th>
                                                <td>
                                                    {moment(list?.created_at).format('DD/MM/YYYY')}
                                                </td>
                                                <td>
                                                    {list?.customer_name}
                                                </td>
                                                <td style={{ textAlign: 'center' }}>
                                                    {(list?.total_value / 100).toLocaleString('pt-BR', options)}
                                                </td>
                                                <td style={{ textAlign: 'center' }}>
                                                    {list?.payment_status}
                                                </td>
                                                <td style={{ textAlign: 'center' }}>
                                                    {list?.processing_status}
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>

                            </Table>
                        </TabPanel>
                        <TabPanel value={value} index={3} dir={theme.direction}>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>
                                            Pedido
                                        </th>
                                        <th>
                                            Data
                                        </th>
                                        <th>
                                            Cliente
                                        </th>
                                        <th style={{ textAlign: 'center' }}>
                                            Total
                                        </th>
                                        <th style={{ textAlign: 'center' }}>
                                            Status do Pagamento
                                        </th>
                                        <th style={{ textAlign: 'center' }}>
                                            Status do Processamento
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {orderList
                                        .filter((list) => list.processing_status === 'Em Processamento' && list.payment_status === 'Pagamento Concluído')
                                        .map((list) => (
                                            <tr key={list.id} style={{ height: '50px', cursor: 'pointer', transition: 'background-color 0.3s ease-out' }} onClick={() => seeDetail(list)}>
                                                <th scope="row">
                                                    {list?.id}
                                                </th>
                                                <td>
                                                    {moment(list?.created_at).format('DD/MM/YYYY')}
                                                </td>
                                                <td>
                                                    {list?.customer_name}
                                                </td>
                                                <td style={{ textAlign: 'center' }}>
                                                    {(list?.total_value / 100).toLocaleString('pt-BR', options)}
                                                </td>
                                                <td style={{ textAlign: 'center' }}>
                                                    {list?.payment_status}
                                                </td>
                                                <td style={{ textAlign: 'center' }}>
                                                    {list?.processing_status}
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>

                            </Table>
                        </TabPanel>
                        <TabPanel value={value} index={4} dir={theme.direction}>

                            <Table>
                                <thead>
                                    <tr>
                                        <th>
                                            Pedido
                                        </th>
                                        <th>
                                            Data
                                        </th>
                                        <th>
                                            Cliente
                                        </th>
                                        <th style={{ textAlign: 'center' }}>
                                            Total
                                        </th>
                                        <th style={{ textAlign: 'center' }}>
                                            Status do Pagamento
                                        </th>
                                        <th style={{ textAlign: 'center' }}>
                                            Status do Processamento
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {orderList
                                        .filter((list) => list.processing_status === 'Enviado' && list.payment_status === "Pagamento Concluído")
                                        .map((list) => (
                                            <tr key={list.id} style={{ height: '50px', cursor: 'pointer', transition: 'background-color 0.3s ease-out' }} onClick={() => seeDetail(list)}>
                                                <th scope="row">
                                                    {list?.id}
                                                </th>
                                                <td>
                                                    {moment(list?.created_at).format('DD/MM/YYYY')}
                                                </td>
                                                <td>
                                                    {list?.customer_name}
                                                </td>
                                                <td style={{ textAlign: 'center' }}>
                                                    {(list?.total_value / 100).toLocaleString('pt-BR', options)}
                                                </td>
                                                <td style={{ textAlign: 'center' }}>
                                                    {list?.payment_status}
                                                    {/* {list?.payment_status === 'paid' && 'Pago'}
                                                    {list?.payment_status === 'created' && 'Pendente'} */}
                                                </td>
                                                <td style={{ textAlign: 'center' }}>
                                                    {list?.processing_status}
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>

                            </Table>
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: '25px' }}>
                                {/*  <Stack spacing={2}>
                                    <Pagination count={totalOfPages} page={page} onChange={handleChangePage} />
                                </Stack> */}
                            </div>
                        </TabPanel>
                        <TabPanel value={value} index={5} dir={theme.direction}>

                            <Table>
                                <thead>
                                    <tr>
                                        <th>
                                            Pedido
                                        </th>
                                        <th>
                                            Data
                                        </th>
                                        <th>
                                            Cliente
                                        </th>
                                        <th style={{ textAlign: 'center' }}>
                                            Total
                                        </th>
                                        <th style={{ textAlign: 'center' }}>
                                            Status do Pagamento
                                        </th>
                                        <th style={{ textAlign: 'center' }}>
                                            Status do Processamento
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {orderList
                                        .filter((list) => list.processing_status === 'Concluído')
                                        .map((list) => (
                                            <tr key={list.id} style={{ height: '50px', cursor: 'pointer', transition: 'background-color 0.3s ease-out' }} onClick={() => seeDetail(list)}>
                                                <th scope="row">
                                                    {list?.id}
                                                </th>
                                                <td>
                                                    {moment(list?.created_at).format('DD/MM/YYYY')}
                                                </td>
                                                <td>
                                                    {list?.customer_name}
                                                </td>
                                                <td style={{ textAlign: 'center' }}>
                                                    {(list?.total_value / 100).toLocaleString('pt-BR', options)}
                                                </td>
                                                <td style={{ textAlign: 'center' }}>
                                                    {list?.payment_status}
                                                    {/*   {list?.payment_status === 'paid' && 'Pago'}
                                                    {list?.payment_status === 'created' && 'Pendente'} */}
                                                </td>
                                                <td style={{ textAlign: 'center' }}>
                                                    {list?.processing_status}
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>

                            </Table>
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: '25px' }}>
                                {/* <Stack spacing={2}>
                                    <Pagination count={totalOfPages} page={page} onChange={handleChangePage} />
                                </Stack> */}
                            </div>
                        </TabPanel>
                        <TabPanel value={value} index={6} dir={theme.direction}>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>
                                            Pedido
                                        </th>
                                        <th>
                                            Data
                                        </th>
                                        <th>
                                            Cliente
                                        </th>
                                        <th style={{ textAlign: 'center' }}>
                                            Total
                                        </th>
                                        <th style={{ textAlign: 'center' }}>
                                            Status do Pagamento
                                        </th>
                                        <th style={{ textAlign: 'center' }}>
                                            Status do Processamento
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {orderList
                                        .filter((list) => list.processing_status === 'Cancelado' /* && list.payment_status === 'Pagamento Concluído' */)
                                        .map((list) => (
                                            <tr key={list.id} style={{ height: '50px', cursor: 'pointer', transition: 'background-color 0.3s ease-out' }} onClick={() => seeDetail(list)}>
                                                <th scope="row">
                                                    {list?.id}
                                                </th>
                                                <td>
                                                    {moment(list?.created_at).format('DD/MM/YYYY')}
                                                </td>
                                                <td>
                                                    {list?.customer_name}
                                                </td>
                                                <td style={{ textAlign: 'center' }}>
                                                    {(list?.total_value / 100).toLocaleString('pt-BR', options)}
                                                </td>
                                                <td style={{ textAlign: 'center' }}>
                                                    {list?.payment_status}
                                                </td>
                                                <td style={{ textAlign: 'center' }}>
                                                    {list?.processing_status}
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>

                            </Table>
                        </TabPanel>
                    </SwipeableViews>
                </ListContentOrder>
            </ContentCard>
        </Container>
    )
}

export default MySales;