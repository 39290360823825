import React from 'react';
import { ButtonGreenSmall, ButtonRedSmall} from '../../../../../Styles/stylesButtons';


const StoreAddress = ({value, fun, closeModal}) => {

    const [change, setChange] = React.useState(false);

    const [street, setStreet] = React.useState(value?.street);
    const [num, setNum] = React.useState(value?.num);
    const [complement, setComplement] = React.useState(value?.complement);
    const [district, setDistrict] = React.useState(value?.district);
    const [zip, setZip] = React.useState(value?.zip);
    const [city, setCity] = React.useState(value?.city);
    const [state, setState] = React.useState(value?.state);
    const [country, setCountry] = React.useState(value?.country);

    const ApplyChange = () => {
        fun({street: street, num: num, complement: complement, district: district, zip: zip, city: city, state: state, country: country});
        closeModal(false);
    }

    /* 
    value.street: ,
    value.complement: ,
    value.district: ,
    value.num: ,
    value.city: ,
    value.state: ,
    value.country: ,
    value.zip: , 
    */

    return (
        <form onChange={() => setChange(true)}>
            <div className="container">
                <div className="row">
                    <div className="col-8">
                        <label htmlFor="address" className="form-label" style={{ fontSize: '0.8rem' }}>Endereço</label>
                        <input type="text" className="form-control" id="address" value={street} onChange={(e) => setStreet(e.target.value)} />
                    </div>
                    <div className="col-4">
                        <label htmlFor="number" className="form-label" style={{ fontSize: '0.8rem' }}>Numero</label>
                        <input type="number" className="form-control" id="number" aria-describedby="number" value={num} onChange={(e) => setNum(e.target.value)} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <label htmlFor="complement" className="form-label" style={{ fontSize: '0.8rem' }}>Complemento</label>
                        <input type="text" className="form-control" id="complement" value={complement} onChange={(e) => setComplement(e.target.value)} />
                    </div>
                    <div className="col-6">
                        <label htmlFor="district" className="form-label" style={{ fontSize: '0.8rem' }}>Bairro</label>
                        <input type="text" className="form-control" id="district" aria-describedby="district" value={district} onChange={(e) => setDistrict(e.target.value)} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <label htmlFor="zip_code" className="form-label" style={{ fontSize: '0.8rem' }}>CEP</label>
                        <input type="text" className="form-control" id="zip_code" value={zip} onChange={(e) => setZip(e.target.value)} />
                    </div>
                    <div className="col-6">
                        <label htmlFor="city" className="form-label" style={{ fontSize: '0.8rem' }}>Cidade</label>
                        <input type="text" className="form-control" id="city" aria-describedby="city" value={city} onChange={(e) => setCity(e.target.value)} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <label htmlFor="state" className="form-label" style={{ fontSize: '0.8rem' }}>Estado</label>
                        <input type="text" className="form-control" id="state" value={state} onChange={(e) => setState(e.target.value)} />
                    </div>
                    <div className="col-6">
                        <label htmlFor="country" className="form-label" style={{ fontSize: '0.8rem' }}>Country</label>
                        <input type="text" className="form-control" id="country" aria-describedby="country" value={country} onChange={(e) => setCountry(e.target.value)} />
                    </div>
                </div>
               <div style={{display: 'flex', justifyContent: 'flex-end', paddingTop: '15px'}}>
               <div style={{paddingRight: '10px' }}>
                    <ButtonRedSmall onClick={() => closeModal(false)}> Cancelar </ButtonRedSmall>
                </div>
                <div style={{paddingLeft: '0 10px'}}>
                    {change && <ButtonGreenSmall onClick={() => ApplyChange()}> Aplicar </ButtonGreenSmall>}
                </div>
               </div>
            </div>
        </form>
    )
}

export default StoreAddress;