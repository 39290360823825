import styled from 'styled-components';

export const Container = styled.div`
     display: flex;
    justify-content: right;
`;
export const ProductSection = styled.div`
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
`;

export const Div404 = styled.div`
    width: 100vw;
    height: 80vh;
`;

export const DivWite = styled.div`
    display: flex; 
    justify-content: space-between;
    background: #fff;
    height: 30vh;
    box-shadow: 0px 1px 2px #00000029;
    border-radius: 6px ;
    margin-top: 50px;
`;
export const DivImage = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
`;

export const DivTexts = styled.div`
    display: flex;
    justify-content: center;
    flex: 1;
    flex-direction: column;

    >h3{
        font: normal normal 700 1.8rem Mulish;
        letter-spacing: 0px;
        color: #434343;
        opacity: 1;
        padding-bottom: 15px;
    }
`;

export const DivAsideLeft = styled.div`
    flex: 1;
    margin: 0 10px;
    padding: 15px;
`;

export const DivCardAside = styled.div`
    width: 80%;
    height: 200px;
    border-radius: 6px;
    box-shadow:  2px 2px 2px #00000029;
    margin-bottom: 15px;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
`;