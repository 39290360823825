import styled from "styled-components";

export const Container = styled.div`
     padding: 1% 5%;
`;

export const ContentCard = styled.div`
    width: 100%;
    height: 100%;
    background-color: #FFFFFF ;
    box-shadow: 0px 0px 9px #26242424;
    border-radius: 6px;
    overflow: auto;
`;
export const TopTitleDiv = styled.div`
    width: 100%;
    height: 110px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 2px #00000029;
    border-bottom: 1px solid #DDDDDD;
    opacity: 1;
    padding: 5px;
    border-radius: 6px 6px 0 0;
`;

export const Card = styled.div`
    border-radius: 6px;
    border: 1px solid #B3B3B3;
    background: #F8F8F8;
    padding: 15px;
    width: 100%;
`;