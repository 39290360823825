import styled from "styled-components";

export const Container = styled.div`
    grid-area: CT;
    height: 100vh;
    width: 100vw;
    overflow-y: auto;
   
`;
export const ContentSection = styled.div`
    overflow: hidden;
;
  

@media(min-width: 1921px){
        width: 1920px;
        justify-content: center;
        align-items: center;
        align-content: center;
        flex-grow: 1;
    }
`;