import axios from "axios";

export async function setAccountChek(accountName, banckCode, agencyNumber, accountNumber, digiNumber) {
    try {
        var data = {
            "bank_holder_name": accountName,
            "bank_bank": banckCode,
            "bank_branch_number": agencyNumber,
            "bank_account_number": accountNumber,
            "bank_account_check_digit": digiNumber,
        }
        var { data: res } = await axios({ method: 'post', url: `${process.env.REACT_APP_LINK}/api/store/checkout-pagarme/setup`, headers: { 'Authorization': `Bearer ${localStorage.getItem("clientToken")}` }, data })
        return res
    } catch (error) {
        return error
    }
};

export async function updateAccountChek(accountName, banckCode, agencyNumber, accountNumber, digiNumber) {
    try {
        var data = {
            "bank_holder_name": accountName,
            "bank_bank": banckCode,
            "bank_branch_number": agencyNumber,
            "bank_account_number": accountNumber,
            "bank_account_check_digit": digiNumber,
        }
        var { data: res } = await axios({ method: 'put', url: `${process.env.REACT_APP_LINK}/api/store/checkout-pagarme/update`, headers: { 'Authorization': `Bearer ${localStorage.getItem("clientToken")}` }, data })
        return res
    } catch (error) {
        return error
    }
}

export async function getBalance() {
    const { data: res } = await axios({ method: 'get', url: `${process.env.REACT_APP_LINK}/api/store/checkout-pagarme/balance`, headers: { 'Authorization': `Bearer ${localStorage.getItem("clientToken")}` } })
    return res
}
export async function getWithdrawl() {
    const { data: res } = await axios({ method: 'get', url: `${process.env.REACT_APP_LINK}/api/store/checkout-pagarme/withdrawals`, headers: { 'Authorization': `Bearer ${localStorage.getItem("clientToken")}` } })
    return res
}

export async function makeWithdrawl(value) {
    var data = {
        "amount": value
    }
    const { data: res } = await axios({ method: 'post', url: `${process.env.REACT_APP_LINK}/api/store/checkout-pagarme/withdrawals`, headers: { 'Authorization': `Bearer ${localStorage.getItem("clientToken")}`, }, data })
    return res
}

export async function setMinimunValue(value, storeSlug) {
    var data = {
        "minimum_order_value": value,
    }
    const { data: res } = await axios({ method: "put", url: `${process.env.REACT_APP_LINK}/api/store/profile/${storeSlug}/minimum-order-value`, headers: { 'Authorization': `Bearer ${localStorage.getItem("clientToken")}` }, data })
    return res
}