import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 80vh;
`;

export const Middle = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2%;
    margin-bottom: 5%;
    justify-content: center;
    background-color: #fff;
    border-radius: 6px;
    width: 62rem;
    height: 62rem;

    @media(max-width: 800px){
      width: 100vw;
    }

   > h2{
    font-weight: bold;
    font-size: 3rem;
    opacity: 0.8;
    color: #009c43;
    padding-bottom: 0.3rem;

    @media(max-width: 600px){
      font-size: 2rem;
    }
   }

   >h6 {
    font-weight: 600;
    font-size: 1.2rem;
    color: #434343;
    padding-bottom: 0.3rem;
   }
   >small{
     font-size: 0.9rem;
     padding-bottom: 1rem;
     text-align: center;
     @media(max-width: 600px){
      font-size: 0.7rem;
    }
   }

`;
export const Inputs = styled.div`
    width: 100%;
    justify-content: center;
    display: flex;
    > div {
      > input {
      width: 4rem;
      height: 4rem;
      background-color: #f1f1f1f1;
      border-radius: 16px;
      border: 1px solid #DDDDDD;
      padding: 5px;
      font-size: 2rem;
      text-align: center;
      color: #009c43;
      font-weight: 600;
      margin: 10px;

      @media(max-width: 600px){
        width:  3rem;
        height: 3rem;
        border-radius: 8px;
      }
    }
    }

    @media(max-width: 450px){
       display: flex;
       flex-direction: column;
       align-items: center;
      }
`;
export const Input = styled.input`
   
`;