import React from 'react';
import { Navigate } from 'react-router-dom';


const AdminPrivateRoute = ({ children }) => {
    const auth = localStorage.getItem('Auth');
    const controll = localStorage.getItem('controll');
    return auth === "true" && controll === "true" ? children : <Navigate to="/agroProdutos_management/supervisor/login" />;
  }

export default AdminPrivateRoute;

