import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import empty from '../../../../Assets/ImagensGerais/empty.png';
import { useNavigate } from 'react-router-dom';

import {
    Container,
    ProductSection,
    arrowStyles,
} from './styles';
import CardProduct from '../CardProduct';
import { TitleH3 } from '../../../../Styles/style';


const CardCaroselProduct = ({ products, title, slug, param }) => {

    const navigate = useNavigate();

    React.useEffect(() => {
    }, [])
    return (
        <Container>
            <div style={{ marginLeft: '8%', display: 'flex', height: '100%' }}>
                <div >
                    <TitleH3>
                        {title}
                    </TitleH3>
                </div>
                {!param && <div style={{ flex: '1', display: 'flex', alignContent: 'flex-end', alignItems: 'flex-end', textAlign: 'justify', marginLeft: '25px', cursor: 'pointer', paddingBottom: '10px', color: '#009c43' }}>
                    <small onClick={() => navigate(`/categorias/${slug}/${title}`)}>Ver mais</small>
                </div>}
                
            </div>
            <Carousel autoPlay={false}
                showThumbs={false}
                infiniteLoop={false}
                showStatus={false}
                renderArrowNext={(onClickHandler, hasNext, label) =>
                    hasNext && (
                        <button type="button" onClick={onClickHandler} title={label} style={{ ...arrowStyles, right: 15 }}>
                            <ArrowForwardIosIcon />
                        </button>
                    )
                }
                renderArrowPrev={(onClickHandler, hasPrev, label) =>
                    hasPrev && (
                        <button type="button" onClick={onClickHandler} title={label} style={{ ...arrowStyles, left: 15 }}>
                            <ArrowBackIosNewIcon />
                        </button>
                    )
                }>
                <ProductSection>
                    {products?.slice(0, 4).map((product) =>
                        <CardProduct key={product?.id} store={product.store_slug} img={!product?.images ? empty : `${process.env.REACT_APP_LINK}/${product?.images?.[0]}`} title={product?.title} description={product?.description} price={product?.price} slug={product.product_slug} />
                    )}
                </ProductSection>

                <ProductSection>
                    {products?.slice(4, 8).map((product) =>
                        <CardProduct key={product?.id} store={product.store_slug} img={!product?.images ? empty : `${process.env.REACT_APP_LINK}/${product?.images?.[0]}`} title={product?.title} description={product?.description} price={product?.price} slug={product.product_slug} />
                    )}
                </ProductSection>

            </Carousel>
        </Container >
    );
};

export default CardCaroselProduct;