import React from "react";
import { ButtonSilver2 } from "../../../Styles/stylesButtons";


export const NfDownload = ({ nf }) => {

    const downloadNf = (nf) => {
        window.open(`${process.env.REACT_APP_LINK}/${nf}`, '_blank');
    }

    return (
        <div>
            <ButtonSilver2 style={{ width: '102px', height: '36px', borderRadius: '6px', border: '1px solid #8F8888', margin: '0' }} onClick={() => downloadNf(nf)}>Baixar NF</ButtonSilver2>
        </div>
    )
}