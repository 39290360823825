import React from 'react';
import { Grid, } from './styles';
import Content from '../Content';
import { useInformation } from '../../../../Providers/informations';

const Layout = ({ children }) => {
    const { setPageWidth } = useInformation;

    function recarregarPagina() {
        setPageWidth(window.innerWidth)
    }
    var TimeOut;

    window.onresize = function () {
        clearTimeout(TimeOut);
        TimeOut = setTimeout(recarregarPagina, 10);
    };

    React.useEffect(() => {
        if (process.env.REACT_APP_ENVARIOMENT === "PRODUCTION") {
            const script = document.createElement("script");
            script.async = true;
            script.src = "https://www.googletagmanager.com/gtag/js?id=G-RSSDQYKP04";
            document.head.appendChild(script);

            script.onload = () => {
                window.dataLayer = window.dataLayer || [];
                function gtag() {
                    window.dataLayer.push(arguments);
                }
                gtag('js', new Date());
                gtag('config', 'G-RSSDQYKP04');
            }
        }
    }, []);


    return (
        <Grid>
            <Content>
                {children}
            </Content>
        </Grid>
    );
}

export default Layout;