import styled from "styled-components";

export const Container = styled.div`
    display: flex;

`;

export const Content = styled.div`
    flex: 1;
    overflow-y: auto;
    height: 90vh;
    padding: 10px 15px;
`;
export const Line = styled.div`
    width: 100%;
    display: flex;
    padding: 0 15px;
    padding-bottom: 15px ;
`;

export const InfoTable = styled.div`
    flex:1;
    height: 100%;
    background-color: #fff ;
    box-shadow: 0px 0px 9px #26242424;
    border-radius: 6px;
    overflow: auto;
    padding: 10px;
`;

export const Search = styled.div`
        width: 40%;
        height: 40px;
        border: 1px solid #A9ABAE;
        border-radius: 3px ;
        background-color: #F1F1F1 ;
        >input {
        flex: 1;
        width: 70%;
        height: 100%;
        padding-left: 10px ;
        background-color: #F1F1F1 ;
    }
    >input::placeholder {
            color: #A9ABAE;
        }
        >select{
            background-color: #F1F1F1 ;
        }
`;

export const ButtonPage = styled.div`
    margin: 5px 15px;
    cursor: pointer;
    border-radius: 4px;
    padding: 5px 10px;
    &:hover{
        background-color: #f1f1f1;
    }
`;