import React from "react";
import { useInformation } from "../../../../Providers/informations";
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import { TitleH6 } from "../../../../Styles/style";
import ImageIcon from '@mui/icons-material/Image';
import { useNavigate } from "react-router-dom";
import ListItem from '@mui/material/ListItem';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import logo from '../../../../Assets/Login/logo_white.png'
import { Document, Page, Text, View, PDFDownloadLink, StyleSheet, Image } from '@react-pdf/renderer';
import ReceiptIcon from '@mui/icons-material/Receipt';
import SummarizeIcon from '@mui/icons-material/Summarize';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { postNF } from "../../../../Services/store";
import SnackBar from "../../../Components/General/SnackBar";
import DetailsIcon from '@mui/icons-material/Details';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Modal } from 'react-bootstrap';
import StoreIcon from '@mui/icons-material/Store';

import {
    Container,
    SmallCard,
    InfoTable,
    LineBy3,
    LineBy3B,
    DivBy3,
    Salles,
    Line,
    DivProducts,
    DivTop,
} from './styles';
import { SimpleAccordion } from "./settings";
import IconBreadcrumbs from "../../../Components/General/Breadcrumbs";


const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
        backgroundColor: '#ffffff',
        border: '1px solid #c7c8c9'
    },
    top: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingBottom: '20px',
        width: '100%'
    },
    left: {
        width: '80%',
        border: '1px solid #c7c8c9',
        padding: '5px',
        borderRadius: '6px'
    },
    center: {
        width: '100%',
        border: '1px solid #f7f7f7',
        padding: '5px',
        borderRadius: '6px'
    },
    divz: {
        flexDirection: 'row',
        fontSize: 12,
        borderBottom: '1px solid #f7f7f7',
    },
    divzTop: {
        flexDirection: 'row',
        fontSize: 12,
        borderBottom: '1px solid #f7f7f7',
        height: '20px',
    },
    divY: {
        flexDirection: 'row',
        fontSize: 15,
        justifyContent: 'space-between',
        padding: '10px 0',
        borderBottom: '1px solid #c7c8c9',
    },
    image: {
        backgroundColor: '#009c43',
        width: '20%',
        border: '1px solid #f7f7f7',
        padding: '5px',
        borderRadius: '6px',
    },
    itenD: {
        textAlign: 'left',
    },
    itemC: {
        textAlign: 'center',
    },
    line: {
        flexDirection: 'row',
        fontSize: 12,
        justifyContent: 'space-between',
        padding: '10px 0',
        borderBottom: '1px solid #f7f7f7',
        textAlign: 'left',
    },
    line2: {
        flexDirection: 'row',
        fontSize: 12,
        textAlign: 'left',
        width: '50%'
    },
    line3: {
        flexDirection: 'row',
        fontSize: 12,
        textAlign: 'center',
        width: '15%'
    },
    table: {
        border: '1px solid #f7f7f7',
        padding: '5px',
        borderRadius: '6px'
    }
});

const SalesDetail = () => {

    const navigate = useNavigate();
    const { detailSales } = useInformation();
    const zero = 0;

    const options = { style: 'currency', currency: 'BRL' };

    const [selectedFile, setSelectedFile] = React.useState(detailSales?.nf_customer);
    const [uploaded, setUploaded] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [successStatus, setSuccessStatus] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [errorStatus, setErrorSuccess] = React.useState(false);


    const [show, setShow] = React.useState(false);
    const handleCloseModal = () => setShow(false);



    React.useEffect(() => {
        if (!detailSales?.id) {
            navigate('/loja/vendas')
        }
    }, [detailSales]);

    const handleFileChange = async (event) => {
        handleClose();
        const response = await postNF(detailSales?.id, event.target.files[0])
        if (response) {
            setSelectedFile(URL.createObjectURL(event?.target?.files[0]));
            setUploaded(true)
            setSuccess(true);
            setTimeout(() => {
                setSuccess(false);
            }, 4000);
        } else {
            setError(true);
            setTimeout(() => {
                setError(false)
            }, 4000);
        }
    };

    const openNf = () => {
        uploaded ? window.open(`${selectedFile}`, '_blank') : window.open(`${process.env.REACT_APP_LINK}/storage/stores/${detailSales?.nf_customer}`, '_blank');
    }


    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleContextMenu = (event) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);

    };

    const changeNF = () => {
        setSelectedFile('');
    };

    const changedStatus = () => {
        setSuccessStatus(true);
        setTimeout(() => {
            setSuccessStatus(false);
            navigate('/loja/vendas')
        }, 3000);
    }

    const notChangedStatus = () => {
        setErrorSuccess(true);
        setTimeout(() => {
            setErrorSuccess(false);
        }, 4000);
    }

    const where = [
        { where: 'Minha Loja', link: '/loja/perfil', icon: <StoreIcon sx={{ mr: 0.5 }} fontSize="inherit" /> },
        { where: 'Vendas', link: '/loja/vendas', icon: <ReceiptIcon sx={{ mr: 0.5 }} fontSize="inherit" /> },
        { where: 'Detalhe da Venda', link: '/loja/vendas/detalhe', color: '#009C43', icon: <DetailsIcon sx={{ mr: 0.5 }} fontSize="inherit" /> },
    ]

    return (
        <Container>
            <IconBreadcrumbs list={where} />
            {/* <SideBarMenu /> */}
            {/*   <Content> */}
            <Line style={{}}>
                <InfoTable>
                    <DivTop>
                        <TitleH6>Pedido: {detailSales?.id}</TitleH6>
                        <div>
                            <small style={{ color: '#009c43', padding: '0 10px' }}>{detailSales?.processing_status}</small>
                        </div>
                    </DivTop>
                    <hr></hr>
                    <div style={{ padding: '0 5%', color: '#009c43' }}><MonetizationOnIcon /></div>
                    <LineBy3 >
                        <DivBy3><h6>Desconto:</h6></DivBy3>
                        <DivBy3 style={{ margin: 'auto' }}> <small> - </small></DivBy3>
                        <DivBy3 style={{ margin: 'auto' }}> <small> {zero.toLocaleString('pt-BR', options)} </small></DivBy3>
                    </LineBy3>
                    <LineBy3 >
                        <DivBy3><h6>Subtotal:</h6></DivBy3>
                        <DivBy3 style={{ margin: 'auto' }}> <small> {detailSales?.products?.length} {detailSales?.products?.length <= 1 ? 'Item' : 'Itens'} </small></DivBy3>
                        <DivBy3 style={{ margin: 'auto' }}> <small>{(detailSales?.total_value / 100).toLocaleString('pt-BR', options)} </small></DivBy3>
                    </LineBy3>
                    <LineBy3 >
                        <DivBy3><h6>Frete:</h6></DivBy3>
                        <DivBy3 style={{ margin: 'auto' }}> <small> - </small></DivBy3>
                        <DivBy3 style={{ margin: 'auto' }}> <small> {zero.toLocaleString('pt-BR', options)} </small></DivBy3>
                    </LineBy3>
                    <LineBy3 >
                        <DivBy3><h6 style={{ color: 'green', fontWeight: 'bold' }}>Pago pelo cliente</h6></DivBy3>
                        <DivBy3 style={{ margin: 'auto' }}> <small style={{ color: 'green', fontWeight: 'bold' }}>{(detailSales?.total_value / 100).toLocaleString('pt-BR', options)} </small></DivBy3>
                        <DivBy3 style={{ margin: 'auto' }}> <small style={{ color: 'green', fontWeight: 'bold' }}>{detailSales?.payment_status} </small></DivBy3>
                    </LineBy3>

                    <hr></hr>
                    <div style={{ padding: '0 5%', color: '#009c43' }}><AccountCircleIcon /></div>
                    <LineBy3 >
                        <DivBy3><h6>Cliente:</h6></DivBy3>
                        <DivBy3> <small>{detailSales?.customer_document}</small></DivBy3>
                        <DivBy3> <small> {detailSales?.customer_name} </small></DivBy3>
                    </LineBy3>
                    <LineBy3B >
                        <DivBy3 ><h6>Contato:</h6></DivBy3>
                        <DivBy3> <small> {detailSales?.customer_phone}</small></DivBy3>
                        <DivBy3> <small> {detailSales?.customer_email}</small></DivBy3>
                    </LineBy3B>
                    <LineBy3 >
                        <DivBy3><h6>Data da Compra:</h6></DivBy3>
                        <DivBy3> <small> </small></DivBy3>
                        <DivBy3> <small>{detailSales?.created_at}</small></DivBy3>
                    </LineBy3>
                    <LineBy3 >
                        <DivBy3><h6>Endereço de entrega:</h6></DivBy3>
                        <DivBy3> <small> </small></DivBy3>
                        <DivBy3><small> {detailSales?.shipping_street} {detailSales?.shipping_number}, {detailSales?.shipping_district} - {detailSales?.shipping_city} {detailSales?.shipping_state} - {detailSales?.shipping_country} </small></DivBy3>
                    </LineBy3>

                </InfoTable>
                <DivProducts >

                    <Salles>
                        <Line style={{ justifyContent: 'space-between', display: "flex", paddingTop: '15px', }}>
                            <div style={{ padding: '0 5%', display: 'flex', justifyContent: 'space-between' }}>
                                <TitleH6>Produtos ({detailSales?.products?.length})</TitleH6>
                            </div>
                        </Line>
                        <hr></hr>
                        <Line>
                            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                {detailSales?.products?.map((prod) => (
                                    <ListItem sx={{ cursor: 'pointer' }} onClick={() => navigate(`/produto/detalhes/${prod?.store_slug}/${prod?.product_slug}`)} key={prod?.product_slug}>
                                        <ListItemAvatar>
                                            <Avatar src={`${process.env.REACT_APP_LINK}/${prod?.image}`}>
                                                <ImageIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary={prod?.title} secondary={`${(prod?.price / 100).toLocaleString('pt-BR', options)} - (${prod?.quantity})`} />
                                    </ListItem>
                                ))}
                            </List>
                        </Line>
                    </Salles>

                    <Line style={{ marginTop: '15px', width: '100%', height: '21vh', color: '#009c43', paddingLeft: '15px' }}>
                        <SmallCard style={{ textAlign: 'center' }}>
                            <PDFDownloadLink style={{ color: '#009c43', textDecoration: 'none', }} document={<Document>
                                <Page size="A4" style={styles.page}>
                                    <View style={styles.section}>
                                        <View id="top" style={styles.top}>
                                            <View style={styles.image}>
                                                <Image
                                                    src={logo}
                                                />
                                            </View>
                                            {/* Info sobre o cliente */}
                                            <View id="left" style={styles.left}>
                                                <View style={styles.divz}>
                                                    <View style={{ width: '33%' }}>
                                                        <Text>Cliente:</Text>
                                                    </View>
                                                    <View style={{ width: '67%' }}>
                                                        <Text>{detailSales?.customer_name}</Text>
                                                    </View>
                                                </View>
                                                <View style={styles.divz}>
                                                    <View style={{ width: '33%' }}>
                                                        <Text>CPF:</Text>
                                                    </View>
                                                    <View style={{ width: '67%' }}>
                                                        <Text>{detailSales?.customer_document}</Text>
                                                    </View>
                                                </View>
                                                <View style={styles.divz}>
                                                    <View style={{ width: '33%' }}>
                                                        <Text>Telefone:</Text>
                                                    </View>
                                                    <View style={{ width: '67%' }}>
                                                        <Text>{detailSales?.customer_phone}</Text>
                                                    </View>
                                                </View>
                                                <View style={styles.divz}>
                                                    <View style={{ width: '33%' }}>
                                                        <Text>Email:</Text>
                                                    </View>
                                                    <View style={{ width: '67%' }}>
                                                        <Text>{detailSales?.customer_email}</Text>
                                                    </View>
                                                </View>
                                                <View>
                                                    <View style={styles.divz}>
                                                        <View style={{ width: '33%' }}>
                                                            <Text>Endereço:</Text>
                                                        </View>
                                                        <View style={{ width: '67%' }}>
                                                            <Text> {detailSales?.shipping_street} {detailSales?.shipping_number}, {detailSales?.shipping_district} - {detailSales?.shipping_city} {detailSales?.shipping_state} - {detailSales?.shipping_country} </Text>
                                                        </View>
                                                    </View>
                                                </View>
                                            </View>
                                        </View>
                                        {/* Info sobre pedido */}
                                        <View id="center" style={styles.center}>
                                            <View style={styles.divzTop}>
                                                <View style={{ width: '33%' }}>
                                                    <Text>Pedido</Text>
                                                </View>
                                                <View style={{ width: '67%' }}>
                                                    <Text>{detailSales?.id}</Text>
                                                </View>
                                            </View>
                                            <View style={styles.divzTop}>
                                                <View style={{ width: '33%' }}>
                                                    <Text>Valor Total:</Text>
                                                </View>
                                                <View style={{ width: '67%' }}>
                                                    <Text>{(detailSales?.total_value / 100).toLocaleString('pt-BR', options)}</Text>
                                                </View>
                                            </View>
                                        </View>
                                        {/* Table Produtos */}
                                        <View style={styles.table}>
                                            <View style={styles.divY}>
                                                <View style={styles.line3}>
                                                    <Text style={styles.itenD}> - </Text>
                                                </View>
                                                <View style={styles.line2}>
                                                    <Text style={styles.itenD}>Produto</Text>
                                                </View>
                                                <View style={styles.line3}>
                                                    <Text style={styles.itemC}>Valor</Text>
                                                </View>
                                                <View style={styles.line3}>
                                                    <Text style={styles.itemC}>Quantidade</Text>
                                                </View>
                                            </View>


                                            <View >
                                                {detailSales?.products?.map((prod, index) => (
                                                    <View style={styles.line} key={prod?.product_slug}>
                                                        <View style={styles.line3}>
                                                            <Text>{index + 1}</Text>
                                                        </View>

                                                        <View style={styles.line2}>
                                                            <Text> {prod?.title}</Text>
                                                        </View>
                                                        <View style={styles.line3}>
                                                            <Text>{(prod?.price / 100).toLocaleString('pt-BR', options)}</Text >
                                                        </View>
                                                        <View style={styles.line3}>
                                                            <Text>  {prod?.quantity}</Text>
                                                        </View>
                                                    </View>
                                                ))}
                                            </View>


                                        </View>
                                    </View>
                                </Page>
                            </Document>} fileName="resumo_pedido.pdf">
                                {({ blob, url, loading, error }) =>
                                    loading ? 'Gerando PDF...' : <div><div><SummarizeIcon /></div>Resumo do pedido</div>
                                }
                            </PDFDownloadLink>
                            {/*   <DownloadForOfflineIcon /> */}
                            {/*   <p style={{ color: '#8e8e8e' }}>Baixar resumo do pedido </p> */}
                        </SmallCard>
                        {!selectedFile ?
                            <SmallCard style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center', paddingTop: '32px' }}>
                                <label htmlFor="nf_pdf" style={{ cursor: 'pointer' }}><ReceiptIcon /></label>
                                <label htmlFor="nf_pdf" style={{ cursor: 'pointer' }}> <p style={{}}>Anexar NF</p></label>
                                <input id="nf_pdf" type="file" accept=".pdf" onChange={handleFileChange} style={{
                                    width: '100%',
                                    opacity: '0',
                                    cursor: 'pointer',
                                }} />
                            </SmallCard > :
                            <SmallCard style={{ display: 'flex', flexDirection: 'column' }} onClick={openNf} onContextMenu={handleContextMenu}
                                id="basic-button"
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}>
                                <div style={{ alignItems: 'center', flexDirection: 'column', display: 'flex', paddingTop: '15%' }}>
                                    <ReceiptIcon />
                                    <p htmlFor="nf_pdf" style={{ cursor: 'pointer' }}> <p style={{}}>Baixar NF</p></p>
                                </div>
                            </SmallCard >}

                        <SmallCard onClick={() => setShow(true)}>
                            <div style={{ alignItems: 'center', flexDirection: 'column', display: 'flex', paddingTop: '5%' }}>
                                <LocalShippingIcon />
                                <p >Alterar Status do pedido</p>
                            </div>
                        </SmallCard>
                    </Line>
                </DivProducts>
            </Line>

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => changeNF()}><label htmlFor="nf_pdf">Trocar NF</label></MenuItem>
                {/* <MenuItem onClick={handleClose}>Excluir NF</MenuItem> */}
            </Menu>

            <Modal show={show} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Configurar Pedido</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <SimpleAccordion payment={detailSales?.payment_status} successF={changedStatus} errorF={notChangedStatus} status={detailSales?.processing_status} id={detailSales?.id} />
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
                <div id="sanckbar">
                    {successStatus && <SnackBar type="success" message="Status atualizado com sucesso" states={true} time={4000} />}
                    {errorStatus && <SnackBar type="error" message="Não foi possível atualizar" states={true} time={4000} />}
                </div>
            </Modal>

            {success && <SnackBar type="success" message="NF anexada com sucesso" states={true} time={4000} />}
            {error && <SnackBar type="error" message="Não foi possível anexar a NF" states={true} time={4000} />}
        </Container>
    )
}

export default SalesDetail;