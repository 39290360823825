import React from "react";
import CreateIcon from '@mui/icons-material/Create';
import Modal from 'react-bootstrap/Modal';
import Button from '@mui/material/Button';


import {
    Container,
} from './styles';
import { postAnswer } from "../../../../Services/storeProducts";
import { ButtonGreenSmall } from "../../../../Styles/stylesButtons";


const AnswerQuestion = ({ question, answer, id, func }) => {

    const [show, setShow] = React.useState(false);
    const [answerR, setQuestion] = React.useState(answer);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const answerQuestion = async () => {
        try {
            if (!answerR) {
                return
            }
            const response = await postAnswer(answerR, id)
            if (response.message === "success") {
                func();
                setShow(false);
            }
        } catch (error) {

        }
    }

    return (
        <Container>
            <CreateIcon onClick={handleShow} style={{ cursor: 'pointer', color: '#009c43' }} />
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Responder</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ paddingBottom: '10px' }}>
                        <label>Pergunta:</label>
                        <div style={{ border: 'solid 1px #cecece', borderRadius: '6px', padding: '10px', background: '#f1f1f1f1' }}>
                            {question}
                        </div>
                    </div>
                    <div style={{ paddingTop: '10px' }}>
                        <label>Resposta:</label>
                        <div>
                            <textarea maxLength="240" className="form-control" id="question" placeholder="Resposta?" rows="3" value={answerR} onChange={(e) => setQuestion(e.target.value)}></textarea>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button style={{ borderRadius: '20px' }} variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <ButtonGreenSmall style={{ width: '150px', height: '35px' }} variant="primary" onClick={answerQuestion}>
                        Salvar Resposta
                    </ButtonGreenSmall>
                </Modal.Footer>
            </Modal>
        </Container>
    )
}


export default AnswerQuestion