import React from "react";
import WebAssetIcon from '@mui/icons-material/WebAsset';
import SecurityIcon from '@mui/icons-material/Security';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import Acord from "../../Components/General/Acordion";
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import StoreIcon from '@mui/icons-material/Store';
import LinkIcon from '@mui/icons-material/Link';
import Condition from '../../../Assets/Documents/CONDIÇÕES DE NAVEGAÇÃO.pdf';
import Privacy from '../../../Assets/Documents/POLÍTICA DE PRIVACIDADE.pdf';
import Buy from '../../../Assets/Documents/POLÍTICA DE COMPRA E ENTREGA.pdf';
import Return from '../../../Assets/Documents/POLÍTICA DE TROCA E DEVOLUÇÃO.pdf';
import Sales from '../../../Assets/Documents/TERMOS DE COMERCIALIZAÇÃO.pdf';

import {
    Container,
    Content,
    Group,
    Header,
} from './styles';
import { TitleH3, TitleH6 } from "../../../Styles/style";


const Help = () => {
    return (
        <Container>
            <Content>
                <Header>
                    <TitleH3>
                        Termos de Uso
                    </TitleH3>
                </Header>
                <Group>
                    <TitleH6>
                        Navegação
                    </TitleH6>
                    <Acord icon={<WebAssetIcon sx={{ color: '#009c43' }} />} title="Condições de Navegação" item="Política de cookies, privacidade e termos de uso para uma experiência segura e completa.">
                        <div onClick={() => window.open(Condition, '_blank')} style={{ cursor: 'pointer' }}>
                            <LinkIcon sx={{ color: '#009c43' }} /> Abrir Arquivo
                        </div>
                    </Acord>
                </Group>
                <Group>
                    <Acord icon={<SecurityIcon sx={{ color: '#009c43' }} />} title="Política de Privacidade" item=" Saiba como protegemos e utilizamos suas informações com responsabilidade e transparência." >
                        <div onClick={() => window.open(Privacy, '_blank')} style={{ cursor: 'pointer' }}>
                            <LinkIcon sx={{ color: '#009c43' }} /> Abrir Arquivo
                        </div>
                    </Acord>
                </Group>

                <Group>
                    <TitleH6>
                        Compra
                    </TitleH6>
                    <Acord icon={<LocalShippingIcon sx={{ color: '#009c43' }} />} title="Política de Compra e Entrega" item="Detalhes sobre o processo de compra e entrega de produtos, garantindo sua satisfação e conveniência." >
                        <div onClick={() => window.open(Buy, '_blank')} style={{ cursor: 'pointer' }}>
                            <LinkIcon sx={{ color: '#009c43' }} /> Abrir Arquivo
                        </div>
                    </Acord>
                </Group>
                <Group>
                    <Acord icon={<ChangeCircleIcon sx={{ color: '#009c43' }} />} title="Política de Troca e Devolução" item="Informações sobre como proceder caso seja necessário trocar ou devolver um produto adquirido em nossa plataforma, visando sua total satisfação." >
                        <div onClick={() => window.open(Return, '_blank')} style={{ cursor: 'pointer' }}>
                            <LinkIcon sx={{ color: '#009c43' }} /> Abrir Arquivo
                        </div>
                    </Acord>
                </Group>

                <Group>
                    <TitleH6>
                        Venda
                    </TitleH6>
                    <Acord icon={<StoreIcon sx={{ color: '#009c43' }} />} title="Termos de Comercialização" item="Condições e acordos relacionados à venda e aquisição de produtos ou serviços em nossa plataforma, garantindo uma transação justa e transparente." >
                        <div onClick={() => window.open(Sales, '_blank')} style={{ cursor: 'pointer' }}>
                            <LinkIcon sx={{ color: '#009c43' }} /> Abrir Arquivo
                        </div>
                    </Acord>
                </Group>
            </Content>
        </Container>
    )
}

export default Help;