import React from 'react';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import { TitleH3 } from '../../../../Styles/style';
import { Logout } from '../../../../Services/logout';
import { useLocation } from 'react-router-dom';
import {
    Container,
    Line,
} from './styles';
import NewAddress from '../AddressNew';
import axios from 'axios';


const Address = ({ redirect }) => {

    const location = useLocation();
    const [addressList, setAddressList] = React.useState([]);

    const getAddressList = async () => {
        try {
            const { data: res } = await axios({ method: 'get', url: `${process.env.REACT_APP_LINK}/api/user/addresses`, headers: { 'Authorization': `Bearer ${localStorage.getItem('clientToken')}` } })
            if (res?.response?.data.message === "Unauthenticated.") {
                Logout();
            } else {
                setAddressList(res.data.address);
            }

        } catch (error) {

        }
    }

    React.useEffect(() => {
        getAddressList();
    }, [location])

    return (
        <Container>
            <Line style={{ justifyContent: 'space-between', display: "flex" }}>
                <div style={{ padding: '10px' }}>
                    <TitleH3>Meus Endereços</TitleH3>
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <NewAddress redirect={redirect} />
                </div>
            </Line>
            <Divider />
            <List sx={{ width: '100%', bgcolor: 'background.paper', padding: '25px' }}>
                {addressList.map((address) =>
                    <div key={address.id}>
                        <NewAddress edit='edit' addressInf={address} />
                        <Divider variant="inset" component="li" />
                    </div>
                )}

            </List>
        </Container>
    )
}

export default Address;