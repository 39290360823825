import styled from "styled-components";

export const Container = styled.div`
`;
export const DivImage = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    >img{
        border-radius: 3px;
        @media(max-width: 600px){
            width: 100%;
        }
    }
`;
export const Text = styled.div`
    width: 100%;
    padding: 5%;
`;
export const BigCard = styled.div`
    background-color: #fff ;
    box-shadow: 0px 0px 9px #26242424;
    border: 1px solid #c6c6c6 ;
    border-radius: 6px;
    width: calc(100% - 10px);
    margin: 0px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    padding: 15px 0px;
    cursor: pointer;
    padding: 10px;
    overflow: auto;
   
    >div{
        width: 60%;
    }
`;
export const SmallCard = styled.div`
    background-color: #fff ;
    box-shadow: 0px 0px 9px #26242424;
    border: 1px solid #c6c6c6 ;
    border-radius: 6px;
    height: 18rem;
    width: calc(100%/3);
    margin: 0px 5px;
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    padding: 15px 0px;
    cursor: pointer;
    padding: 10px;
    @media(max-width: 800px){
        width: calc(100% - 10px);

    }
`;
export const Bottom = styled.div`
    display: flex;
    padding: 2%;
    @media(max-width: 800px){
        flex-direction: column;
    }
`;
export const Top = styled.div`
    display: flex;
    padding: 2%;
`;