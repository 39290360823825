import styled from "styled-components";

export const Container = styled.div`

`;

export const List = styled.div`
    width: 100%;
    height: 70vh;
    overflow: auto;
    padding: 5px;

    @media(min-width: 1920px){
    height: 50vh;
        
    }
`;