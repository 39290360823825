import styled from "styled-components";

export const Container = styled.div`
    grid-area: MH;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    background-color: #fff ;
    box-shadow: 0px 0px 9px #26242424;
    height: 80px;
`;

export const Logo = styled.div`
    padding-top: 15px;
    padding-bottom: 10px ;
    margin-left: 15px;
    width: 300px;
`;
export const MenuDiv = styled.div`
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    min-width: 0;
    padding: 0 15px;
`;
export const MenuLine1 = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    >p{
        cursor: pointer;
    }
`;
export const MenuLine2 = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    width: 50% ;
    padding-bottom: 15px;
    
    >div{
        text-align: center;
        font-size: 15px;
        color: #A9ABAE;
        cursor: pointer;
        :hover{
           
        }
    }
`;
export const Social = styled.div`
     width: 30%;
     height: 100%;
     text-align: center;
     align-items: center;
     display: flex;
     justify-content: space-between;
     padding: 0 3% ;
     
`;

export const Icon = styled.div`
    border-radius: 100px ;
    color: #A9ABAE;
    &:hover{
       color: #116E44 ;
    }
    >small{
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
`;
export const Left = styled.div`
    width: 180px;
    height: 100%;
    background-color: #009c43;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    > img {
        width: 130px;
        cursor: pointer;
        @media(max-height: 900px){
            width: 130px;
    }
    }
`;