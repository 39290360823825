import React from 'react';
import DetailMobile from '../../Components/General/productsDetail/Mobile';
import DetailWeb from '../../Components/General/productsDetail/Web';

import {
    Container,
} from './styles';


const ProductDetails = () => {

    const [pageWidth, setPageWidth] = React.useState(window.innerWidth);

    function recarregarPagina() {
        setPageWidth(window.innerWidth)
    }
    var TimeOut;

    window.onresize = function () {
        clearTimeout(TimeOut);
        TimeOut = setTimeout(recarregarPagina, 10);
    };


    React.useEffect(() => {
        setPageWidth(window.innerWidth);
    }, [window.innerWidth])

    return (
        <Container>
            {pageWidth <= 800 ? <DetailMobile /> : <DetailWeb />}
        </Container>
    )
}

export default ProductDetails;