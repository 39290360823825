import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import menuIcon from '../../../../Assets/icons/menuIcon.svg';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import StoreIcon from '@mui/icons-material/Store';
import HomeIcon from '@mui/icons-material/Home';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LogoutIcon from '@mui/icons-material/Logout';
import InfoIcon from '@mui/icons-material/Info';
import { useNavigate } from 'react-router-dom';
import * as LogoutFunction from '../../../../Services/logout'
import LoginIcon from '@mui/icons-material/Login';

export const TemporaryDrawer = ({ info, status }) => {

    const navigate = useNavigate();

    const [state, setState] = React.useState({ right: false, });

    const logout = () => {
        LogoutFunction.Logout();
    }

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <Box
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            {status === "true" ?
                <List>
                    <ListItem disablePadding >
                        <ListItemButton onClick={() => navigate('/')}>
                            <ListItemIcon>
                                <HomeIcon sx={{ color: '#009c43' }} />
                            </ListItemIcon>
                            <ListItemText primary={"Home"} />
                        </ListItemButton>
                    </ListItem>

                    <ListItem disablePadding >
                        <ListItemButton onClick={() => navigate("/usuario/perfil")}>
                            <ListItemIcon>
                                <AccountCircleIcon sx={{ color: '#009c43' }} />
                            </ListItemIcon>
                            <ListItemText primary={"Meu Perfil"} />
                        </ListItemButton>
                    </ListItem>

                    {info ?
                        <ListItem disablePadding >
                            <ListItemButton onClick={() => navigate("/loja/perfil")}>
                                <ListItemIcon>
                                    <StoreIcon sx={{ color: '#009c43' }} />
                                </ListItemIcon>
                                <ListItemText primary={"Minha Loja"} />
                            </ListItemButton>
                        </ListItem> : <></>}

                    <ListItem disablePadding >
                        <ListItemButton onClick={() => navigate("/usuario/meus_pedidos")}>
                            <ListItemIcon>
                                <ShoppingBagIcon sx={{ color: '#009c43' }} />
                            </ListItemIcon>
                            <ListItemText primary={"Minhas Compras"} />
                        </ListItemButton>
                    </ListItem>

                    <ListItem disablePadding >
                        <ListItemButton onClick={() => navigate("/produtos/favoritos/lista")}>
                            <ListItemIcon>
                                <FavoriteIcon sx={{ color: '#009c43' }} />
                            </ListItemIcon>
                            <ListItemText primary={"Lista de favoritos"} />
                        </ListItemButton>
                    </ListItem>

                    <ListItem disablePadding >
                        <ListItemButton onClick={() => navigate("/produtos/cart/lista")}>
                            <ListItemIcon>
                                <ShoppingCartIcon sx={{ color: '#009c43' }} />
                            </ListItemIcon>
                            <ListItemText primary={"Meu Carrinho"} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding >
                        <ListItemButton onClick={() => logout()}>
                            <ListItemIcon>
                                <LogoutIcon sx={{ color: '#009c43' }} />
                            </ListItemIcon>
                            <ListItemText primary={"Logout"} />
                        </ListItemButton>
                    </ListItem>

                </List>
                :
                <List>
                    <ListItem disablePadding >
                        <ListItemButton onClick={() => navigate("/login/Af140e")}>
                            <ListItemIcon>
                                <LoginIcon sx={{ color: '#009c43' }} />
                            </ListItemIcon>
                            <ListItemText primary={"Login"} />
                        </ListItemButton>
                    </ListItem>
                </List>}

            <Divider />
            <List>
                {!status &&
                    <ListItem disablePadding >
                        <ListItemButton onClick={() => navigate('/')}>
                            <ListItemIcon>
                                <HomeIcon sx={{ color: '#009c43' }} />
                            </ListItemIcon>
                            <ListItemText primary={"Home"} />
                        </ListItemButton>
                    </ListItem>
                }
                <ListItem disablePadding>
                    <ListItemButton onClick={() => navigate("/lojas/lista")}>
                        <ListItemIcon >
                            <StoreIcon sx={{ color: '#009c43' }} />
                        </ListItemIcon>
                        <ListItemText primary={"Lojas"} />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton onClick={() => navigate("/sobre-nos")}>
                        <ListItemIcon>
                            <InfoIcon sx={{ color: '#009c43' }} />
                        </ListItemIcon>
                        <ListItemText primary={"Sobre Nós"} />
                    </ListItemButton>
                </ListItem>
            </List>
        </Box>
    );

    return (
        <div>
            {['left'].map((anchor) => (
                <React.Fragment key={anchor}>
                    <Button onClick={toggleDrawer(anchor, true)}> <img src={menuIcon} alt="menu icon" /></Button>
                    <Drawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                    >
                        {list(anchor)}
                    </Drawer>
                </React.Fragment>
            ))}
        </div>
    );
}
